
<template>
	<div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
			<dotsloader />
		</div>
	</div>
	<div v-else>
		<div class="tableCornerRadius bg-white">
			
		<div :class="pageData!=undefined && pageData == 4 ? 'fixHeightTable flex flex-col justify-between':''">
			<div class="table-container">
			<table>
				<thead>
					<th scope="col" v-for="presentColumn in columnNames" :key="presentColumn" :class="getColumnHeadWidth(presentColumn)" class="bg-white capitalize" @click="sort(presentColumn)">
					<div class="flex" :class="getPaddingForColumn(presentColumn)">
							<div >{{ getColumnLabel(presentColumn) }}</div>
							<div v-if="presentColumn == 'framework_controlNo'">

							</div>
							<div class="sort cursor-pointer" :class="className == presentColumn ? classSort : 'opacity-50'" v-else-if="getColumnLabel(presentColumn) != ''"></div>
						</div>
					</th>
					<tr v-if="visibility">
						<td v-for="(presentColumn, index) in columnNames" :key="index">
							<!-- Dropdown -->
							<select :class="filters[presentColumn] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-if="isColumnArrayPresent(presentColumn)" class="statusFilter"  v-model="filters[presentColumn]">
								<option :value="filters.presentColumn" selected hidden disabled>Select</option>
								<option class="optionColors" v-for="option in getDropdownOptionsForFilter(presentColumn)" :key="option.id" :value="option.id">{{ option.value }}</option>
							</select>
							<!-- No Input -->
							<div v-else-if="isFilterNotNecessary(presentColumn)"></div>
							<div v-else-if="presentColumn == 'taskId'" >
								<input type="text" class="searchForInputBoxStyling marginForIdInFilters" v-model.trim="filters[presentColumn]" placeholder="Search" />
							</div>
							<!-- Text Input -->
							<input v-else type="text" class="searchForInputBoxStyling" v-model.trim="filters[presentColumn]" placeholder="Search" />
						</td>
					</tr>
				</thead>

				<tbody v-if="loadData == true && sortedData.length == 0">
					<tr class="cursor-pointer empty rowHeight">
						<td class="relative h-5" :colspan="columnNames.size">
							<div class="flex items-center justify-center w-full h-16 skeleton">
								<dotsloader/>
							</div>
						</td>
					</tr>
				</tbody>

				<tbody :class="pageData!=undefined && pageData == 4 ? 'emptyFixed':''" v-else-if="sortedData.length == 0">
					<tr class="cursor-pointer rowHeight">
						<td class="relative h-10" :colspan="columnNames.size">
							<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
						</td>
					</tr>
				</tbody>
				
				<tbody class="nonEmptyTable" v-else>
					<tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight" >
						<td v-for="presentColumn in columnNames" :key="presentColumn" @mouseout="doFalse()"  @click="clickedOnTableRowData(row, index, presentColumn)" class="cursor-pointer relative">
							<!-- <div class=" ml-3 rounded h-5 w-24" v-if="row[presentColumn] == ''">{{ '-' }}</div> -->
							<div v-if="isColumnPresentForLabel(presentColumn)">
								<div class="flex items-center justify-center rounded w-24 h-5 " :class="bgColorForStatus(row[presentColumn])">
									<div :class="textColorForTemplateStatus(row[presentColumn])">
										<!-- {{ row[presentColumn]}} -->
										{{getStatusLabelFromColumn(row[presentColumn],presentColumn)}}
									</div>
								</div>
							</div>
							<!-- framework_controlNo -->
							<div v-else-if="presentColumn=='framework_controlNo'">
								<div style="white-space: break-spaces" v-if="row[presentColumn] != undefined && row[presentColumn].length == 0">{{'N/A  N/A'}}</div>
								<div v-else v-for="(obj,index) in row[presentColumn]" :key="index"  class="flex py-1">
									<div v-if="obj.framework == undefined || obj.framework == null" class="mr-2">{{ 'N/A' }}</div>
									<div v-else class="mr-2">{{ obj.framework }}</div>
									<div v-if="obj.controls == undefined || (obj.controls.length == 1 && obj.controls[0]==null)">{{ 'N/A' }}</div>
									<div v-else>
										<div v-for="control in obj.controls" :key="control" class="flex flex-wrap items-center pb-1" >
											<div :class="getBorder()" class="text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto flex items-center" >
												{{ control }}
											</div>
										</div>
										
									</div>
									
								</div>
							</div>
							<!-- <div v-else-if="presentColumn=='controlNo'">
								<div v-if="row[presentColumn] != null" class="h-full w-full flex flex-wrap items-center" >
									<div :class="getBorder()" class="text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto flex items-center" >
										{{ row[presentColumn] }}
									</div>
								</div>
								<div v-else>
									-
								</div>
							</div> -->
							<div v-else-if="presentColumn=='startDate'||presentColumn=='dueDate'">
								<div v-if="row[presentColumn] != undefined || row[presentColumn] !=null">
									{{ changeDateFormat(row[presentColumn]) }}
								</div>
								<div v-else>
									-
								</div>
							</div>
							<div v-else-if="presentColumn=='team'">
								<div>
									{{row[presentColumn]}}
								</div>
							</div>
							<!-- <div v-else-if="presentColumn=='status'">
								<div class=" ml-1.5 flex items-center justify-center rounded-sm" :class="colorTemplateText(row[presentColumn])">
									<div :class="colorTemplateBg(row[presentColumn])">
										{{getStatusLabelFromColumn(row[presentColumn],presentColumn)}}
									</div>
								</div>
								{{ 
									statusType.find((status:any)=>{
										return statu.id == row[presentColumn]
									})
								}}
							</div> -->
							
							<div v-else-if="presentColumn=='deleteIcon'">
								<div class="flex justify-start items-center h-5">
									<img src="@/assets/deleteIcon.svg" class="h-5 w-5 cursor-pointer" @click="clickedOnDeleteButton(row)"/>
								</div>
							</div>
							<div v-else-if="presentColumn=='type'">
								<div v-if="row[presentColumn] == 1">Task</div>
								<div v-else-if="row[presentColumn] == 2">Sub-Task</div>
								<div v-else-if="row[presentColumn] == 3">New Task</div>
								<div v-else-if="row[presentColumn] == ''">-</div>
							</div>

							<div v-else>
								<div class="table-ellipsis">
									<div  @mousemove="toolTip(presentColumn, $event, index, row, 'text')" :class="presentColumn == 'Templates'?'pl-9':''">
										{{ row[presentColumn] }}
									</div>
								</div>
								
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			</div>
			<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData" :pageData="pageData"></pagination></div>
		</div>
	</div>
</template>
<style scoped>
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.widthForccid{
	width:6.2rem;
}
.fixHeightTable{
	height: 353px !important;
}
.emptyFixed:last-child  {
	border-bottom: 1px solid #e9e9e9;
}
.nonEmptyTable:last-child  {
	border-bottom: 1px solid #e9e9e9;
}
.ellipsis-container {
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 1.5em;
	max-height: 3em;
}
.table-ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.actionButtonStyling {
    left: -18px;
}
.lastChild:last-child {
    border: 0px;
}
/* .rowHeight{
  height: 40px;
} */
 .actionButtonWidth{
	width : 60px;
 }
 .numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 2px solid #152A43;
    /* border-width: 2px; */
}
.zIndexingForActionDropDown{
	z-index : 65;
	width : 100px;
	margin-left : -40px;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.widthFor1stAudit{
	width: 98px;
    margin-left: -3px;
}
.w-12{
	width: 60px !important;
}
</style>
<script lang="ts">
import { emitter,toast } from '@/main';
// import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import dotsloader from './dotsloader.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
	props: ['tableRowData', 'headersData','pageData','loadData'],
	data(): any {
		return {
			tableHead: [],
			tableRow: [],
			searchTableRow: [],
			visibility: false,
			className: '',
			classSort: '',
			currentSort: 'name',
			currentSortDir: 'asc',
			currentPage: '',
			pageSize: '',
			dynamicPagination: '',
			pop: false,
			styling: { left: '', bottom: '' },
			tipText: '',
			tipColumn: '',
			tipIndex: '',
			filters: {},
			showDropDown: false,
			selectedRowIndex: '',
			optionsUserArray : [],
			teamObject : [],
			locationObject : [],
			showBlock: false,
			statusType: [
				{
					id: 10401,
					value: 'Open'
				},
				{
					id: 10402,
					value: 'In Progress'
				},
				{
					id: 10415,
					value: 'In Review'
				},
				{
					id: 10403,
					value: 'Completed'
				},
				{
					id: 10411,
					value: 'Draft'
				},
			],
			optionsValue: [
                {
                    id: 1,
                    value: 'Edit',
                },
                {
                    id: 2,
                    value: 'View',
                },
                {
                    id: 5,
                    value: 'Controls',
                },
				{
					id: 6,
					value: 'Reports',
				}
            ],
			columns: [
                { key: 'auditId', value: 'Audit ID' },
                { key: 'title', value: 'Title' },
				
				{ key: 'taskstatus', value: 'status' },
				{ key: 'teamName', value: 'team' },
				{ key: 'entityName', value: 'entity' },
				{ key: 'teamName1', value: 'team' },
				{ key: 'businessProcessName', value: 'Process Name' },
				{ key: 'name', value: 'Owner' },
				{ key: 'department', value: 'Team' },
				{ key: 'SubTaskStatus', value: 'status' },
				{ key: 'ccid', value: 'CCID' },
				{ key: 'framework_controlNo', value: 'Framework & ControlNo' },
				{ key: 'subTasktitle', value: 'Title' }
            ],
			dropdownOptions:[
				{
                    key: 'SubTaskStatus',
                    value:[
						{
							id: 10411,
							value: 'Draft'
						},
						{
							id: 10401,
							value: 'Open'
						},
						{
							id: 10402,
							value: 'In Progress'
						},
						{
							id: 10415,
							value: 'In Review'
						},
						{
							id: 10403,
							value: 'Completed'
						},
					],
                
				},
				{
                    key: 'parentTaskStatus',
                    value: [
						{
							id: 10401,
							value: 'Open'
						},
						{
							id: 10402,
							value: 'In Progress'
						},
						{
							id: 10415,
							value: 'In Review'
						},
						{
							id: 10403,
							value: 'Completed'
						},
					],
                
				},
				{
                    key: 'taskstatus',
                    value: [
						
						{
							id: 10401,
							value: 'Open'
						},
						{
							id: 10402,
							value: 'In Progress'
						},
						{
							id: 10415,
							value: 'In Review'
						},
						{
							id: 10403,
							value: 'Completed'
						},
						
					]
                
				},
				{
                    key: 'type',
                    value: [
						
						{
							id: 1,
							value: 'Task'
						},
						{
							id: 2,
							value: 'Sub-task'
						},
						{
							id: 3,
							value: 'New Task'
						},
						
					]
                
				},
				
			],
			// actionButtonRow:{}
		};
	},
	components: {
		toolTip,
		pagination,
		dotsloader
	},
	computed: {
		sortedData: function (): any {
			return [...this.tableRow]
				.filter((row: any) => {
					// console.log('row', row);
					// console.log(' this.filters', this.filters);
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						// console.log('inputValue', inputValue);
						if (inputValue !== '' && row[key]) {
							if (Number.isInteger(row[key])) {
								if (row[key] !== Number(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir == 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != 'entityId' && key != '_id') names.add(key);
            }
            return names;
        },
		// actionKeys: function():any{
		// 	let startArray = [1,3,4,5,6];
		// 	let progressArr = [2,4,5,6];
		// 	let mainArr :any
		// 	if(this.actionButtonRow.auditStatus == 10401){
		// 		mainArr = startArray
		// 	}else{
		// 		mainArr = progressArr;
		// 	}
		// 	return this.optionsValue.filter((obj:any)=>{return mainArr.includes(obj.id)})
		// }
		...mapGetters({ dotsLoading: 'dotsLoading' })
	},
	async mounted() {
		this.initialize();
		emitter.on('visible', (isBoolean: any) => {
			this.visibility = isBoolean;
			this.visibility == false ? this.filters = {}:'';
		});
		emitter.on('*', (type, booleanValue) => {
        	if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
        		this.showDropDown == true ? (this.showDropDown = false) : '';
        	} else {
        		if (this.showBlock === true) {
        			this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
        		} else if (this.showBlock === false) {
        			this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
        			this.showBlock = true;
        		}
        	}
        });
	},
	watch: {
		visibility(newValue) {
			if (newValue == false) {
				this.initialize();
			}
		},
		tableRowData: {
			handler(newVal, oldVal) {
				this.tableRow = this.tableRowData;
				this.initialize();
				console.log('this.tableRowData[0]', this.tableRowData[0]);
			},
			deep: true
		}
	},
	methods: {
		changeDateFormat(date: any) {
			if (date.includes('-')) {
				let dateParts = date.split('-');
				if (dateParts.length === 6) {
				let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
				return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
				return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},
		getBorder(){
            return `numberBorder numberNavy`
        },
		getPaddingForColumn(column:any){
            if(column=='SubTaskStatus'){
                return 'pl-3'
            }else if(column=='taskstatus'){
                return 'pl-5'
            }
        },
		clickedOnDeleteButton(rowData:any){
            emitter.emit('deleteRowFromTable',rowData)
        },
		colorTemplateText(status: any) {
			if (status == 10411) {
				return ['font-semibold text-disabledgrey'];
			} else if (status == 10415) {
				return ['font-semibold text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-lightgreen'];
			} else if(status == 10402){
				return ['font-semibold mx-2 text-electricGreen']
			} else if(status == 10401){
				return ['font-semibold mx-2 text-dakred']
			} else{
				return '-'
			}
		},
		colorTemplateBg(status: any) {
			if (status == 10411) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10402) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10401) {
				return ['bg-veryHighcolor border-solid border border-darkred'];
			} 
		},
		textColorForStatus(status: any) {
			if (status == 10403 || status == 10418) {
				return ['font-semibold text-darkred'];
			} else if (status == 10402) {
				return ['font-semibold text-yellow'];
			} else if (status == 10401) {
				return ['font-semibold text-lightgreen'];
			} else if(status == 10401){
				return ['font-semibold mx-2 text-dakred']
			}
		},
		bgColorForStatus(status: any) {
			if (status == 10411) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10402) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10401) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			} 
		},
		textColorForTemplateStatus(status: any) {
			if (status == 10411) {
				return ['font-semibold text-xs text-disabledgrey'];
			} else if (status == 10415) {
				return ['font-semibold text-xs text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-xs text-lightgreen'];
			} else if(status == 10402){
				return ['font-semibold text-xs mx-2 text-electricGreen']
			} else if(status == 10401){
				return ['font-semibold text-xs mx-2 text-darkred']
			} else{
				return '-'
			}
		},
		isColumnPresentForLabel(column:any){
			if(column=='SubTaskStatus' || column=='taskstatus'){
				return true
			}
		},
		initialize() {
			console.log('this.tableRowData', this.tableRowData);
			this.tableRow = this.tableRowData;
			this.searchTableRow = this.tableRowData;
		},
		paginationUpdations(currentPage: any, pageSize: any) {
			console.log('Pagination processing... ', currentPage, pageSize);
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		sort(tableHead: any) {
			this.className = tableHead;
			if (tableHead == this.currentSort) {
				this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = tableHead;
			this.classSort = this.currentSortDir == 'asc' ? 'sort_asc' : 'sort_desc';
		},
		openPopup(data: any, index: any) {
			let popupdata = {
				entityData: data,
				indexx: index
			};
			emitter.emit('showData123', popupdata);
		},
		toolTip(column: any, e: any, index: any, row: any, status: any) {
			this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
				case 'circleButton':
					if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
						this.pop = true;
						this.styling.left = e.offsetX + e.target.offsetLeft + 13 + 'px';
						this.styling.bottom = -15 + 'px';
					}
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			// console.log("tooltip",container)
			if (container && this.isTextOverflowed(container)) {
				// this.pop = true;
				// this.styling.left = e.offsetX + 38 + 'px';
				// this.styling.bottom = -35 + 'px';
				let obj:any = {
					tipText : this.tipText
				}
				emitter.emit('activiteToolTip',obj)
			}
			//  else {
			// 	this.pop = false;
			// }
		},
		isTextOverflowed(element: any) {
			return element.scrollHeight > element.clientHeight;
		},
		doFalse() {
				emitter.emit('deActiviteToolTip')
		},
		getOwnerType(input: any) {
			let type = this.orgType.find((l: any) => {
				return l.id == input;
			});
			if (type == undefined) {
				return input;
			} else {
				return type.value;
			}
		},
		getColumnLabel(columnName: any) {
			if (columnName == 'actionButton' || columnName == 'deleteIcon' ){
                return '';
            }
            const column = this.columns.find((col: { key: any }) => col.key === columnName);
            return column ? column.value : columnName;
        },
		isColumnArrayPresent(columnName: any) {
            // Returns true if the column name has corresponding dropdown options
            return this.dropdownOptions.some((option: { key: any }) => option.key === columnName);
        },
		getDropdownOptionsForFilter(columnName: any) {
            // console.log('sazxsadsad', columnName);
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === columnName);
            return option ? option.value : [];
        },
		getStatusLabelFromColumn(columnName: any, type: any) {
            // console.log('columnName', columnName);
			// console.log("type",type)
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === type);
            let option1 = option.value.find((soption: { id: any }) => soption.id === columnName);
            // console.log('wasbdhjbdzjsad', option);
            // console.log('option1', option1);
			if(option1 == undefined || option == undefined ||option == null || option1 == null ){
				option1 = '-'
			}
            return option ? option1.value : [];
        },
		isFilterNotNecessary(columnName:any) {
            if(columnName == 'dueDate'|| columnName == 'deleteIcon' || columnName == 'startDate' || columnName == 'Templates'|| columnName == 'framework_controlNo')
            {
                return true;
            }
			return false;
        },
		// shouldShowDisabled(columnName:any) {
        //     if(columnName == 'startDate' || columnName == 'endDate' || columnName == 'auditPeriod')
        //     {
        //         return true;
        //     }
            
        //         return false;  
        // },
		getLabelsArrayForColumnInStore(column: any){
			if(column == 'auditStatus'){
				return true
			} else{
				return false
			}
		},
		 getTeamName(ownerId: any) {
            let user = this.teamObject.find((obj: any) => {
                return obj.value == ownerId;
            });
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.label;
            }
        },
		 getLocationName(locationId: any) {
			if(locationId == 'Global'){
				return 'Global'
			}
			
            let user = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.label;
            }
        },
		getOwnerName(userId: any) {
            let user = this.optionsUserArray.find((obj: any) => {
                return obj.value == userId;
            });
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.label;
            }
        },
		checkuserData(column: any) {
            if (column == 'team' || column == 'entity') {
                return true;
            }
        },
		clickedOnTableRowData(rowData: any,index:any,column:any) {
			// console.log('Clicked row data:', rowData);
			if(rowData.actionType == 2 || rowData.actionType == 3){
				// console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
				emitter.emit('clickedOnSubTaskRowData', rowData);
			} else if(column!='deleteIcon'&& !this.$route.path.includes('/templateDetails')&& !this.$route.path.includes('/archiveTemplates')&&!this.$route.path.includes('/editTemplate')&&!this.$route.path.includes('/createTemplate')) {
				// console.log("outtttttttttttttttttttttttttttt")
				emitter.emit('clickedOnRowData', rowData);
			}
        },
		optionClickedOnAction(actionOption:any,rowData:any){
			let actionObj:any = {
				action: actionOption,
				data:rowData
			}
			if(rowData['auditStatus'] != 10401 && actionOption=='Edit'){
                toast.error('Cannot Edit Audit in this Status', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }else if(actionOption == 'Findings'){
				this.$router.push({ name: 'auditFindings', params: { auditId: rowData._id } });
			}else{
                emitter.emit('clickedOnDropDownAction',actionObj)
            }
		},
		async getAllUsers() {
            this.optionsUserArray = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((result: any) => {
                // console.log('result of getAllUsers', result.data);
                result.data.map((obj: any) => {
                    let sendObj = {
                        value: obj._id,
                        label: obj.name
                    };
                    this.optionsUserArray.push(sendObj);
                    // console.log('getAllUsersListgetAllUsersListgetAllUsersList', this.optionsUserArray)
                });
            });
        },
		 async getallTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].teamName, value: this.users[i]._id });
            }
        },
		 async getallEntities() {
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
        },
		selectInputStyling(column: any){
			if(column == 'auditStatus' || column == 'findingStatus'){
				return 'widthFor1stAudit'
			}
		},
		getColumnHeadWidth(column: any) {
            if (column == 'processName' || column == 'description') {
                return 'w-80';
            } else if( column == 'deleteIcon'){
                return 'w-20';
            }else if(column=='taskId'){
                return 'w-24';
            }
			else if(column == 'ccid'){
				return 'widthForccid';
			}
			else if(column == 'startDate' || column == 'dueDate' || column == 'team'){
                return 'w-28';
			}
			 else if(column == 'taskstatus' || column == 'Templates' || column == 'SubTaskStatus') {
				return 'w-36'
			} else if(column == 'framework_controlNo' || column == 'title' || column == 'businessProcessName') {
				return 'w-56'
			} else if(column == 'assignee'|| column == 'department' || column == 'entity' ) {
				return 'w-36'
			} else if(column == 'SubTaskStatus' || column=='CCID'|| column == 'teamName'  || column == 'controlNumber' || column == 'type' || column == 'name' || column == 'subTasktitle' ){
                return 'w-32';
            }else {
                return '';
            }
        },
	},
	async created(){
        // await this.getAllUsers();
		// await this.getallTeams();
		// await this.getallEntities();
    },
});
</script>

