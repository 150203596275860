<template>
	<div class="mb-5 mx-5 mt-3 ResponsiveControlePage">
		<div class="mb-2">
			<breadCrumbs :routesArray="routesArray"></breadCrumbs>
		</div>
		<div class="items-center justify-between flex">
			<div v-if="presentTemplateTask.mainTaskName" class="title flex items-center">
				<h1 :class="presentTemplateTask.mainTaskName != undefined && presentTemplateTask.mainTaskName.length > 40? 'heading-ellipsis':''">{{ presentTemplateTask.mainTaskName }}</h1>
			</div>
			<div v-if="presentTemplateTask.TaskName" class="title flex items-center">
				<h1 :class="presentTemplateTask.TaskName != undefined && presentTemplateTask.TaskName.length > 40? 'heading-ellipsis':''">{{ presentTemplateTask.TaskName }}</h1>
			</div>
			<div class="flex">
				
				<div v-if="checkOwner() && presentTemplateTask.statusCode == 10415">
					<!-- v-if="checkOwner() && presentTemplateTask.statusCode == 10402" -->
					<button class="btnprimary mr-1.5" :disabled="clickOnButton" @click="approveOrRejectTheParentApproval(10403)">Approve</button>
					<button class="btnprimary mr-1.5" :disabled="clickOnButton" @click="approveOrRejectTheParentApproval(10401)">Reject</button>
				</div>
				<div v-if="presentTemplateTask.statusCode == 10401 || presentTemplateTask.statusCode == 10402">
					<button class="btnprimary" v-if="presentTemplateTask.statusCode == 10401" @click="toStartMainTask(7)">Start</button>
				</div>
				
			</div>
		</div>
			
			<div v-if="dotsLoading && pageStatus == 200" class="mt-2">
                <div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
                    <dotsloader />
                </div>
            </div>
			<div v-else>
				<div class="w-full minheight">
					<div class="w-full">
						<div class="text-sm">
							<div class="mainContainer tabStructure flex flex-row w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey">
								<div class="w-75 heightFixing pr-5">
									<div class="tabStructureDiv">
										<div class="flex flex-row borderBottom justify-between cursor-pointer tab-container">
											<div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold primaryText':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
												<div>
													<div class="cursor-pointer">{{ tab.displayName }}</div>
													<div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
												</div>
											</div>
										</div>
										<div class="progressbarB rounded h-1"></div>
									</div>
									<div class="h-full">
										<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 1">
											<div>
												<div class="border-b border-lightgrey flex justify-between">
													<div class="flex items-center marginTopForDoc marginBottomForDoc justify-center">
														<div>
															<p class="my-1 header">Title</p>
														</div>
													</div>
												</div>
												
												<p class="mt-1.5 text-xs">{{ presentTemplateTask.mainTaskName }}</p>
											</div>
											<div>
												<div class="border-b border-lightgrey">
													<p class="my-1.5 header">Description</p>
												</div>
												<p class="mt-1.5 headerText"></p>
											</div>
											<div class="text-xs">
												{{ presentTemplateTask.description }}
											</div>
											
										</div>
										<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 2">
											<!-- <div> -->
												<div class="border-b border-lightgrey flex justify-between">
													<div class="flex items-center mt-0.5 marginTopForDoc marginBottomForDoc justify-center">
														<div>
															<p class="my-1 header">Documents</p>
														</div>
													</div>
													<div class="mb-1.5">
														<div class="buttonposition flex justify-center items-center">
															<!-- :class="TaskDetailsDropDown.taskStatus ==10419 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 || presentFindingTaskObj.auditor == userInfo._id? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" -->
															<!-- <button for="upload"  class="btnprimary flex justify-center taskEditButtons">Attach</button> -->
														</div>
													</div>
													
												</div>
												
											<!-- </div> -->
										</div>
										<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 3">
											<div class="border-b border-lightgrey flex justify-between">
												<div class="flex items-center mt-0.5 marginTopForDoc marginBottomForDoc justify-center">
													<div>
														<p class="my-1 header">Related Tasks</p>
													</div>
												</div>
												<div class="mb-1">														
													<div class="buttonposition flex justify-center items-center">
														<button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
															Filters
															<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
														</button>											
													</div>
												</div>												
											</div>
											<div>
												<tableData :tableRowData="tableRow" :headersData="Headers"></tableData>
												<div v-if="toolTipActive">
													<tool-tip :tipText="toolTipText"></tool-tip>
												</div>
											</div>
										</div>
										<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 4">
											<div>
												<!-- <div class="border-b border-lightgrey flex justify-end">
													<div><p class="my-1.5 header">Files </p></div>
													<div class="mb-1.5">
														<div class="buttonposition flex justify-center items-center">
															:class="TaskDetailsDropDown.taskStatus ==10419 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 || presentFindingTaskObj.auditor == userInfo._id? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'"
															<button @click="openFilePopup" for="upload"  :class="presentTemplateTask.statusCode == 10403? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" >Attach</button>
														</div>
													</div>
												</div> -->
												<div class="border-b border-lightgrey flex justify-between">
												<div class="flex items-center justify-center">
													<div>
														<p class="my-1.5 header">Evidence</p>
													</div>
												</div>
												<div>
													<div class="buttonposition flex justify-center items-center">
														<!-- :class="TaskDetailsDropDown.taskStatus ==10419 || approvalObj.statusCode == 10401 || presentFindingTaskObj.statusCode == 10403 ||  presentFindingTaskObj.auditor == userInfo._id?'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" -->
															<button @click="openFilePopup" for="upload"  :class="presentTemplateTask.statusCode == 10403? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" >Attach</button>
														</div>
													</div>
												</div>
												<!-- <p class="mt-1.5">Files</p> -->
												<div v-if="Object.keys(presentTemplateTask).includes('evidences')" class="flex mt-4 gap-4 flex-wrap">
													<div v-for="doc in presentTemplateTask.evidences" :key="doc">
														<div class="pdf w-full rounded-sm">
															<a :href="doc.url" class="flex w-full flex-row items-center">
																<img src="@/assets/pdfs.svg" alt="" />
																<p v-if="Object.keys(doc).includes('filename')" class="text-xs ml-2">{{doc.filename}}</p>
																<p v-else class="text-xs ml-1">{{ doc.name }}</p>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 6">
											<div>
												<div class="border-b border-lightgrey flex justify-between">
													<div class="flex items-center justify-center">
														<div>
															<p class="my-1.5 header">Comments</p>
														</div>
													</div>
													<div>
														<div class="buttonposition flex justify-center items-center">
															<div class="buttonposition flex justify-center items-center">
																<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortComments()">
																	<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
																	Newest
																</button>
																<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortComments()">
																	<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
																	Oldest
																</button>
															</div>
														</div>
													</div>
												</div>
												<div>
													<div class="comment-box w-full">
														<!-- <div class="my-3"><textarea placeholder="Add a Comment" :disabled="presentTemplateTask.statusCode == 10403" class="w-full text-xs border border-lightgrey rounded-sm p-2" rows="4"  v-model="comment" @input="handleInput"></textarea></div>
														<div class="flex justify-end">
															<button @click="addComment" class="mr-2" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Add</button>
															<button @click="clearComment" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Cancel</button>
														</div> -->
														<div class="my-3">
															<commentsEditor/>
														</div>
														
													</div>
													<div v-if="Object.keys(presentTemplateTask).includes('comments')" class="comments">
														<div v-for="(obj, index) in presentTemplateTask.comments" class="border border-lightgrey mt-2 rounded-sm flex flex-row p-2" :key="index">
															<div class="w-1/5">
																<div class="header">{{ getPersonName(obj.createdBy) }}</div>
																<div class="fontSize10PX">{{ obj.createdAt }}</div>
															</div>
															<div class="w-4/5 text-xs"><safeHtml :content="obj.comment"/></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 5">
											<div class="border-b border-lightgrey flex justify-between">
												<div class="flex items-center justify-center">
													<div>
														<p class="my-1.5 header">Approvals</p>
													</div>
												</div>
												<div>
													<div class="buttonposition flex justify-center items-center">
														<button @click="openApprovalpopup()" :class="presentTemplateTask.statusCode==10403 || presentTemplateTask.statusCode==10415 || presentTemplateTask.statusCode==10402 || presentTemplateTask.assignee.value == userInfo._id ?'approvalbtndisabled pointer-events-none paddingForAttachButton mb-1.5' : 'btnprimary flex justify-center taskEditButtons mb-1.5'" >Send Approval</button>
													</div>
												</div>
											</div>
											<div>
												<div v-for="(approval, number) in presentTemplateTask.approvals" :key="number" class="bg-white flex flex-row p-4 mb-0.5 border-lightgrey border-b w-full">
													<div v-if="approval.createdBy != undefined" class="initials">{{extractInitials(getPersonName(approval.createdBy))}}</div>
													<div v-if="approval.createdBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.createdBy) }}</div>
													
													<div v-if="approval.updatedBy != undefined" class="initials">{{extractInitials(getPersonName(approval.updatedBy))}}</div>
													<div v-if="approval.updatedBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.updatedBy) }}</div>

													<div v-if="approval.statusCode == 10401" class="mx-2 mt-1 text-xs"> {{' Requested an approval '}} </div>
													<div v-else-if="approval.statusCode == 10410" class="mx-2 mt-1 text-xs"> {{' Rejected an approval '}} </div>
													<div v-else-if="approval.statusCode == 10403" class="mx-2 mt-1 text-xs"> {{' Approved an approval '}} </div>

													<div v-if="approval.createdAt != undefined" class="font-semibold mt-1 text-xs">{{approval.createdAt}}</div>
													<div v-if="approval.updatedAt != undefined" class="font-semibold mt-1 text-xs">{{approval.updatedAt}}</div>
												</div>
											</div>
										</div>
										<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 7">
											<div>
												<div class="border-b border-lightgrey flex justify-between">
													<div class="flex items-center justify-center">
														<div>
															<p class="my-1.5 header">History</p>
														</div>
													</div>
													<div>
														<div class="buttonposition flex justify-center items-center">
															<div class="buttonposition flex justify-center items-center">
																<button class="btnprimary w-24 flex taskEditButtons" v-if="newHistory" @click="sortWorkLog()">
																	<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
																	Oldest
																</button>
																<button class="btnprimary w-24 flex taskEditButtons" v-if="!newHistory" @click="sortWorkLog()">
																	<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
																	Newest
																</button>
															</div>
														</div>
													</div>
												</div>
												
											<div v-for="work in workLogObject.taskHistory" :key="work" class="bg-white p-4 mb-0.5 border-lightgrey border-b w-full">
													<div class="flex">
														<div v-if="Object.keys(work).includes('createdBy')" class="initials">
															{{ extractInitials(getPersonName(work.createdBy)) }}
														</div>
														<div v-if="Object.keys(work).includes('updatedBy')" class="initials">
															{{ extractInitials(getPersonName(work.updatedBy)) }}
														</div>
														<div class="logContainer w-93">
															<div class="flex mt-1">
																<p v-if="Object.keys(work).includes('createdBy')" class="font-semibold text-xs">
																	{{ getPersonName(work.createdBy) }}
																</p>
																<p v-else-if="Object.keys(work).includes('updatedBy')" class="font-semibold text-xs">
																	{{ getPersonName(work.updatedBy) }}
																</p>
		
																<p v-if="work.actionEntity == 10301" class="mx-2 text-xs">{{ 'Created the Task on' }}</p>
																<p v-else-if="work.statusCode != undefined && work.statusCode == 10418" class="mx-2 text-xs">{{ 'Terminated the Task on' }}</p>
																<p v-else-if="work.actionEntity == 10302" class="mx-2 text-xs">{{ 'Updated the Task on' }}</p>
		
																<p v-if="Object.keys(work).includes('createdAt')" class="font-semibold  text-xs">{{ formatTime(work.createdAt) }}</p>
																<p v-if="Object.keys(work).includes('updatedAt')" class="font-semibold text-xs">{{ formatTime(work.updatedAt) }}</p>
															</div>
															<div class="mt-3" v-if="Object.keys(work).includes('additionalInfo')">
																<div class="flex" v-if="Object.keys(work.additionalInfo).includes('comment')">
																	<p class="font-semibold text-xs w-12">{{ 'Comment :' }}</p>
																	<p class="text-xs w-88"><safeHtml :content="work.additionalInfo.comment"/></p>
																</div>
																<div class="flex" v-if="Object.keys(work.additionalInfo).includes('approver')">
																	<p class="font-semibold text-xs">{{'Requested approval from -'}}</p>
																	<p class="mx-2 text-xs text-justify">{{ getPersonName(work.additionalInfo.approver) }}</p>
																</div>
																<div class="flex" v-if="work.additionalInfo.approvalStatusCode == 10410">
                                                                    <p class="font-semibold text-xs text-justify">{{'Approval is Rejected'}}</p>
                                                                </div>
                                                                <div class="flex" v-if="work.additionalInfo.approvalStatusCode == 10403">
                                                                    <p class="font-semibold text-xs text-justify">{{'Approval is Accepted'}}</p>
                                                                </div>
																<div class="flex" v-if="Object.keys(work.additionalInfo).includes('filename')">
																	<p class="font-semibold text-xs">{{'Uploaded the Evidence -'}}</p>
																	<p class="mx-2 text-xs">{{ work.additionalInfo.filename }}</p>
																</div>
																<div class="flex" v-if="Object.keys(work.additionalInfo).includes('assignee')">
																	<p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
																	<p class="mx-2 text-xs">{{ getPersonName(work.additionalInfo.assignee) }}</p>
																</div>
																<div class="flex items-center" v-if="Object.keys(work.additionalInfo).includes('previousStatus')">
																	<div>
																		<p class="font-semibold text-xs">{{'Updated The Status :'}}</p>
																	</div>
																	<div class="flex ml-4">
																		<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorTemplateBg(work.additionalInfo.previousStatus)">
																			<div :class="colorTemplateText(work.additionalInfo.previousStatus)">
																				{{ getStatusLabel(work.additionalInfo.previousStatus) }}
																			</div>
																		</div>
																		<div class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
																		<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorTemplateBg(work.additionalInfo.updatedStatus)">
																			<div :class="colorTemplateText(work.additionalInfo.updatedStatus)">
																				{{ getStatusLabel(work.additionalInfo.updatedStatus) }}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="w-25 border taskDetailsRightBar rounded-sm border-lightgrey">
									<div class="m-3">
										<div class="pb-2 border-b border-lightgrey">
											<h1>Task Details</h1>
										</div>
										<div class="pt-1.5 overflow-y-scroll thirtyWidthToggle">
											<div class="flex flex-col mb-3">
												<label class="controllabel">Task Status</label>
												<div :class="changeBackground()" class="inputboxstyling1 cursor-pointer" >
													<div :class="changeBackground()" @change="updateTaskStatus(TaskDetailsDropDown.taskStatus)">
														{{ getStatusLabel1(presentTemplateTask.statusCode) }}
													</div>
												</div>
											</div>
				
											
											<div class="mb-3">
												<div>
													<label class="controllabel">Assigned To</label>
													<div>
														<div class="mt-2 mb-3" v-if="presentTemplateTask.statusCode == 10403 || presentTemplateTask.statusCode == 10402 || (presentTemplateTask.createdBy != userInfo._id && presentTemplateTask.assignee != undefined && presentTemplateTask.assignee.value != userInfo._id)">
															{{presentTemplateTask.assignee != undefined ? presentTemplateTask.assignee.label : 'N/A'}}
														</div>
														<div v-else class="mt-2">
															<multiselect
																v-model="presentTemplateTask.assignee"
																:options="userOptions"
																:showNoOptions="false"
																@search-change="asyncFind"
																:limit-text="limitText"
																:multiple="false"
																:close-on-select="true"
																:disabled="presentTemplateTask.statusCode == 10403"
																:clear-on-select="true"
																label="label"
																open-direction="bottom"
																:hide-selected="true"
																track-by="value"   
																@select="updateAssignedMember(presentTemplateTask.assignee)" 
																:searchable="true"
																:hideArrow="false"
																placeholder="Assigned-To"
																deselectLabel=""
																selectLabel=""
																class="custom-multiselect userSelect"
																:class="{
																	'has-options': userOptions && userOptions.length > 0,
																	'no-options': !userOptions.length &&  searchValue !== ''
																}"
															>
																<template v-slot:noResult>
																	<span>No Members Found</span>
																</template>
															</multiselect>
														</div>
													</div>
												</div>
											</div>
											<div class="mb-3">
												<label class="controllabel">Created By</label><br>
												<!-- show process Owner -->
												<div class="my-1">{{ getPersonName(presentTemplateTask.createdBy) }}</div>
											</div>
											<div class="mb-3">
												<label class="controllabel">Start Date</label><br>
												<!-- show Start Date -->
												<div class="my-1">{{ changeDateFormat(presentTemplateTask.startDate) }}</div>
											</div>
											<div class="mb-3">
												<label class="controllabel">Due Date</label><br>
												<!-- show Due Date -->
												<div class="my-1">{{ changeDateFormat(presentTemplateTask.dueDate) }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		<popup v-if="importpopUp" :popUp="importpopUp">
			<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
			<div class="relative w-full max-w-xl">
			<!--content-->
			<div class="border-0 rounded relative flex flex-col w-full bg-white h-screen">
				<!--header-->
				<div class="flex items-center justify-between popupheadingcontainer rounded">
				<h1>Import files</h1>
				<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCancelIcon()">
					<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
				</button>
				</div>
				<div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
				<div class="impactcontainer popupbodycontainer">
				<div class="formcontainer flex flex-col">
					<div class="row flex flex-col">
					<div class="upperspacing">
						<label class="controllabel">File Name </label>
						<input class="inputboxstyling mt-2" type="text" v-model.trim="inputFileName" placeholder="Filename"  :class="{'is-invalid': v$.inputFileName.$error }"/>
						<div v-if="v$.inputFileName.$error " class="text-red-500">
							<div class="error-text" v-if="v$.inputFileName.required.$invalid ">Required</div>
						</div>
					</div>
					<div class="upperspacing">
						<label class="controllabel">Attachment</label>
						<div class="w-auto evidenceHeight bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2" :class="{'is-invalid':v$.fileToUpload.$error}">
							<!-- <label htmlFor="upload" class="controllabel">Upload File</label> -->
							<input type="file" ref="fileInput" class="hidden" id="upload" placeholder="Choose a file to upload" @change="handleFileUpload"/>
							<label for="upload" class="float-right cursor-pointer text-white flex justify-center items-center w-9 h-7 bg-primary"><img src="@/assets/Browse.svg" /></label>
							<div v-if="fileName" class="text-xs p-1">{{ fileName }}</div>
						</div>
					</div>
					
					<div v-if="v$.fileToUpload.$error" class="text-red-500">
						<div v-if="v$.fileToUpload.$invalid" class="error-text text-xs">File upload is required</div>
					</div>
					</div>
				</div>
				</div>
				<!--footer-->
				<div class="buttonposition flex justify-center items-center">
					<button class="btnprimary" @click="handleFileChange" :class="(inputFileName.length == 0) ? 'btndisabled pointer-events-none ' : 'btnprimary'">Save</button>
				</div>
			</div>
			</div>
			</div>
		</popup>
		<popup v-if="showSubTaskPopUp" :popUp="showSubTaskPopUp">
			<template v-slot:header>
				<div class="flex items-center justify-between m-4 rounded">
					<h1>Sub Tasks</h1>
					<button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
						<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
					</button>
				</div>
			</template>
			<fieldset>
				
				<!-- [ "ccid", "title", "team", "startDate", "dueDate", "status", "description", "assignee" ] -->
				<!-- ccid: "668fcf02883b2b4764b9543c"
					dueDate: "2024-08-02"
					startDate: "2024-07-25"
					status: 10411
					team: "IT"
					title: "fghfdxh ftgh rtsy sdfht strystr"
					subTaskDetailsObj -->
					<div class="popup-business">
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="upperspacing">
                                        <label for="number" class="controllabel">Title</label><br />
                                        <input class="inputboxstyling mt-2" placeholder="Task Name"  v-model.trim="subTaskDetailsObj.subTasktitle" :class="{'is-invalid': v$.subTaskDetailsObj.subTasktitle.$error }"/>
                                        <div v-if="v$.subTaskDetailsObj.subTasktitle.$error " class="text-red-500">
                                            <div class="error-text" v-if="v$.subTaskDetailsObj.subTasktitle.required.$invalid ">Required</div>
                                        </div>
										<!-- <div class="inputboxstyling mt-2">{{ subTaskDetailsObj.subTasktitle }}</div> -->
                                    </div>
                                    <div class="row flex flex-row upperspacing w-full gap-3">
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Start Date</label>
                                            <input
                                                type="date"
                                                class="inputboxstyling mt-2"
                                                @focus="openCalendar"
                                                :min="today"
                                                onkeydown = "return false"
                                                @change="checkForStartDate"
                                                placeholder="Select Start Date"
                                                v-model="subTaskDetailsObj.startDate"
                                                :class="{'is-invalid': v$.subTaskDetailsObj.startDate.$error,'inputboxstylingForSelectAtPopup': subTaskDetailsObj.startDate === '','stylingForInputboxstylingForSelectAtPopup': subTaskDetailsObj.startDate !== ''}"
                                            />
                                            <div v-if="v$.subTaskDetailsObj.startDate.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.subTaskDetailsObj.startDate.required.$invalid ">Required</div>
                                            </div>
											<!-- <div class="inputboxstyling mt-2">{{ subTaskDetailsObj.startDate }}</div> -->
                                        </div>
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Due Date</label>
                                            <input
                                                type="date"
                                                class="inputboxstyling mt-2"
                                                @focus="openCalendar"
                                                onkeydown = "return false"
                                                :min="subTaskDetailsObj.startDate||today"
                                                @change="checkForStartDate"
                                                placeholder="Select Start Date"
                                                v-model="subTaskDetailsObj.dueDate"
												:class="{'is-invalid': v$.subTaskDetailsObj.dueDate.$error,'inputboxstylingForSelectAtPopup': subTaskDetailsObj.dueDate === '','stylingForInputboxstylingForSelectAtPopup': subTaskDetailsObj.dueDate !== '' }"
                                            />
											
                                            <div v-if="v$.subTaskDetailsObj.dueDate.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.subTaskDetailsObj.dueDate.required.$invalid ">Required</div>
                                            </div>
											<!-- <div class="inputboxstyling mt-2">{{ subTaskDetailsObj.dueDate }}</div> -->
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full gap-3 upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Responsibility</label>
											<div class="mt-2">
											<multiselect
                                                v-model="subTaskDetailsObj.team"
                                                :options="teamOptions"
                                                :showNoOptions="false"
                                                @search-change="asyncFind2"
                                                :limit-text="limitText"
                                                :multiple="false"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Responsibility"
												class="custom-multiselect userSelect"
                                                :class="{
													'is-invalid': v$.subTaskDetailsObj.team.$error,
													'has-options': teamOptions && teamOptions.length > 0,
													'no-options': !teamOptions.length &&  searchValue !== ''
												}"
                                            >
                                                <template v-slot:noResult>
                                                    <span>No team Found</span>
                                                </template>
                                            </multiselect>
											</div>
                                            <!-- <input class="inputboxstyling mt-2" placeholder="Responsibility" v-model="subTaskDetailsObj.team" :class="{'is-invalid': v$.subTaskDetailsObj.team.$error }"/> -->
                                            <div v-if="v$.subTaskDetailsObj.team.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.subTaskDetailsObj.team.required.$invalid ">Required</div>
                                            </div>
											<!-- <div class="inputboxstyling mt-2">{{ subTaskDetailsObj.team }}</div> -->
                                        </div>
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Assigned To</label>
											<div class="mt-2">
                                            <multiselect
                                                v-model="subTaskDetailsObj.assignee"
                                                :options="userOptions"
                                                :showNoOptions="false"
                                                @search-change="asyncFind"
                                                :limit-text="limitText"
                                                :multiple="false"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
												class="custom-multiselect userSelect"
                                                placeholder="Assigned to"
                                                :class="{
													'is-invalid': v$.subTaskDetailsObj.assignee.$error,
													'has-options': userOptions && userOptions.length > 0,
													'no-options': !userOptions.length &&  searchValue !== ''
												}"
                                            >
                                                <template v-slot:noResult>
                                                    <span>No Members Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.subTaskDetailsObj.assignee.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.subTaskDetailsObj.assignee.required.$invalid ">Required</div>
                                            </div>
										</div>
                                        </div>
                                    </div>
                                    <div class="upperspacing">
                                        <label class="controllabel text-xs">Description</label><br />
                                        <textarea name="Description" placeholder="Description" id="" class="inputboxstyling mt-2" v-model.trim="subTaskDetailsObj.description"  :class="{'is-invalid': v$.subTaskDetailsObj.description.$error }" ></textarea>
                                        <div v-if="v$.subTaskDetailsObj.description.$error " class="text-red-500">
                                            <div class="error-text" v-if="v$.subTaskDetailsObj.description.required.$invalid ">Required</div>
                                        </div>
                                    </div>
									<div class="upperspacing">
                                        <label class="controllabel text-xs">Notes</label><br />
                                        <textarea name="Description" placeholder="Notes" id="" class="inputboxstyling mt-2" v-model.trim="subTaskDetailsObj.notes"></textarea>
                                        <!-- <div v-if="v$.subTaskDetailsObj.description.$error " class="text-red-500">
                                            <div class="error-text" v-if="v$.subTaskDetailsObj.description.required.$invalid ">Required</div>
                                        </div> -->
                                    </div>
                                    
                                </div>
                                
                                <div class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
									<!-- :class="(JSON.stringify(subTaskDetailsObjDummy) == JSON.stringify(subTaskDetailsObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" -->
                                    <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(subTaskDetailsObjDummy) == JSON.stringify(subTaskDetailsObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Reset</button>
                                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(subTaskDetailsObjDummy) == JSON.stringify(subTaskDetailsObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickSubmitBtn(subTaskDetailsObj)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</fieldset>
		</popup>
		<popup v-if="showApprovalPopup" :popUp="showApprovalPopup">
			<template v-slot:header>
				<div class="flex items-center justify-between popupheadingcontainer rounded">
					<h1>Approval</h1>

					<button class="float-right text-3xl" @click="onCrossbtn()">
						<img src="@/assets/cancel.svg" class="cursor-pointer" />
					</button>
				</div>
			</template>
			
			<div class="popup-business">
				<div>
					<div>
						<div class="statuscontainer px-5 pb-5">
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row upperspacing">
									<div class=" w-1/2">
										<label class="controllabel mb-2">Name of the Authority</label>
										<div class="mt-2">
										<multiselect
											v-model="taskObj.approver"
											:options="authorityOptions"
											:showNoOptions="false"
											@search-change="asyncFind1"
											:limit-text="limitText"
											:multiple="false"
											label="label"
											open-direction="bottom"
											track-by="value"
											:searchable="true"
											:hideArrow="false"
											placeholder="Assigned-To"
											deselectLabel=""
											selectLabel=""
											class="custom-multiselect userSelect"
											:class="{
												'is-invalid': v$.taskObj.approver.$error,
												'has-options': userOptions && userOptions.length > 0,
												'no-options': !userOptions.length &&  searchValue !== ''
											 }"
										></multiselect>
									</div>
									<div v-if="v$.taskObj.approver.$error " class="text-red-500">
										<div class="error-text" v-if="v$.taskObj.approver.$invalid ">Required</div>
									</div>
									</div >
								</div>
								<div class="row flex flex-row upperspacing">
									<div class="w-full">
										<label class="controllabel">Justification or Comments for Approving</label>
										<textarea
											class="inputboxstyling mt-2"
											placeholder="Comments"
											v-model.trim="taskObj.approvalComments"
											:class="{'is-invalid': v$.taskObj.approvalComments.$error }"
										/>
										<div v-if="v$.taskObj.approvalComments.$error " class="text-red-500">
											<div class="error-text" v-if="v$.taskObj.approvalComments.$invalid ">Required</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="buttonposition flex justify-center items-center mb-4 mx-0">
						<button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(taskObj) == JSON.stringify(taskObjDummy)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtnAtApproval()">Reset</button>
						<button class="" :disabled="clickOnButton" :class="(JSON.stringify(taskObj) == JSON.stringify(taskObjDummy)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="clickOnSaveForApproval(taskObj)">Submit</button>
					</div>
				</div>
			</div>
		</popup>
	</div>
</template>
<style scoped>
.progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
}
.numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 2px solid #152A43;
    /* border-width: 2px; */
}
.inputboxstyling1 {
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(233, 233, 233, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  /* background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); */
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  --tw-text-opacity: 1;
  /* color: rgba(77, 77, 77, var(--tw-text-opacity)); */
}
.progress {
	background: #152a43;
	position: relative;
	border-radius: 4px;
	z-index: 2;
}
.primaryBackground{
	background-color: #152a433b
}
.descriptionToggle {
	height: 97%;
}
.tabStructure {
	height: calc(100vh - 165px);
}
.thirtyWidthToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.thirtyWidthToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.thirtyWidthToggle{  
     height:  calc(100vh - 250px);
}
.descriptionToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}
.filterButton{
   padding: 7px !important;
}
.descriptionToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.heightFixing {
	height:  calc(100vh - 220px);
	/* height : 64vh; */
}
option {
	background-color: #ffffff;
	color: #000000;
	border-color: pink transparent transparent transparent;
}
.textColorWhite {
	color: white;
}
.approvalbtndisabled {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(233 233 233 / var(--tw-bg-opacity));
    padding: 6px;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: .75rem;
    --tw-text-opacity: 1;
    color: rgb(77 77 77 / var(--tw-text-opacity));
}
.evidenceHeight{
	height: 29px;
}
.custom-select {
    background-image: url("../assets/Collapse.svg") !important;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    background-position: right 15px center; /* Change the px value here to change the distance */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.marginBottomForDoc{
	margin-bottom : 1.5px;
}
.marginTopForDoc{
	margin-top : 2.5px;
}
.marginBottomZero{
	margin-bottom : 0px;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.labelWidth{
	min-width: 7rem;
}
.framework{
	padding: 12px 170px 12px 12px;
}
.findingCategory{
	padding: 12px 80px 12px 12px;
}
.btnprimary1 {
	cursor: pointer;
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-family: Open Sans;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import breadCrumbs from '@/components/breadCrumbs.vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import popup from '@/components/popup.vue';
import tableData from '@/components/tableData.vue';
import toolTip from '@/components/toolTip.vue'
// import { groupBy } from 'lodash';
import { emitter, toast } from '@/main';
// import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import commentsEditor from '@/components/commentsEditor.vue';
import Multiselect from 'vue-multiselect';
import safeHtml from '@/components/safeHtml.vue';
import dotsloader from '@/components/dotsloader.vue';

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			selectedTabIndex: null,
			fileToUpload: null,
			pageStatus:false,
			tabHead: [
				{ displayName: 'Description', id: 1 },

				{ displayName: 'Documents', id: 2 },

				{ displayName: 'Related Tasks', id: 3 },

				{ displayName: 'Evidence', id: 4 },

				{ displayName: 'Approvals', id: 5 },

				{ displayName: 'Comments', id: 6 },

				{ displayName: 'History', id: 7 }
			],
			teamOptions:[],
            clickOnButton: false,
			selectedTabObj:{},
			presentTemplateTask:[],
			Headers: ['ccid','subTasktitle','team','startDate','dueDate','SubTaskStatus'],
			tableRow:[],
			importpopUp: false,
			inputFileName: '',
			fileName:'',
			isInputFileNameValid: true,
			showSubTaskPopUp: false,
			TaskDetailsDropDown: {
				taskStatus: '',
				assignedToDropDown: ''
			},
			taskObj:{
				approver:'',
                approvalComments : ''
            },
			taskObjDummy:{
				approver:'',
                approvalComments : ''
			},
			userOptions:[],
			routesArray:[],
			allSubTasks:[],
			users:[],
			showApprovalPopup: false,
			parentTaskStatus:[
				{
					id: 10401,
					value: 'Open'
				},
				{
					id: 10402,
					value: 'In Progress'
				},
				{
					id: 10415,
					value: 'In Review'
				},
				{
					id: 10403,
					value: 'Completed'
				},
			],
			
			// [ "ccid", "title", "team", "startDate", "dueDate", "status", "description", "assignee" ] 
			subTaskDetailsObj:{
				// ccid:'',
				subTasktitle:'',
				team:'',
				startDate:'',
				dueDate:'',
				description:'',
				assignee:'',
				notes:''
			},
			subTaskDetailsObjDummy:{},
			oldComments: true,
			newHistory: true,
			comment: '',
			comments: [],
			btnDisabled: true,
			selectedOption: '',
			selectedBG: '',
			workLogObject: [],
			optionsUserArray: [],
			orgUsersList: [],
			presentAuditObject: {},
			tasksForAudit: [],
			personObj : '',
			controlsArray : [],
			controlDetailsArray : [],
            controlsArrayAllControls : [],
            controlsDetailsList : [],
			teamObj : '',
			allFrameWorkControls:[],
			locationObject:[],
			fileSelected: false,
			isFileValid: true,
			fileChanged : false,
			findingsForTask:[],
			allActiveControls:[],
			teamObject:[],
			approvingMembers:[],
			toolTipActive:false,
			titleLength: 0,
            titleLengthForTaskName: 0,
            toolTipText:'',
			searchValue:''	
				// fileObj:{
			// 	inputFileName:'',
			// 	fileToUpload: null,
			// },
		};
	},
	validations() {
		return {
			inputFileName:{required},
			fileToUpload: {required},
			subTaskDetailsObj:{
				// ccid:{required},
				subTasktitle:{required},
				team:{required},
				startDate:{required},
				dueDate:{required},
				description:{required},
				assignee:{required},
			},
			taskObj:{
				approver:{required},
				approvalComments:{required},
			},
			authorityOptions:[]
		};
	},
	components: {
		breadCrumbs,
		popup,
		Multiselect,
		tableData,
		commentsEditor,
		safeHtml,
		dotsloader,
		toolTip
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo',dotsLoading: 'dotsLoading' }),
		today() {
			const today = new Date();
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, '0'); 
			const day = String(today.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		}
	},
	methods: {
		limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		changeDateFormat(date: any) {
            if(date != undefined){
                return date.split('-').reverse().join('-');
            }
		},
		openCalendar(event: any) {
            event.target.showPicker();
        },
		checkForStartDate(){
			if(this.subTaskDetailsObj.dueDate != ''){
				if(this.subTaskDetailsObj.startDate > this.subTaskDetailsObj.dueDate){
					this.subTaskDetailsObj.dueDate = ''
				}
			}
		},
		getStatusLabel1(statusCode: any){
			let result: any = this.parentTaskStatus.find((data: any)=>{
				return data.id == statusCode
			})
			if(result != undefined){
				return result.value
			} else{
				return 'N/A'
			}
			
		},
		asyncFind2(query:any){
			this.searchValue = query;
			if(query == ''){
				console.log("intooo empty",query)
				this.teamOptions = []
			}else if(query.length > 0){
				this.teamOptions = this.teamObject.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		asyncFind(query:any){
			this.searchValue = query;
			if(query == ''){
				console.log("intooo empty",query)
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		asyncFind1(query:any){
			this.searchValue = query;
			if(query == ''){
				console.log("intooo empty",query)
				this.authorityOptions = []
			}else if(query.length > 0){
				this.authorityOptions = this.approvingMembers.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		changeFrameWorkControlNums(){
			console.log('saidwiudhks',this.findingsObject.frameworks)
			this.selectedControlNumbers = []
			this.findingsObject.frameWorkControl = ''

			if(this.findingsObject.frameworks == 'Internal Controls'){
				this.selectedControlNumbers.push(this.presentTemplateTask.controlNumber);
				this.findingsObject.frameWorkControl = this.presentTemplateTask.controlNumber
			}else{
				let obj:any = this.presentTemplateTask.frameworks.find((obj:any)=>{return obj.framework == this.findingsObject.frameworks});
				obj.controlsDetailsArray.map((ctrlObj:any)=>{this.selectedControlNumbers.push(ctrlObj.controlNumber);})
			}
		},
		toStartMainTask(type: any){
			let payload : any ={createdBy: this.userInfo._id}
			this.updateTemplateTask(type,payload)
			// this.$router.push({ name: 'tasks'});
			// this.$router.push({ name: 'tasks'});
		},
		async getParentTaskHistoryObject() {
			// /api/org/task/:taskId/history/get
			
			// for task history
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${this.$route.params.taskId}/history/get`).then((result: any) => {
				const mainObject = result.data;
				this.workLogObject = mainObject;
				console.log('this.workLogObject', this.workLogObject);
			});
		},
		// onClickSubmitBtn(obj:any){
		// 	this.v$.$touch();
		// 	if(!this.v$.subTaskDetailsObj.$invalid){
		// 		this.onClickUpdateBtn(obj)
		// 		this.showSubTaskPopUp = false
		// 	}
			
		// },
		async onClickSubmitBtn(obj: any){
			console.log("objjjjjjjjjjjjjjj", obj)
			this.v$.$touch();
			// /api/org/subTask/:taskId/update
 
			// POST
			
			// { Updated key value pairs}
			// subTaskDetailsObj:{
			// 	ccid:'',
			// 	title:'',
			// 	team:'',
			// 	startDate:'',
			// 	dueDate:'',
			// 	status:'',
			// 	description:'',
			// 	assignee:'',
			// 	notes:''
			// },
			console.log("!this.v$.subTaskDetailsObj.$invalid",this.v$.subTaskDetailsObj.$invalid)
			if(!this.v$.subTaskDetailsObj.$invalid){
				this.clickOnButton = true;
				
			let taskId:any = obj._id
			const copyOfgobj: any={
				title:obj.subTasktitle,
				team:obj.team.value,
				startDate:obj.startDate,
				dueDate:obj.dueDate,
				description:obj.description,
				assignee:obj.assignee.value,
				notes:obj.notes,
				updatedBy:this.userInfo._id
			}
			const payload: any ={...copyOfgobj}
			console.log("payyyyyyyyyyyyyyload",payload)
			try {
				await this.$http.post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/subTask/${taskId}/update`, payload).then(async (result: any) => {
					console.log('updated');
					toast.info(`Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.showSubTaskPopUp = false
				this.v$.$reset();
			} catch {
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
                this.clickOnButton = false;
            }
		}
			await this.getAllSubTasksForTemplate();
			// await this.updateTemplateTask(5,payload)
			
		},
		getLocation(teamId:any){
                
			let presentteamObject:any = this.users.find((obj: any) => {
				return obj._id == teamId;
			});

			if (presentteamObject!=undefined && presentteamObject.type == 20301){
				return 'Global';
			}else{
				if(this.presentTemplateTask.location == undefined || this.presentTemplateTask.location == null ){
					return 'N/A'
				}else{
					return this.getLocationName(this.presentTemplateTask.location[0].entity)
				}
			}
		},
		getLocationName(locationId: any) {
            let locationObj = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
			// console.log("locationObj",locationId.entity)
            if (locationObj == undefined) {
                let empty = '';
                return empty;
            } else {
                return locationObj.label;
            }
        },
		onCancelIcon(){
			this.importpopUp = false
			this.inputFileName = ''
			this.fileName = ''
			this.fileToUpload=null
			this.v$.$reset();
		},
		getStatusLabel(num:any){
			let result = this.parentTaskStatus.find((obj:any)=>{return obj.id == num})
			return result.value
		},
		// updateAuditResult() {
		// 	const category = this.findingsObject.findingCategory;
		// 	if (category === 1 || category === 2) {
		// 		this.findingsObject.auditResult = 'Fail';
		// 	} else if (category === 3 || category === 4) {
		// 		this.findingsObject.auditResult = 'Pass';
		// 	} else {
		// 		this.findingsObject.auditResult = '';
		// 	}
		// },
		scrollTabIntoView(tabId : any) {
			this.$nextTick(() => {
				const tabElement = this.$el.querySelector(`.tab:nth-child(${tabId})`);
				if (tabElement) {
				tabElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
				}
			});
		},
		getTeamName(team: any) {
            let teamObj = this.users.find((obj: any) => {
                return obj._id == team;
            });
            if (teamObj == undefined) {
                return '';
            } else {
                return teamObj.teamName;
            }
        },
		filterOpen() {
			this.showFilters = !this.showFilters;
			// if (this.showFilters == true) {
			// 	this.dummyList = this.savedAuditSets;
			// } else {
			// 	this.savedAuditSets = this.dummyList;
			// }
			emitter.emit('visible', this.showFilters);
		},
		getBorder(){
            return `numberBorder numberNavy`
        },
		openApprovalpopup(){
			this.showApprovalPopup = true
		},
		onCancelbtnAtApproval(){
			this.v$.$reset();
			this.taskObj.approver = ''
			this.taskObj.approvalComments = ''
		},
		onCrossbtn(){
			this.v$.$reset();
			this.taskObj.approver = ''
			this.taskObj.approvalComments = ''
			this.showApprovalPopup =  false
		},
		checkOwner(){
			let approver: any 
			if(this.presentTemplateTask.approvals){
				let approvals: any = this.presentTemplateTask.approvals
				approvals.forEach((obj:any) => {
					approver = obj.approver
				});
				if(approver == this.userInfo._id){
					return true
				} else{
					return false
				}
			}
		},
		async getAllActiveControls(){
		await this.$http
			.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`)
			.then((result: { data: any }) => {
				console.log("Active controls data",result.data)
				this.allActiveControls = result.data;
			})
			.catch((error: any) => {
				toast.error(` ${error}`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});
        },
		clickOnSaveForApproval(obj: any){
			// (TYPE = 3) for sending approvals
			// {approver:'userId',createdBy:'',createdAt:''}
			let payload: any = {
				approver :obj.approver.value,
			}
			this.v$.$touch();
			if(!this.v$.taskObj.$invalid){
				this.clickOnButton = true;
				this.updateTemplateTask(3,payload)
				// console.log("odfhijg")
				this.taskObjDummy = this.taskObj
				// this.checkOwner();
				this.taskObj.approver = ''
				this.taskObj.approvalComments = ''
				this.showApprovalPopup = false
				this.v$.$reset();
			}
			
			
		},
		async approveOrRejectTheParentApproval(statusCode: any){
			this.clickOnButton = true;
			let taskId: any = this.$route.params.taskId
			const payload: any={
				statusCode: statusCode,
				updatedBy: this.userInfo._id
			}
			// /api/org/task/:taskId/update/approval

			// POST

			// => payload for approving approval

			// {statusCode: 10403 , updatedBy:'',updatedAt:''}
			await this.$http.post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${taskId}/update/approval`,payload).then((res: any) => {
                
				console.log("this.presentTemplateTask",this.presentTemplateTask)
            });
			if(statusCode == 10403){
				
                toast.success(`Approval Approved`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
				});
				await this.getParentTaskHistoryObject()
            }else if(statusCode == 10401){
				
                toast.error(`Approval Rejected`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
				});
				await this.getParentTaskHistoryObject() 
            }
			await this.getPresentTemplateTask();
			this.workLogObject.taskHistory.sort().reverse();
		},
		async getAllActiveUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
				this.orgUsersList = res.data;
				
				for (var i = 0; i < this.orgUsersList.length; i++) {
					// if(this.orgUsersList[i].isActive == true){
						console.log("this.optionsUserArray")
						this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
					// }
					if(this.userInfo._id != this.orgUsersList[i]._id){
						console.log("this.approvingMembers")
						this.approvingMembers.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
						console.log("this.approvingMembers",this.approvingMembers)
					}
				}
			});
			console.log('this.orgUsersList', this.orgUsersList);
		},
		// /api/org/task/:taskId/get
		async getPresentTemplateTask() {
			let taskId : any = this.$route.params.taskId
			// /api/org/task/:taskId/get
            await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${taskId}/get`).then((res: any) => {
                this.presentTemplateTask = res.data;
				// console.log("resres",res)
				this.pageStatus = res.status
				console.log("this.presentTemplateTask",this.presentTemplateTask)
            });
			this.TaskDetailsDropDown.taskStatus = this.presentTemplateTask.statusCode;
			if(this.presentTemplateTask.assignee != undefined){
				let userObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentTemplateTask.assignee});
				this.presentTemplateTask.assignee = userObj;
			}else{
				let userObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentTemplateTask.auditor});
				this.presentTemplateTask.assignee = userObj;
			}
            
        },
		async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
		async getAllTeams() {
            this.teamObject = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
            });
            console.log('users',this.users)
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].displayName, value: this.users[i]._id });
            }
            console.log('teamObject',this.teamObject)
        },
		getPersonName(person: any) {
			console.log("personnnn",person)
			let personObj = this.orgUsersList.find((user: any) => {
              
				return user._id == person;
			});
			console.log("personObj",personObj)
			if (personObj == undefined) {
				return '';
			} else {
				return personObj.name;
			}
		},
		getPerson(person: any) {
			this.personObj = this.orgUsersList.find((user: any) => {
              
				return user._id == person;
			});
			if (this.personObj == undefined) {
				return '';
			} else {
				return this.personObj.name;
			}
		},
		sortWorkLog() {
			this.newHistory = !this.newHistory;
			this.workLogObject.taskHistory.sort().reverse();
		},
		sortComments() {
			this.oldComments = !this.oldComments;
			if(this.presentTemplateTask.comments != undefined){
				this.presentTemplateTask.comments.sort().reverse();
			}
		},
		formatTime(time: any) {
			let now = moment(time);
			return now.format('DD-MM-YYYY, hh:mm:ss');
		},
		extractInitials(name: any) {
			const names = name.split(' ');
			return names
				.map((name: any) => name[0])
				.join('')
				.toUpperCase();
		},
		changeBackground() {
			if (this.TaskDetailsDropDown.taskStatus == 10411) {
				return 'bg-disabledgrey textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10415) {
				return 'bg-yellow textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10402) {
				return 'bg-electricGreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10403) {
				return 'bg-lightgreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10401) {
				return 'bg-darkred textColorWhite';
			} else {
				return '';
			}
		},
		checkAuditor(id:any){
			console.log("this.userInfo",this.userInfo)
			if(id == this.userInfo._id){
				return true
			} else if(id == undefined || id == null){
				return false
			} else {
				return false
			}
		},
		async updateTaskStatus(status: any) {
			let payload: any = { statusCode: status };
			await this.updateTemplateTask(5,payload)
		},
		async updateAssignedMember(obj: any) {
			let payload: any = { assignee: obj.value };
			await this.updateTemplateTask(6,payload)
		},
		handleInput() {
			this.btnDisabled = this.comment.length === 0;
		},
		async addComment() {
			if (this.comment.trim() !== '') {
				const newComment: any = {
					comment: this.comment
				};
				await this.updateTemplateTask(4, newComment);
				// this.comments.push(newComment);
				if(this.presentTemplateTask.comments != undefined){
					this.presentTemplateTask.comments.sort().reverse();
				}
				this.comment = '';
				this.btnDisabled = true;
			}
		},
		clearComment() {
			this.comment = '';
			this.btnDisabled = true;
		},
		async toggleTabs(obj: any) {
			this.scrollTabIntoView(obj.id);
			this.selectedTabIndex = obj.id;
			this.selectedTabObj = obj;
			sessionStorage.setItem('selectedTab',obj.id);
		},
		
		onCancelButtonAddPopUp() {
			this.v$.$reset();
			this.showSubTaskPopUp = false;
		},
		onCancelbtn(){
			this.v$.$reset();
			this.subTaskDetailsObj = {...this.subTaskDetailsObjDummy}
			// this.showSubTaskPopUp = false;
		},
		openFilePopup() {
			// this.$refs.fileInput.click();
			this.importpopUp = true
		},
		updateFileName(event: any) {
			// const selectedFile = event.target.files[0];
			// this.newDocumentObj.file = selectedFile;
			let file = this.$refs.fileInput.files[0];

			if (file && !file.type.match('application/pdf') && !file.name.endsWith('.doc') && !file.name.endsWith('.docx')) {
				alert('Please select a PDF, DOC, or DOCX file.');
				return;
			} else {
				this.fileChanged = true;
				this.fileToUpload.file = file;
				console.log("this.fileToUpload.file",this.fileToUpload.file)
			}
		},
		handleFileUpload(event: any) {
            const file = this.$refs.fileInput.files[0];
            if (file) {
                this.fileName = file.name;
                this.fileToUpload = file
                console.log("file",file)
                 const fileNameParts = file.name.split('.');
                // console.log('fileNameParts1',fileNameParts)
                fileNameParts.pop(); // Remove the extension
                // console.log('fileNameParts2',fileNameParts)
                const nameWithoutExtension = fileNameParts.join('.');
                // console.log('fileNameParts',nameWithoutExtension)
                if (this.inputFileName.trim() === '') {
                this.inputFileName = nameWithoutExtension;
                }
                // console.log('inputFileName',this.inputFileName)
            } else {
                this.fileName = '';
                this.inputFileName = '';
                console.log('inputFileName',this.inputFileName)
            }
        },
		async handleFileChange(event: any) {
			console.log('this.$refs.fileInput.files[0]', this.$refs.fileInput.files[0]);
			let data = this.$refs.fileInput.files[0];
			// this.fileToUpload = data
			this.v$.inputFileName.$touch()
			this.v$.fileToUpload.$touch()
			console.log("data",data)
			if(((!this.v$.fileToUpload.$invalid) && (!this.v$.inputFileName.$invalid))){
			this.importpopUp = !this.importpopUp
			let fileExtension = data.name.split('.').pop();
			if (fileExtension == 'pdf') {
				const fileDataFormat = await this.getConvertDataForFile(data);
				let uuid = uuidv4();
				let payload = { fileData: fileDataFormat, name: `${uuid}.${fileExtension}` };
				let folderName = 'planner';
				try {
					await this.$http
						.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${folderName}/file/upload`, payload)
						// .post(`http://localhost:4030/services/filemanagement/bucket/${folderName}/file/upload`, payload)
						// bucket/:folderName/file/upload
						.then(async (response: any) => {
							if (response.status == 200) {
								console.log(response.data);
								let payload: any = {
									// name: `${uuid}.${fileExtension}`,
									url: response.data,
									filename:this.inputFileName
								};
								await this.updateTemplateTask(2, payload);
								this.inputFileName = ''
                                this.fileName = ''
                                this.v$.$reset();
							} else {
								console.log('response status', response.status);
							}
						});
				} catch (err) {
					console.error(err);
				}
			} else {
				toast.error(`Upload only pdf`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				// this.importpopUp = false
				// this.inputFileName = ''
				// this.fileName = ''
				// this.fileToUpload=null
				this.v$.$reset();
			}
			this.fileName = '';
            this.inputFileName = '';
			this.fileToUpload=null
			}
		},
		async getConvertDataForFile(data: any) {
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		async getAllSubTasksForTemplate() {
			this.tableRow = []
			let mainTaskId:any = this.presentTemplateTask._id
		
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${mainTaskId}/subTasks/get`).then((res: any) => {
                this.allSubTasks = res.data;
				console.log("this.allSubTasks",this.allSubTasks)
				let title:any
				let subtaskTeam: any
				this.allSubTasks.forEach((subTask:any) => {

					let controlObj:any = this.allActiveControls.find((obj: any)=>{return obj._id == subTask.control});

					let teamName:any = this.users.find((team: any)=>{return team._id == controlObj.departments});

					let assignee:any = this.optionsUserArray.find((assigneeName: any)=>{return assigneeName.value == subTask.assignee})
					
					if(subTask.title){
						title=subTask.title
					}else{
						title=controlObj.controlTitle	
					}

					let subTaskObj:any = {
						_id: subTask._id,
						ccid: controlObj.clientUniqueId != undefined ? controlObj.clientUniqueId : 'N/A',
						subTasktitle:title,
						team: teamName != undefined ? teamName.displayName : 'N/A',
						startDate:subTask.startDate,
						dueDate:subTask.dueDate,
						SubTaskStatus:subTask.statusCode,
						description: subTask.description ? subTask.description: controlObj.controlGuidance,
						assignee: assignee,
						notes:subTask.notes,
						mainTaskId:subTask.mainTaskId
					}
					console.log("subTaskObj",subTaskObj)
					this.tableRow.push(subTaskObj)
				});
            });
            
        },
		async updateTemplateTask(type: any, payload: any) {
			payload.createdBy = this.userInfo._id;

			if(type == 2){
				payload.additionalInfo = {evidence:''};
				payload.additionalInfo.evidence = payload.filename;
			} else if(type == 3){
				payload.additionalInfo = {approver:''};
				payload.additionalInfo.approver = payload.approver;
			} else if(type == 4){
				payload.additionalInfo = {comment:''};
				payload.additionalInfo.comment = payload.comment;
			} else if(type == 5){
				payload.additionalInfo = {previousStatus:'',updatedStatus:''};
				payload.additionalInfo.previousStatus = this.presentTemplateTask.statusCode;
				payload.additionalInfo.updatedStatus = payload.statusCode;
			} else if(type == 6){
				payload.additionalInfo = {assignee:''};
				payload.additionalInfo.assignee = payload.assignee;
			} else if(type == 7){
				payload.additionalInfo = {createdBy:''};//the person who have started the task
				payload.additionalInfo.createdBy = payload.createdBy;
			}
			// /api/org/task/${this.$route.params.taskId}/type/${type}/update
			try {
				await this.$http.post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${this.$route.params.taskId}/type/${type}/update`, payload).then(async (result: any) => {
					console.log('updated');
					toast.info(`Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			} catch {
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
                this.clickOnButton = false;
            }
			await this.getPresentTemplateTask();
			await this.getParentTaskHistoryObject();
			await this.getAllSubTasksForTemplate();
			this.workLogObject.taskHistory.sort().reverse();
		},
		colorTemplateText(status: any) {
			if (status == 10411) {
				return ['font-semibold text-xs text-disabledgrey'];
			} else if (status == 10415) {
				return ['font-semibold text-xs text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-xs text-lightgreen'];
			} else if(status == 10402){
				return ['font-semibold text-xs mx-2 text-electricGreen']
			} else if(status == 10401){
				return ['font-semibold text-xs mx-2 text-darkred']
			} else{
				return 'N/A'
			}
		},
		colorTemplateBg(status: any) {
			if (status == 10411) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10402) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10401) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			} 
		},
		getSubtaskObject(subTask: any){
			console.log("subTaskk",subTask)
			let title:any
			let subtaskTeam: any
			let controltitle: any = this.allActiveControls.find((obj: any)=>{return obj._id == subTask.control})
			let teamName: any = this.users.find((team: any)=>{return team.teamOwner == subTask.assignee})
			let assignee: any = this.optionsUserArray.find((assigneeName: any)=>{return assigneeName.value == subTask.assignee})
			if(subTask.team == null || subTask.team == undefined){
				subtaskTeam = this.teamObject.find((teamName: any)=>{return teamName.value == controltitle.departments})
			} else {
				subtaskTeam = this.teamObject.find((teamName: any)=>{return teamName.value == subTask.team})
			}
			
			if(subTask.title){
				title=subTask.title
			}else{
				title=controltitle.controlTitle	
			}
			let subTaskObj:any = {
				_id:subTask._id,
				subTasktitle:title,
				team:subtaskTeam != undefined?(subtaskTeam!= undefined? subtaskTeam:''):'',
				startDate:subTask.startDate,
				dueDate:subTask.dueDate,
				description: subTask.description?subTask.description:controltitle.controlGuidance,
				assignee: assignee,
				notes:subTask.notes != undefined ? subTask.notes : '',
			}
			
			this.subTaskDetailsObj = {...subTaskObj}
		}
	},
	async mounted(){
		emitter.off('addedComments');
		emitter.on('addedComments',(data: any)=>{
			this.comment = data.comments
			this.addComment()
		})
		emitter.off('activiteToolTip');
			emitter.on('activiteToolTip', (data: any) => {
				this.toolTipActive = true;
				this.toolTipText = data.tipText
			});
			emitter.off('deActiviteToolTip');
			emitter.on('deActiviteToolTip', (data: any) => {
				this.toolTipActive = false;
				this.toolTipText = ''
			});
		
	},
	async created(){
		await this.getAllActiveUsers();
		await this.getAllTeams();
		await this.getAllActiveControls();
		await this.getPresentTemplateTask();
		await this.getAllSubTasksForTemplate();
		await this.getParentTaskHistoryObject();
        const savedTabKey = sessionStorage.getItem('selectedTab');
        if (savedTabKey) {
        const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
        if (savedTab) {
            await this.toggleTabs(savedTab);
        }
        else{
            this.selectedTabObj = { displayName: 'Description', id: 1 };
            this.selectedTabIndex = 1;
        }
        }
        else {
            this.selectedTabObj = { displayName: 'Description', id: 1 };
            this.selectedTabIndex = 1;
        }
		this.routesArray = [
            { name: 'Task', routeName: 'tasks' },
			{ name: this.presentTemplateTask.taskId, routeName: 'templateTaskEdit' },
        ];
		emitter.off('clickedOnRowData')
		emitter.on('clickedOnRowData',async (dataObj:any)=>{
				console.log('Row Data dataObj',dataObj)
				console.log("this.allSubTask",this.allSubTasks)
				let selectedSubTaskId:any = dataObj._id;
				let getSelectedObj: any = this.allSubTasks.find((obj: any) => {
					return obj._id === selectedSubTaskId;
				});
				this.getSubtaskObject(getSelectedObj);
				
				if(dataObj.SubTaskStatus == 10411){
					this.showSubTaskPopUp = true
				} else {
					this.$router.push({ name: 'subTaskEditfromtask', params: { taskId: this.presentTemplateTask.id,subtaskId:dataObj._id  },query: { tasks: true } });
				}
				
				// this.goToTemplate(dataObj)
				this.subTaskDetailsObjDummy = {...this.subTaskDetailsObj}
			})
		await this.getParentTaskHistoryObject();
		if(this.presentTemplateTask.comments != undefined){
			this.presentTemplateTask.comments.sort().reverse();
		}	
		this.workLogObject.taskHistory.sort().reverse();
		this.titleLength = this.presentTemplateTask.mainTaskName  ? this.presentTemplateTask.mainTaskName.length:0
        this.titleLengthForTaskName = this.presentTemplateTask.TaskName  ? this.presentTemplateTask.TaskName.length:0
	},
	beforeUnmount() {
         sessionStorage.removeItem('selectedTab')
    },
	
});
</script>
