<template>
  <div>dashboard</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
data(): any {
  return {
    abc:true
  };
},

});
</script>
<style scoped>
</style>