<template>
    <div>
      <div class="styleContent" ref="content"></div>
    </div>
  </template>
   
  <script>
  import { defineComponent } from 'vue';
  import Quill from 'quill';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';
   
  export default defineComponent({
    name: 'SafeHtml',
    data() {
      return {
        quill: null
      };
    },
    props: {
      content: {
        type: String,
        required: true
      }
    },
    mounted() {
      this.initializeQuill();
    },
    watch: {
      content() {
        this.initializeQuill();
      }
    },
    methods: {
      initializeQuill() {
        this.$refs.content.innerHTML = this.content;
        this.quill = new Quill(this.$refs.content, {
          readOnly: true,
          placeholder: 'Compose an epic...',
          modules: {
            toolbar: false
          }
        });
      },
    }
  });
  </script>
   
  <style scoped>
  /* Add any custom styles for your editor here */
  </style>
   