import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import defaultLayout from '../layouts/defaultLayout.vue';
import adminlayout from "@/layouts/adminLayout.vue";
import dashboard from '../views/dashboard.vue';
import process from '../views/process.vue';
import tasks from '../views/tasks.vue';
import archiveTemplates from '../views/archiveTemplates.vue';
import template from '../views/template.vue';
import createTemplate from '@/views/createTemplate.vue';
import templateDetails from '@/views/templateDetails.vue';
import templateTaskEdit from '@/views/templateTaskEdit.vue';
import SubTaskEdit from '@/views/SubTaskEdit.vue';
import viewArchiveTemplateInformation from '@/views/viewArchiveTemplateInformation.vue';
import store from "@/store";

const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'default',
    meta: {
        title: "Dashboard",
        layout: defaultLayout,
    },
    redirect: { name: 'process' }
  },

  {
    path: '/admin',
    name: 'default-admin',
    meta: {
        title: "Dashboard",
        layout: adminlayout,
    },
    redirect: { name: 'process' }
  },
  {
    path: "/org/planner/dashboard",
    name: "dashboard",
    meta: {
        title: "Dashboard",
        layout: defaultLayout,
    },
    component: dashboard,
  },
  {
    path: "/org/planner/process",
    name: "process",
    meta: {
        title: "Process",
        layout: defaultLayout,
    },
    component: process,
  },
  {
    path: "/planner/tasks",
    name: "tasks",
    meta: {
        title: "tasks",
        layout: defaultLayout,
    },
    component: tasks,
  },
  {
    path: "/planner/archiveTemplates",
    name: "archiveTemplates",
    meta: {
        title: "archiveTemplates",
        layout: defaultLayout,
    },
    component: archiveTemplates,
  },
  {
    path: "/planner/archiveTemplates/:templateId",
    name: "viewArchiveTemplateInformation",
    meta: {
        title: "viewArchiveTemplateInformation",
        layout: defaultLayout,
    },
    component: viewArchiveTemplateInformation,
  },
  {
    path: "/org/process/:processId/templates",
    name: "template",
    meta: {
        title: "template",
        layout: defaultLayout,
    },
    component: template,
  },
  {
    path: "/org/process/:processId/createTemplate",
    name: "createTemplate",
    meta: {
        title: "createTemplate",
        layout: defaultLayout,
    },
    component: createTemplate,
  },
  {
    path: "/org/process/:processId/edit/:templateId/editTemplate",
    name: "editTemplate",
    meta: {
        title: "editTemplate",
        layout: defaultLayout,
    },
    component: createTemplate,
  },
  {
    path: "/org/process/:processId/template/:templateId/templateDetails",
    name: "templateDetails",
    meta: {
        title: "templateDetails",
        layout: defaultLayout,
    },
    component: templateDetails,
  },
  {
    path: "/org/process/:taskId/task",
    name: "templateTaskEdit",
    meta: {
        title: "templateTaskEdit",
        layout: defaultLayout,
    },
    component: templateTaskEdit,
  },
  {
    path: "/org/process/:taskId/task/:subtaskId/subTask",
    name: "subTaskEditfromtask",
    meta: {
        title: "subTaskEditfromtask",
        layout: defaultLayout,
    },
    component: SubTaskEdit,
  },
  {
    path: "/org/process/:subtaskId/subTask",
    name: "subTaskEdit",
    meta: {
        title: "subTaskEdit",
        layout: defaultLayout,
    },
    component: SubTaskEdit,
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});
router.beforeEach(async (to, from, next) => {
  store.state.isLoading = true;
  if(to.meta.title){
      document.title=`Planner - ${to.meta.title}`
  }
  else{
      document.title=`Planner`
  }
  console.log('Router check triggered', to)
  if ( to.path == '/admin' ||  to.path == '/' || to.path=='/dashboard' || to.path=='/org/planner/process') {
      console.log('Nav to default route with', to.query.session)
      if (to.query.session) {
          localStorage.setItem('AUTH_ID', to.query.session.toString());
          await store.dispatch('getUserInfo').then((res) => {
              console.log('response', res.data[0]);
              if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_PLANNER_WEBAPP_URL}`;
              else {
                  next();
              }
          })
      }
      await normalAuthenticationCheck(next);
  } else {
      await normalAuthenticationCheck(next);
  }
  // next();
});

router.afterEach(async (to, from, next) => {
  store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
  console.log('Inside normal check')
  if(!localStorage.getItem('AUTH_ID')) {
      console.log('AUTH ID NOT found')
      window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_PLANNER_WEBAPP_URL}`;
  } else{
      const userInfo = store.getters.userInfo;
      console.log('USER INFO', userInfo)
      if(!userInfo.roles) {
          await store.dispatch('getUserInfo').then((res) => {
              next();
          });
      }
      else{
          next();
      }
  }
}


export default router
