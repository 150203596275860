
<template>
    <div class="px-5 pt-3 pb-2 mb-5">
        
        <div>
            <div class="mb-2">
                <breadCrumbs :routesArray="routesArray"></breadCrumbs>
            </div>
            <div class="items-center justify-between flex">
                <div class="title flex items-center w-2/4">
                    <h1 :class="titleLength > 40? 'table-ellipsis':''">{{presentTemplateObject.title}}</h1>
                </div>
                <div class="flex">
                    <button v-if="(((presentTemplateObject.controls != null||presentTemplateObject.controls!=undefined) && presentTemplateObject.controls.length!=0))" class="btnprimary mr-1" @click="startTaskPopup()">Start</button>
                    <button class="btnprimary mr-1" @click="editTemplate()">Edit</button>
                    <button class="btnprimary" @click="onClickArchiveBtn()">Archive</button>
                    <!-- :class="sortedData.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" -->
                    <button class="ml-1 flex" :class="controlsArray.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
                        Filters
                        <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                    </button>

                </div>
            </div>
            <div class="mt-3">
                <p class="text-xs">
                    This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
                    to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
                </p>
            </div>
            <div>
                <tableData :tableRowData="controlsArray" :headersData="headers" :loadData="dataLoading"></tableData>
                 <div v-if="toolTipActive">
          		    <tool-tip :tipText="toolTipText"></tool-tip>
        	    </div>
            </div>
            <popup v-if="createTaskPopup" :popUp="createTaskPopup">
                <template v-slot:header>
                    <div class="flex items-center justify-between popupheadingcontainer rounded">
                        <h1 class="w-1/2" :class="titleLength > 40? 'table-ellipsis':''">{{presentTemplateObject.title}}</h1>

                        <button class="float-right text-3xl" @click="onCloseAddPopup()">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>

                <div class="popup-business">
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="upperspacing">
                                        <label for="number" class="controllabel">Title</label><br />
                                        <input class="inputboxstyling mt-2" placeholder="Task Name"  v-model.trim="templateTaskObj.mainTaskName" :class="{'is-invalid': v$.templateTaskObj.mainTaskName.$error }"/>
                                        <div v-if="v$.templateTaskObj.mainTaskName.$error " class="text-red-500">
                                            <div class="error-text" v-if="v$.templateTaskObj.mainTaskName.required.$invalid ">Required</div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row upperspacing w-full gap-4">
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Start Date</label>
                                            <input
                                                type="date"
                                                class="mt-2"
                                                @focus="openCalendar"
                                                :min="today"
                                                onkeydown = "return false"
                                                @change="checkForStartDate"
                                                placeholder="Select Start Date"
                                                v-model="templateTaskObj.startDate"
                                                :class="{'is-invalid': v$.templateTaskObj.startDate.$error,'inputboxstylingForSelectAtPopup': templateTaskObj.startDate === '','stylingForInputboxstylingForSelectAtPopup': templateTaskObj.startDate !== '' }"
                                            />
                                            <div v-if="v$.templateTaskObj.startDate.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.startDate.required.$invalid ">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Due Date</label>
                                            <input
                                                type="date"
                                                class="mt-2"
                                                @focus="openCalendar"
                                                onkeydown = "return false"
                                                :min="templateTaskObj.startDate||today"
                                                @change="checkForStartDate"
                                                placeholder="Select Start Date"
                                                v-model="templateTaskObj.dueDate"
                                                :class="{'is-invalid': v$.templateTaskObj.dueDate.$error,'inputboxstylingForSelectAtPopup': templateTaskObj.dueDate === '','stylingForInputboxstylingForSelectAtPopup': templateTaskObj.dueDate !== '' }"
                                            />
                                            <div v-if="v$.templateTaskObj.dueDate.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.dueDate.required.$invalid ">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <!-- <div class="w-1/2">
                                            <label class="controllabel text-xs">Control Number</label>
                                            <input class="inputboxstyling" placeholder="Control Number" v-model="templateTaskObj.controlNumber" :class="{'is-invalid': v$.templateTaskObj.controlNumber.$error }"/>
                                            <div v-if="v$.templateTaskObj.controlNumber.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.controlNumber.required.$invalid ">Required</div>
                                            </div>
                                        </div> -->
                                        <div class="upperspacing">
                                            <label class="controllabel text-xs">Assigned To</label>
                                            <div class="mt-2">
                                            <multiselect
                                                v-model="templateTaskObj.assignee"
                                                :options="userOptions"
                                                :showNoOptions="false"
                                                @search-change="asyncFind"
                                                :limit-text="limitText"
                                                :multiple="false"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Assigned to"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid': v$.templateTaskObj.assignee.$error,
                                                    'has-options': userOptions && userOptions.length > 0,
                                                    'no-options': !userOptions.length &&  searchValue !== ''
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span>No Members Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.templateTaskObj.assignee.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.assignee.required.$invalid ">Required</div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upperspacing">
                                        <label class="controllabel text-xs">Description</label><br />
                                        <textarea name="Description" placeholder="Description" id="" class="inputboxstyling mt-2" v-model.trim="templateTaskObj.description" :class="{'is-invalid': v$.templateTaskObj.description.$error }"></textarea>
                                        <div v-if="v$.templateTaskObj.description.$error " class="text-red-500">
                                            <div class="error-text" v-if="v$.templateTaskObj.description.required.$invalid ">Required</div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                                    <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(templateTaskObjDummy) == JSON.stringify(templateTaskObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Reset</button>
                                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(templateTaskObjDummy) == JSON.stringify(templateTaskObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="clickOnSubmitToCreateTask()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </popup>
        </div>
	</div>
</template>

<script lang="ts">
import { emitter,toast } from '@/main';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import breadCrumbs from '@/components/breadCrumbs.vue';
import tableData from '@/components/tableData.vue';
import popup from '@/components/popup.vue';
// import { template } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import toolTip from '@/components/toolTip.vue'
  
export default defineComponent({
  data(): any {
    return {
        v$: useVuelidate(),
        headers:['ccid','title','description','framework_controlNo'],
        routesArray:[],
        controlsArray:[],
        createTaskPopup: false,
        presentTemplateObject:[],
        userOptions:[],
        optionUserArray:[],
        templateTaskObj:{
            mainTaskName:'',
            startDate:'',
            dueDate:'',
            controlNumber:'',
            assignee:'',
            description:''
        },
        templateTaskObjDummy:{},
        addedControlArray:[],
        keysOfActiveControls:[],
        allProcess:[],
        presentProcessObj:{},
        disableStart:true,
        titleLength:0,
        toolTipActive:false,
        toolTipText:'',
        clickOnButton: false,
        dataLoading:true,
        searchValue:''
        };
    },
    components:{
        breadCrumbs,
        popup,
        tableData,
        Multiselect,
        toolTip
    },
    computed: {
		...mapGetters({ userInfo: 'userInfo' }),
        today() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); 
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
    },
    methods:{
        addTemplate(){
            this.$router.push({name:'createTemplate'});
        },
        checkWhetherTasksCreated(presentTemplateObject:any){
            if(((((presentTemplateObject.controls != null||presentTemplateObject.controls!=undefined) && presentTemplateObject.controls.length!=0)) && !(this.checkTasks(presentTemplateObject)))){
                return true
            } else {
                return false
            }
        },
        checkTasks(obj:any){
            console.log("Object.keys(this.presentTemplateObject).includes('tasks')",Object.keys(obj))
            if(Object.keys(obj).includes('tasks')){
                return true
            }else{
                return false
            }
        },
        filterOpen() {
			this.showFilters = !this.showFilters;
			// if (this.showFilters == true) {
            //     this.dummyList = this.savedAuditSets;
            // } else {
            //     this.savedAuditSets = this.dummyList;
            // }
			emitter.emit('visible', this.showFilters);
		},
        openCalendar(event: any) {
            event.target.showPicker();
        },
        checkForStartDate(){
			if(this.templateTaskObj.dueDate != ''){
				if(this.templateTaskObj.startDate > this.templateTaskObj.dueDate){
					this.templateTaskObj.dueDate = ''
				}
			}
		},
        startTaskPopup(){
            this.createTaskPopup = true
        },
        onCloseAddPopup(){
            this.createTaskPopup = false
            this.v$.$reset();
            this.templateTaskObj.mainTaskName = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.dueDate = '',
            this.templateTaskObj.controlNumber = '',
            this.templateTaskObj.assignee = '',
            this.templateTaskObj.description = ''
        },
        onCancelbtn(){
            this.v$.$reset();
            this.templateTaskObj.mainTaskName = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.dueDate = '',
            this.templateTaskObj.controlNumber = '',
            this.templateTaskObj.assignee = '',
            this.templateTaskObj.description = ''
        },
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
            this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        editTemplate(){
            this.$router.push({name:'editTemplate',params:{id:1}});
        },
        clickOnSubmitToCreateTask(){
            this.v$.$touch();
            // this.v$.causeActionObj.$invalid
            console.log("this.templateTaskObj",this.templateTaskObj)
            if(!this.v$.templateTaskObj.$invalid){
                this.clickOnButton = true;
                let copyOftemplateTaskObj: any = {...this.templateTaskObj}
                console.log("this.templateTaskObj222222222",this.templateTaskObj)
                this.createTaskForTemplate(copyOftemplateTaskObj)
                this.createTaskPopup = false
                this.v$.$reset();
                this.templateTaskObj.mainTaskName = '',
                this.templateTaskObj.startDate = '',
                this.templateTaskObj.startDate = '',
                this.templateTaskObj.dueDate = '',
                this.templateTaskObj.controlNumber = '',
                this.templateTaskObj.assignee = '',
                this.templateTaskObj.description = ''
            }
            // this.createTaskForTemplate()
        },
        async createTaskForTemplate(obj: any){
            console.log("this.templateTaskObj",this.templateTaskObj)
            let templateId:any = this.$route.params.templateId
            
            const payload: any = {
				mainTaskName: obj.mainTaskName,
                startDate: obj.startDate,
                dueDate:obj.dueDate,
                assignee:obj.assignee.value,
                description:obj.description,
                linkedTemplateId:templateId,
                createdBy:this.userInfo._id,
            };
            console.log("Task payload",payload)
            // toast.info('Task Created', {
            //             timeout: 1000,
            //             closeOnClick: true,
            //             closeButton: 'button',
            //             icon: true
            //         });
            let workflowId: any = "66c2f0beb4f223dca569f907"
            try{
                await this.$http
                    .post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/planner/template/task`,[payload])
                    .then((result: { data: any }) => {
                        console.log("Update data",result.data)
                        toast.info('Task Created', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    })
                    .catch((error: any) => {
                        toast.error(` ${error}`, {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        this.onCloseAddPopup()
                });
            } catch (err){
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false;
            }
            

        },
        async onClickArchiveBtn(){
            await this.ArchiveTemplate();
            this.$router.push({ name: 'archiveTemplates' });
        },
        async ArchiveTemplate(){
            let templateId:any = this.$route.params.templateId
            // /api/org/template/:templateId/archive
            // POST

            // {updatedAt:'knedle12124n',updatedBy:'elkdcbe98378yu'}
            const payload: any = {
				updatedBy:this.userInfo._id
            };
            console.log("payload",payload)
            await this.$http
				.post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/template/${templateId}/archive`,payload)
				.then((result: { data: any }) => {
                    console.log("Update data",result.data)
                    toast.info('Archived a Template', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                    
				})
				.catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
			});
            
        },
        async getActiveTemplates(typeId: any){
            // /api/org/type/:typeId/templates/getAll

            // GET

            // type 1 for all active templates

            // type 2 for all archive templates
            await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/type/${typeId}/templates/getAll`).then((result: any) => {
				console.log('active templates data', result.data);
                
			});

        },
        async getOneTemplateDetails() {
            let templateId:any = this.$route.params.templateId
            console.log("this.$route.prams.templateId,",this.$route.params.templateId)
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/template/${templateId}/get`).then((result: any) => {
				console.log('result.data', result.data);
                this.presentTemplateObject = result.data
			});
            let templateObj:any
            let finaltemplateObj:any
            let framework_controlNo :any
            this.presentTemplateObject.controls.forEach((element:any) => {
                templateObj=this.allActiveControls.filter((obj: any)=>{return obj._id === element})
                console.log("templateObj",templateObj)
                templateObj.forEach((obj: any) => {
                    console.log("Object.keys(templateObj).includes('frameworks')",Object.keys(templateObj))
                    
                    if(Object.keys(obj).includes('frameworks')){
                        framework_controlNo = obj.frameworks.map((framework: any) => {
                                return {
                                    framework: framework.framework,
                                    controls: framework.controls
                                };
                            });
                            console.log("framework_controlNo",framework_controlNo)
                            console.log("framework_controlNo",framework_controlNo)
                    }else{
                        // framework_controlNo =  {
                        //         control:{label:obj.controlNumber, value:obj._id},
                        //         frameworkName:'Internal Controls'
                        //     }
                        //     console.log("framework_controlNo",framework_controlNo)
                        
                    }
                    console.log("jfd;lg",obj)
                    finaltemplateObj= {
                        ccid:obj.clientUniqueId?obj.clientUniqueId:'N/A',
                        title:obj.controlTitle? obj.controlTitle:'N/A',
                        description: obj.controlGuidance? obj.controlGuidance : 'N/A',
                        framework_controlNo:framework_controlNo? framework_controlNo : 'N/A'
                    }
                    console.log("objjkgfdhjg",finaltemplateObj)
                    this.controlsArray.push(finaltemplateObj)
                });
                
                
                console.log("fdhkjlhkl",element)
            });
            console.log("editOBBJJJJJJJJ",templateObj)
            console.log("this.allActiveControls",this.allActiveControls)
			
		},
        async getAllActiveControls(){
            await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`)
				.then((result: { data: any }) => {
                    console.log("Active controls data",result.data)
                    this.allActiveControls = result.data;
				})
				.catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
				});
                
        },
        async getAllBusinessProcesses() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                console.log("result of all business Process",result.data)
				this.allProcess = result.data
            });
            let processId:any = this.$route.params.processId
            let obj:any = this.allProcess.find((process:any)=>{
                return process._id==processId
            })
            this.presentProcessObj=obj
        },
        async getAllActiveUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
				console.log('res.data', res.data);
				this.orgUsersList = res.data.filter((user: any) => {
					return user.isActive == true;
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
					this.optionUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
				}
			});
			console.log('this.orgUsersList', this.orgUsersList);
		},
    },
    validations(){
        return {
            templateTaskObj:{
                mainTaskName:{required},
                startDate:{required},
                dueDate:{required},
                assignee:{required},
                description:{required}
            },
        }
//         [
//   {
//     mainTaskName: 'asdfvf',
//     startDate:'',
//     dueData:'',
//     assignee:'',
//     description:'',
//     linkedTemplateId:'dkncdicdiubciuejk',
//     createdAt:'',
//     createdBy:'',
//   }
// ]

    },
    async  created() {
        this.dataLoading = true;

        await this.getAllActiveUsers();
        await this.getAllActiveControls();
        await this.getOneTemplateDetails();
        await this.getActiveTemplates(1);
        await this.getAllBusinessProcesses();


        if(this.presentTemplateObject.title){
            this.titleLength = this.presentTemplateObject.title.length
        }

        this.templateTaskObjDummy = {...this.templateTaskObj}

        this.dataLoading = false;

        this.routesArray = [
            { name: 'Process', routeName: 'process' },
			{ name: this.presentProcessObj.businessProcessName, routeName: 'template' },
            { name: 'Template', routeName: 'templateDetails' }
        ];
        


        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
  
  });



</script>

<style scoped>
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>