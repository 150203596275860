<template>
    <div class="px-5 pt-3 pb-2 mb-5">
		<div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>Process</h1>
			</div>
			<div class="flex items-center">
				<button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3">
			<p class="text-xs">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
		<div>
			<tableData :tableRowData="tableRow" :headersData="headers" :loadData="dataLoading"></tableData>
			<div v-if="toolTipActive">
          		<tool-tip :tipText="toolTipText"></tool-tip>
        	</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import tableData from '@/components/tableData.vue';
import { emitter,toast } from '@/main';
import toolTip from '@/components/toolTip.vue';
// import { identity } from 'lodash';

export default defineComponent({
	data(): any {
		return {
			headers: ['businessProcessName','name','department','entity','Templates'],
			tableRow:[],
			allProcess:[],
			orgUsersList:[],
			departments:[],
			optionUserArray:[],
			teamObject : [],
			teams:[],
			locationObject:[],
			length:0,
			templateData:[],
			pageStatus: false,
			toolTipActive:false,
			toolTipText:'',
			dataLoading:true
		};
	},
	components:{
		tableData,
		toolTip
	},
	methods:{
		filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.savedAuditSets;
            } else {
                this.savedAuditSets = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
		goToTemplate(id: any){
			console.log("objiiiiii",id)
			// let id : any = obj._id
			// console.log("iddd",id)
        	this.$router.push({ name: 'template' ,params:{processId : id}});
    	},
		// getTeamName(ownerId: any) {
        //     let user = this.teamObject.find((obj: any) => {
        //         return obj.value == ownerId;
        //     });
		// 	console.log("user",user)
        //     if (user == undefined || user == null) {
        //         let empty = '';
        //         return empty;
        //     } else {
        //         return user.label;
        //     }
        // },
		getTeamName(departmentId: any) {
            let team = this.teamObject.find((obj: any) => {
                return obj.value == departmentId;
            });
			console.log("team",team)
            if (team == undefined || team == null) {
                let empty = 'N/A';
                return empty;
            } else {
                return team.label;
            }
        },
		getPersonName(person: any) {
			let personObj = this.orgUsersList.find((user: any) => {
              
				return user._id == person;
			});
			console.log("personObj",personObj)
			if (personObj == undefined) {
				return 'N/A';
			} else {
				return personObj.name;
			}
		},
		getLocation(teamId:any){
			let presentteamObject:any = this.teams.find((obj: any) => {
				return obj._id == teamId;
			});
			
			if (presentteamObject!=undefined && presentteamObject.type == 20301){
				return 'Global';
			}else if(presentteamObject == undefined || presentteamObject == null){
				return 'N/A'
			}
			else{
				return presentteamObject.entityName
				
			}
		},
		async getallEntities() {
			let result:any
			try{
				await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
					this.locationObject = res.data;
				});
			}catch (err) {
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
            
        },
		async getallTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.teams = res.data;
            });
            for (var i = 0; i < this.teams.length; i++) {
                this.teamObject.push({ label: this.teams[i].teamName, value: this.teams[i]._id });
            }
			// console.log("this.teamObject",this.teamObject)
        },
		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				// console.log('res.data', res.data);
				this.orgUsersList = res.data.filter((user: any) => {
					return user.isActive == true;
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
					this.optionUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
				}
			});
			// console.log('this.orgUsersList', this.orgUsersList);
		},
		async getActiveTemplates(typeId: any){
            // /api/org/type/:typeId/templates/getAll

            // GET

            // type 1 for all active templates

            // type 2 for all archive templates
            await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/type/${typeId}/templates/getAll`).then((result: any) => {
				// console.log('result.data', result.data);
                this.templateData = result.data
				// this.length = templateData.length
				let length = this.templateData.filter((data:any)=>{return data.isActive==true})
				this.templateData = length
				// console.log("this.templateData",this.templateData)
			});


        },
		async getAllBusinessProcesses() {
			try{
				await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
					
					if(result.data == 200){
						this.pageStatus = true
					}
					this.allProcess = result.data
				});
				
				this.allProcess.forEach((element: any) => {
					
					let owner: any = this.getPersonName(element.name)
					let team:any = this.getTeamName(element.department);
					let entity:any = this.getLocation(element.department);
					
					let templateLength: any
					let length:any

					if(Object.keys(element).includes('tempalates')){
						templateLength = element.tempalates
						length = this.templateData.filter((check: any)=>{
							return templateLength.includes(check._id)
						})
						console.log("lengthlength",length)
					}
					else{
						templateLength = 0
					}
					let processObj:any={
						businessProcessName:element.businessProcessName != undefined ? element.businessProcessName : 'N/A',
						name:(owner!=undefined || owner!=null)?owner:'N/A',
						department:(team!=undefined ||team!=null)?team:'N/A',
						entity:(entity!=undefined || entity!=null)?entity:'N/A',
						Templates:(element.tempalates != undefined ||element.tempalates!=null )?length.length:'0',
						id: element._id
					}
					
					this.tableRow.push(processObj)
			});
			}catch(err){
				// toast.error(`Error`, {
				// 	timeout: 1000,
				// 	closeOnClick: true,
				// 	closeButton: 'button',
				// 	icon: true
				// });
			}
            
			
        },
	},
	async created() {
		this.dataLoading = true;

		await this.getAllUsers();
		await this.getallTeams();
		await this.getActiveTemplates(1);
		await this.getallEntities();
		await this.getAllBusinessProcesses();

		this.dataLoading = false;

		emitter.off('clickedOnRowData')
		emitter.on('clickedOnRowData',async (dataObj:any)=>{
			console.log('Row Data dataObj',dataObj)
			this.goToTemplate(dataObj.id)
		});

		emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});		
	}
	
});
</script>
<style scoped>
</style>