<template>
    <div class="px-5 pt-3 pb-2 mb-5">
        
        <div>
            <div class="mb-2">
                <breadCrumbs :routesArray="routesArray"></breadCrumbs>
            </div>
            <div class="items-center justify-between flex">
                <div class="title flex items-center">
                    <h1>Archive Templates</h1>
                </div>
                
            </div>
            <div class="mt-3">
                <p class="text-xs">
                    This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
                    to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
                </p>
            </div>
            <div class="mt-5">
                <div v-if="getArchiveTemplateObj.length==0" class="cardheight flex items-center justify-center card h-auto boxShadow bg-white py-2.5 cursor-pointer">
                    <div class="flex items-center justify-center flex-col">
                        <!-- <div class="flex justify-center mb-1">
                            <img src="@/assets/createTemplate.svg" alt="createTemplate">                    
                        </div> -->
                        <div class="text-sm font-semibold flex justify-center mt-2">No Archive Templates</div>
                    </div>
                </div>
                <div v-else class="mt-2 flex flex-wrap gap-3">
                    <div v-for="(obj,index) in getArchiveTemplateObj" :key="index" class="flex flex-row justify-between cardheight card bg-white boxShadow p-3 cursor-pointer" @click="goToTemplateDetails(obj._id)">
                        <div  class="flex flex-col justify-between">
                            <div>
                                <div class="text-sm font-semibold text-wrap h-10 mb-1.5" :class="obj.title.length > 40? 'table-ellipsis':''">{{obj.title}}</div>
                            </div>
                                <div class="flex flex-row justify-between mb-1.5">
                                    <div class="items-baseline gap-2 flex flex-row">
                                        <div class="text-xs pt-2.5">Activities :</div>
                                        <div class="text-xs font-semibold">{{obj.controls.length}}</div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	data(): any {
		return {
			getArchiveTemplateObj:[]
		};
	},
	methods:{
		async getAllArchiveTemplates(typeId: any){
			// /api/org/type/:typeId/templates/getAll
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/type/${typeId}/templates/getAll`).then((result: any) => {
				console.log('result.data', result.data);
                this.getArchiveTemplateObj = result.data
			});
		},
        goToTemplateDetails(id :any){
            console.log("iddddd",id)
            this.$router.push({name:'viewArchiveTemplateInformation',params:{templateId:id}});
        },
	},
	async created(){
		await this.getAllArchiveTemplates(2)
	}
});
</script>
<style scoped>
.cardheight{
    height: 100px;
    width: 24%;
  }
  
</style>