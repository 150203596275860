<template>
    <div class="px-5 pt-3 pb-2 mb-5">
        <div v-if="dotsLoading && pageStatus" class="mt-2">
                <div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
                    <dotsloader />
                </div>
            </div>
        
        <div v-else>
            <div class="mb-2">
                <breadCrumbs :routesArray="routesArray"></breadCrumbs>
            </div>
            <div class="items-center justify-between flex">
                <div class="title flex items-center">
                    <h1>{{presentProcessObj.businessProcessName}}</h1>
                </div>
            </div>
            <div class="mt-3">
                <p class="text-xs">
                    This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
                    to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
                </p>
            </div>
            
            <div class="mt-5 flex flex-wrap gap-3">
                <!-- shadow-md -->
                <div class="cardheight flex items-center justify-center card h-auto boxShadow bg-white py-2.5 cursor-pointer" @click="addTemplate()">
                    <div class="flex items-center justify-center flex-col">
                        <div class="flex justify-center mb-1">
                            <img src="@/assets/createTemplate.svg" alt="createTemplate">                    
                        </div>
                        <div class="text-sm font-semibold flex justify-center mt-2">New Template</div>
                    </div>
                </div>
                <div v-for="(obj,index) in addedTemplateObject" :key="index" class="cardheight card bg-white boxShadow p-3 cursor-pointer" @click="goToTemplateDetails(obj._id)">
                    <div class="flex flex-col">
                        <div>
                            <div class="text-sm font-semibold h-10 mb-1.5" :class="obj.title.length > 40 ? 'table-ellipsis' : ''">
                                {{ obj.title }}
                            </div>
                        </div>
                        <div class="flex justify-between mt-1.5">
                            <div class="gap-2 flex justify-center mt-1">
                                <div class="text-xs gap-2 flex">Activities <div>:</div></div>
                                <div class="text-xs font-semibold">{{ obj.controls.length }}</div>
                            </div>
                            <div class="image-container" @click.stop="startTaskPopup(obj)">
                                <img src="@/assets/Start.svg" alt="createTemplate" class="default-img"/>
                                <img src="@/assets/StartHover.svg" alt="createTemplate" class="hover-img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <popup v-if="createTaskPopup" :popUp="createTaskPopup">
                <template v-slot:header>
                    <div class="flex items-center justify-between popupheadingcontainer rounded">
                        <h1 class="w-1/2" :class="titleLength > 40? 'table-ellipsis1':''">{{templateTitle}}</h1>

                        <button class="float-right text-3xl" @click="onCloseAddPopup()">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>

                <div class="popup-business">
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="upperspacing">
                                        <label for="number" class="controllabel">Title</label><br />
                                        <input class="inputboxstyling mt-2" placeholder="Task Name"  v-model.trim="templateTaskObj.mainTaskName" :class="{'is-invalid': v$.templateTaskObj.mainTaskName.$error }"/>
                                        <div v-if="v$.templateTaskObj.mainTaskName.$error " class="text-red-500">
                                            <div class="error-text" v-if="v$.templateTaskObj.mainTaskName.required.$invalid ">Required</div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row upperspacing w-full gap-4">
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Start Date</label>
                                            <input
                                                type="date"
                                                class="inputboxstyling mt-2"
                                                @focus="openCalendar"
                                                :min="today"
                                                onkeydown = "return false"
                                                @change="checkForStartDate"
                                                placeholder="Select Start Date"
                                                v-model="templateTaskObj.startDate"
                                                :class="{'is-invalid': v$.templateTaskObj.startDate.$error }"
                                            />
                                            <div v-if="v$.templateTaskObj.startDate.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.startDate.required.$invalid ">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Due Date</label>
                                            <input
                                                type="date"
                                                class="inputboxstyling mt-2"
                                                @focus="openCalendar"
                                                onkeydown = "return false"
                                                :min="templateTaskObj.startDate||today"
                                                @change="checkForStartDate"
                                                placeholder="Select Start Date"
                                                v-model="templateTaskObj.dueDate"
                                                :class="{'is-invalid': v$.templateTaskObj.dueDate.$error }"
                                            />
                                            <div v-if="v$.templateTaskObj.dueDate.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.dueDate.required.$invalid ">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <!-- <div class="w-1/2">
                                            <label class="controllabel text-xs">Control Number</label>
                                            <input class="inputboxstyling" placeholder="Control Number" v-model="templateTaskObj.controlNumber" />
                                            <div v-if="v$.templateTaskObj.controlNumber.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.controlNumber.required.$invalid ">Required</div>
                                            </div>
                                        </div> -->
                                        <div class="upperspacing">
                                            <label class="controllabel text-xs">Assigned To</label>
                                            <div class="mt-2">
                                            <multiselect
                                                v-model="templateTaskObj.assignee"
                                                :options="userOptions"
                                                :showNoOptions="false"
                                                @search-change="asyncFind"
                                                :limit-text="limitText"
                                                :multiple="false"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Assigned to"
                                                class="custom-multiselect userSelect"
                                                :class="{ 
                                                    'is-invalid': v$.templateTaskObj.assignee.$error,
                                                    'has-options': userOptions && userOptions.length > 0,
                                                    'no-options': !userOptions.length &&  searchValue !== '' 
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span>No Members Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.templateTaskObj.assignee.$error " class="text-red-500">
                                                <div class="error-text" v-if="v$.templateTaskObj.assignee.required.$invalid ">Required</div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="upperspacing">
                                        <label class="controllabel text-xs">Description</label><br />
                                        <textarea name="Description" placeholder="Description" id="" class="inputboxstyling mt-2" v-model.trim="templateTaskObj.description" :class="{'is-invalid': v$.templateTaskObj.description.$error }"></textarea>
                                        <div v-if="v$.templateTaskObj.description.$error " class="text-red-500">
                                            <div class="error-text" v-if="v$.templateTaskObj.description.required.$invalid ">Required</div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                                    <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(templateTaskObjDummy) == JSON.stringify(templateTaskObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Reset</button>
                                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(templateTaskObjDummy) == JSON.stringify(templateTaskObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="clickOnSubmitToCreateTask()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </popup>
        
	</div>
</template>


<script lang="ts">
import { emitter,toast } from '@/main';
import { defineComponent } from 'vue';
import Multiselect from 'vue-multiselect';
import breadCrumbs from '@/components/breadCrumbs.vue';
import popup from '@/components/popup.vue';
// import { template } from 'lodash';
import { mapGetters } from 'vuex';
import dotsloader from '@/components/dotsloader.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
  
  export default defineComponent({
  data(): any {
    return {
        v$: useVuelidate(),
        templateTaskObj:{
            mainTaskName:'',
            startDate:'',
            dueDate:'',
            controlNumber:'',
            assignee:'',
            description:''
        },
        templateTitle:'',
        titleLength:0,
        templateTaskObjDummy:{},
        routesArray:[],
        addedTemplateObject:[],
        userOptions:[],
        createTaskPopup: false,
        hover:false,
        optionUserArray:[],
        allProcess:[],
        presentProcessObj:{},
        templateId:0,
        pageStatus: false,
        clickOnButton: false,
        searchValue:''
        };
    },
    components:{
        breadCrumbs,
        popup,
        Multiselect,
        dotsloader
    },
    computed: {
		...mapGetters({ userInfo: 'userInfo', dotsLoading: 'dotsLoading' }),
        today() {
			const today = new Date();
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, '0'); 
			const day = String(today.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		}
    },
    validations(){
        return {
            templateTaskObj:{
                mainTaskName:{required},
                startDate:{required},
                dueDate:{required},
                assignee:{required},
                description:{required}
            },
        }
    },
    methods:{
        addTemplate(){
            this.$router.push({name:'createTemplate'});
        },
        goToTemplateDetails(id :any){
            console.log("iddddd",id)
            this.$router.push({name:'templateDetails',params:{templateId:id}});
        },
        startTaskPopup(obj:any){
            console.log("objjjj",obj)
            this.templateId=obj._id
            if(obj.title){
                this.templateTitle = obj.title
                this.titleLength = this.templateTitle.length
            } else {
                this.templateTitle = 'N/A'
            }
            
            if(obj.controls.length != 0){
                this.createTaskPopup = true
            } else{
                toast.error('Cannot create task', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            
        },
        openCalendar(event: any) {
            event.target.showPicker();
        },
        checkForStartDate(){
			if(this.templateTaskObj.dueDate != ''){
				if(this.templateTaskObj.startDate > this.templateTaskObj.dueDate){
					this.templateTaskObj.dueDate = ''
				}
			}
		},
        onCloseAddPopup(){
            this.createTaskPopup = false
            this.v$.$reset();
            this.templateTaskObj.mainTaskName = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.dueDate = '',
            this.templateTaskObj.controlNumber = '',
            this.templateTaskObj.assignee = '',
            this.templateTaskObj.description = ''
        },
        onCancelbtn(){
            this.v$.$reset();
            this.templateTaskObj.mainTaskName = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.startDate = '',
            this.templateTaskObj.dueDate = '',
            this.templateTaskObj.controlNumber = '',
            this.templateTaskObj.assignee = '',
            this.templateTaskObj.description = ''
        },
        clickOnSubmitToCreateTask(){
            this.v$.$touch();
            // this.v$.causeActionObj.$invalid
            console.log("this.templateTaskObj",this.templateTaskObj)
            if(!this.v$.templateTaskObj.$invalid){
                this.clickOnButton = true;
                let copyOftemplateTaskObj: any = {...this.templateTaskObj}
                console.log("this.templateTaskObj222222222",this.templateTaskObj)
                this.createTaskForTemplate(copyOftemplateTaskObj)
                this.createTaskPopup = false
                this.v$.$reset();
                this.templateTaskObj.mainTaskName = '',
                this.templateTaskObj.startDate = '',
                this.templateTaskObj.startDate = '',
                this.templateTaskObj.dueDate = '',
                this.templateTaskObj.controlNumber = '',
                this.templateTaskObj.assignee = '',
                this.templateTaskObj.description = ''
            }
            // this.createTaskForTemplate()
        },
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
            this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        async getAllBusinessProcesses() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                console.log("result of all business Process",result.data)
				this.allProcess = result.data
            });
            let processId:any = this.$route.params.processId
            let obj:any = this.allProcess.find((process:any)=>{
                return process._id==processId
            })
            this.presentProcessObj=obj
        },
        async createTaskForTemplate(obj: any){
            this.v$.$touch();
            console.log("this.templateTaskObj",this.templateTaskObj)
            let templateId:any = this.templateId
            console.log("this.templateId",this.templateId)
            //here task api
            // /api/org/trigger/workflow/:workflowId/planner/template/task

            // WORKFLOW API 3040

            // POST

            // [
            //   {
            //     mainTaskName: 'asdfvf',
            //     startDate:'',
            //     dueData:'',
            //     assignee:'',
            //     description:'',
            //     linkedTemplateId:'dkncdicdiubciuejk',
            //     createdAt:'',
            //     createdBy:'',
            //   }
            // ]
            // 66a0ce70a18432dc59960b26----->workflowId
            
            const payload: any = {
				mainTaskName: obj.mainTaskName,
                startDate: obj.startDate,
                dueDate:obj.dueDate,
                assignee:obj.assignee.value,
                description:obj.description,
                linkedTemplateId:templateId,
                createdBy:this.userInfo._id,
            };
            console.log("Task payload",payload)
            let workflowId: any = "66c2f0beb4f223dca569f907"
            try{
                await this.$http
                    .post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/planner/template/task`,[payload])
                    .then((result: { data: any }) => {
                        console.log("Update data",result.data)
                        toast.info('Task created', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    })
                    .catch((error: any) => {
                        toast.error(` ${error}`, {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                });
            } catch(err){
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            

        },
        async getAllActiveUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
				console.log('res.data', res.data);
				this.orgUsersList = res.data.filter((user: any) => {
					return user.isActive == true;
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
					this.optionUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
				}
			});
			console.log('this.orgUsersList', this.orgUsersList);
		},
        async getAllTemplates() {
            let processId = this.$route.params.processId
            // /api/org/process/:processId/templates/getAll
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/process/${processId}/templates/getAll`).then((result: any) => {
				console.log('result.data', result.data);
                if(result.status == 200){
                    this.pageStatus = true
                }
                this.addedTemplateObject = result.data
			});
			
		},
    },

    async  created() {
        await this.getAllActiveUsers();
        await this.getAllTemplates();
        await this.getAllBusinessProcesses();
        this.routesArray = [
            { name: 'Process', routeName: 'process' },
			{ name: this.presentProcessObj.businessProcessName, routeName: 'template' }
        ];
        this.templateTaskObjDummy = {...this.templateTaskObj}
    }
  
  });
  </script>

  
<style scoped>
.image-container {
  position: relative;
  width: 24px; /* Adjust width as needed */
  height: 24px; /* Adjust height as needed */
}
 
.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}
 
.image-container .hover-img {
  opacity: 0;
}
 
.image-container:hover .hover-img {
  opacity: 1;
}
 
.image-container:hover .default-img {
  opacity: 0;
}
  .cardheight{
    height: 100px;
    width: 24%;
  }
  .image-container {
  width: 24px; 
  height: 24px;
  background-image: url('@/assets/Start.svg');
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.3s ease-in-out;
}
/* .ellipsis-container {
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 1.5em;
	max-height: 3em;
} */
.table-ellipsis1{
    display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.image-container:hover {
  background-image: url('@/assets/StartHover.svg');
}
.boxShadow:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>