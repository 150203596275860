<template>
    <div class="px-5 pt-3 pb-2 mb-5">
        <div>
            <div class="mb-2">
                <breadCrumbs :routesArray="routesArray"></breadCrumbs>
            </div>
            <div class="items-center justify-between flex">
                <div class="title flex items-center  w-2/4" v-if="checkCreateOrEdit()">
                    <!-- {{presentTemplateObject.title}} -->
                    <h1 :class="titleLength > 40? 'table-ellipsis':''">{{presentTemplateObject.title}}</h1>
                </div>
                <div class="title flex items-center" v-else>
                    <h1>Create a template</h1>
                </div>
               
                <div class="flex">
                    <div v-if="!editPage">
                        <button class=" mr-1.5" :disabled="clickOnButton" :class="(JSON.stringify(addTemplateObjectDummy) == JSON.stringify(addTemplateObject)) && addedControlArray == '' ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickCanelBtn()">Reset</button>
                        <button class="" :disabled="clickOnButton" :class="(JSON.stringify(addTemplateObjectDummy) == JSON.stringify(addTemplateObject)) && addedControlArray == '' ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickSaveBtn()">Save</button>
                    </div>
                    <div v-else>
                        <button class=" mr-1.5" :disabled="clickOnButton" :class="(areControlIdsSame()&& ((presentTemplateObjectDummy === addTemplateObject.template)) )|| ((areControlIdsSame())&&controlIdsArray.length == 0 &&(presentTemplateObjectDummy == addTemplateObject.template))? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickCanelBtnForupdate()">Reset</button>
                        <button class="" :disabled="clickOnButton" :class="(areControlIdsSame()&& ((presentTemplateObjectDummy === addTemplateObject.template)) )|| ((areControlIdsSame())&&controlIdsArray.length == 0 &&(presentTemplateObjectDummy == addTemplateObject.template)) ? 'btndisabled pointer-events-none ' : 'btnprimary'"  @click="onClickUpdateBtn()">Update</button>
                    </div>
                    
                    
                    <!-- :class="addedControlArray.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" -->
                    <button class="ml-1.5 flex" :class="addedControlArray.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
                        Filters
                        <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                    </button>

                </div>
            </div>
            
            <div class="mt-3">
                <p class="text-xs">
                    This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
                    to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
                </p>
            </div>
            <div class="w-full h-auto bg-white p-4 border border-lightgrey mt-5 rounded-sm">
                <div>
                    <div>
                        <label class="controllabel text-xs">Template</label>
                        <input class="inputboxstyling mt-2" type="text" placeholder="Template Name" v-model.trim="addTemplateObject.template" :class="{'is-invalid': v$.addTemplateObject.template.$error }">
                        <div v-if="v$.addTemplateObject.template.$error " class="text-red-500">
                            <div class="error-text" v-if="v$.addTemplateObject.template.required.$invalid ">Required</div>
                        </div>
                    </div>
                    <div class="upperspacing">
                        <label class="controllabel text-xs">Control</label>
                        <!-- :class="{'is-invalid': addedControlArray == null&&v$.addTemplateObject.selectedControlNumber.$error }" -->
                         <div class="mt-2">
                        <multiselect
                            v-model="addTemplateObject.selectedControlNumber"
                            :options="controlOptions"
                            :showNoOptions="false"
                            @select="onSelectingControlNumberBtn"
                            @search-change="asyncFind"
                            :limit-text="limitText"
                            :multiple="false"
                            :hide-selected="true"
                            :close-on-select="true"
                            :clear-on-select="true"
                            open-direction="bottom"
                            label="label"
                            track-by="value"    
                            :searchable="true"
                            :hideArrow="false"
                            placeholder="Control"
                            class="custom-multiselect userSelect"
                            :class="{
                                'has-options': controlOptions && controlOptions.length > 0,
                                'no-options': !controlOptions.length &&  searchValue !== '',
                            }"
                            
                        >
                                <template v-slot:noResult>
                                    <span>No Control Found</span>
                                </template>
                            </multiselect>
                            <!-- <div v-if=" addedControlArray == null">
                                <div v-if="v$.addTemplateObject.selectedControlNumber.$error " class="text-red-500">
                                    <div class="error-text" v-if="v$.addTemplateObject.selectedControlNumber.required.$invalid  == null">Required</div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="pb-1 font-semibold border-b border-lightgrey">Controls</div>
                    <div style="margin-top: -6px !important;">
                        <tableData :tableRowData="addedControlArray" :headersData="headers"></tableData>
                        <div v-if="toolTipActive">
          		            <tool-tip :tipText="toolTipText"></tool-tip>
        	            </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
    
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { emitter, toast } from '@/main';
import breadCrumbs from '@/components/breadCrumbs.vue';
import tableData from '@/components/tableData.vue';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import toolTip from '@/components/toolTip.vue'
// import { result, template } from 'lodash';

export default defineComponent({
	data(): any {
		return {
            v$: useVuelidate(),
			controlsArray:[],
            userOptions:[],
            headers:['ccid','title','description','framework_controlNo','deleteIcon'],
            routesArray:[],
            allActiveControls:[],
            controlOptions:[],
            controlOptions1:[],
            addedControlArray:[],
            addTemplateObject:{
                template:'',
                selectedControlNumber:'',
            },
            addTemplateObjectDummy:{},
            keysOfActiveControls:[],
            presentTemplateObject:[],
            presentTemplateObjectDummy:'',
            controlIdsArray:[],
            editPage:false,
            addedControlArrayDummy:[],
            allProcess:[],
            presentProcessObj:{},
            filteredArray:[],
            controlIdsArrayDummy:[],
            titleLength:0,
            toolTipActive:false,
            toolTipText:'',
            clickOnButton: false,
            searchValue:''
		};
	},
    components:{
        breadCrumbs, 
        Multiselect,
        tableData,
        toolTip
    },
    computed: {
		...mapGetters({ userInfo: 'userInfo' }),
        disableStatus: function (): any {
            let result;
            let result2 = this.addedControlArray.filter((person:any) => this.addedControlArrayDummy.every((person2:any) => !person2.framework_controlNo.control.value.includes(person.framework_controlNo.control.value)))
            console.log("result2",result2)
            if(result2&&result2.length>0){
                result = false
            }else{  
                result = true
            }
            return result;
        }
    },
    validations(){
        return {
            addTemplateObject:{
                template:{required},
                // selectedControlNumber:{required},
            }
        }
    },
    methods:{
        areControlIdsSame() {
            // console.log("controlIdsArrayDummy:", this.controlIdsArrayDummy);
            // console.log("controlIdsArray:", this.controlIdsArray);
            // console.log("presentTemplateObjectDummy:", this.presentTemplateObjectDummy);
            // console.log("addTemplateObject.template:", this.addTemplateObject.template);
        
            // Check if lengths of both arrays are different
            if (this.controlIdsArrayDummy.length !== this.controlIdsArray.length) {
                return false;
            }
        
            // Sort both arrays
            let sortedDummyArray = [...this.controlIdsArrayDummy].sort();
            let sortedOriginalArray = [...this.controlIdsArray].sort();
        
            // Compare sorted arrays
            for (let i = 0; i < sortedDummyArray.length; i++) {
                if (sortedDummyArray[i] !== sortedOriginalArray[i]) {
                    return false;
                }
            }
        
            
            return true;
        },
        filterOpen() {
			this.showFilters = !this.showFilters;
			// if (this.showFilters == true) {
            //     this.dummyList = this.savedAuditSets;
            // } else {
            //     this.savedAuditSets = this.dummyList;
            // }
			emitter.emit('visible', this.showFilters);
		},
        onClickCanelBtnForupdate(){
            console.log("ihttgg")
            this.addTemplateObject.template = this.presentTemplateObject.title
            this.addedControlArray = [...this.addedControlArrayDummy]
            this.controlIdsArray = [...this.controlIdsArrayDummy]
            // console.log("addedControlArray",this.addedControlArray)
            // console.log("this.controlIdsArray",this.controlIdsArray)
            // this.showFilters = !this.showFilters;
        },
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any) {
            this.searchValue = query;
            if (query === '') {
                this.controlOptions = [];
            } else if (query.length > 0) {
                this.controlOptions = this.controlsArray.filter((obj:any) => {
                    const matchesQuery = obj.label.toLowerCase().includes(query.toLowerCase());
                    const notSelected = !this.controlIdsArray.includes(obj.value);
                    return matchesQuery && notSelected;
                });
            }
        },
        // filterControlsByIds() {
        // console.log("controlIdsArray for filtering", this.controlIdsArray);
        
        // // If no controls are selected, return the initial controlsArray
        // if (this.controlIdsArray.length === 0) {
        //     return this.controlsArray;
        // }
        
        // // Ensure both arrays are non-empty and contain valid data
        // if (this.controlsArray==null || this.controlIdsArray==null) {
        //     console.error("controlsArray or controlIdsArray is not properly populated.");
        //     return [];
        // }
        
        // // Log each control in controlsArray
        // this.controlsArray.forEach((control:any) => {
        //     console.log("Control:", control);
        // });
        
        // // Filter the controlsArray based on the ids in controlIdsArray
        // return this.controlsArray.filter((control:any) => {
        //     const isIncluded = !this.controlIdsArray.includes(control.value);
        //     console.log(`Controlincluded:`, isIncluded);
        //     return isIncluded;
        // });
        // },
        checkCreateOrEdit(){
            let path:any = this.$route.path
            if(path.includes('edit')){
                return true
            }
        },
        onClickCanelBtn(){
            // this.addTemplateObject.template = '',
            // this.addTemplateObject.selectedControlNumber = ''
            this.v$.$reset();
            this.addedControlArray = []
            this.addTemplateObject.template = ''
            this.controlIdsArray = []
            // this.addTemplateObject.selectedControlNumber = ''
            // this.showFilters = !this.showFilters;
            // emitter.off('visible', this.showFilters);
        },
        async onClickSaveBtn(){
            console.log("this.controlIdsArray",this.controlIdsArray)
            console.log("this.addTemplateObject",this.addTemplateObject)
            this.v$.$touch();
            if(!this.v$.addTemplateObject.template.$invalid){
                this.clickOnButton = true;
                let copyOfaddTemplateObject: any = {...this.addTemplateObject}
                await this.createTemplate(copyOfaddTemplateObject);
            }
            
            
        },
        async onClickUpdateBtn(){
            console.log("this.controlIdsArray",this.controlIdsArray)
            console.log("this.addTemplateObject",this.addTemplateObject)
            this.v$.$touch();
            if(!this.v$.addTemplateObject.template.$invalid){
                this.clickOnButton = true;
                let copyOfaddTemplateObject:any = {...this.addTemplateObject}
                await this.updateTemplateObj(copyOfaddTemplateObject);
                this.goToViewPage1();
            }
            
        },
        onSelectingControlNumberBtn() {
            console.log("addTemplateObject", this.addTemplateObject);
            let controlNumber: any = this.addTemplateObject.selectedControlNumber;
            console.log("controlNumber", controlNumber);
        
            let controlObject: any = this.allActiveControls.find((obj: any) => {
                return obj._id === controlNumber.value;
            });
        
            let framework_controlNo: any = [];
        
            if (controlObject && controlObject.frameworks) {
                framework_controlNo = controlObject.frameworks.map((framework: any) => {
                    return {
                        framework: framework.framework,
                        controls: framework.controls
                    };
                });
            } else {
                // framework_controlNo = [
                //     {
                //         framework: 'Internal Controls',
                //         controls: [controlObject.controlNumber]
                //     }
                // ];
            }
        
            console.log("controlObject", controlObject);
            console.log("framework_controlNo", framework_controlNo.length);
        
            let sendObj: any = {
                _id: controlObject._id?controlObject._id:'N/A',
                ccid: controlObject.clientUniqueId?controlObject.clientUniqueId:'N/A',
                title: controlObject.controlTitle?controlObject.controlTitle:'N/A',
                description: controlObject.controlGuidance?controlObject.controlGuidance:'N/A',
                framework_controlNo: framework_controlNo!=undefined && framework_controlNo.length != 0 ?framework_controlNo:''
            };
        
            console.log("sendObj", sendObj);
        
            this.addedControlArray.push(sendObj);
            this.controlIdsArray.push(this.addTemplateObject.selectedControlNumber.value);
        
            console.log("this.addedControlArray", this.addedControlArray);
            console.log("this.controlIdsArray", this.controlIdsArray);
            console.log("this.controlsArray", this.controlsArray);
            this.addTemplateObject.selectedControlNumber = ''
        },
        // onSelectingControlNumberBtn(){
        //     console.log("addTemplateObject",this.addTemplateObject)
        //     let controlNumber: any = this.addTemplateObject.selectedControlNumber
        //     console.log("controlNumber",controlNumber)
        //     let controlObject:any = this.allActiveControls.find((obj: any)=>{
        //         return obj._id === controlNumber.value
        //     })
        //     const framework_controlNo = []
        //     if(Object.keys(controlObject).includes('frameworks')){
                
        //             console.log("controlObject",controlObject)
                
        //         console.log('framework_controlNo',framework_controlNo)
        //     //     framework_controlNo = [{
        //     //         control:{label:controlObject.controlNumber, value:controlObject._id},
        //     //         frameworkName:controlObject.frameworks[0]?.framework || ''
        //     // }]
        //     }else{
        //         framework_controlNo = [ {
        //             control:{label:controlObject.controlNumber, value:controlObject._id},
        //             frameworkName:'Internal Controls'
        //         }]
                
        //     }
        //     let sendObj:any = {
        //         _id: controlObject._id,
        //         ccid: controlObject.clientUniqueId,
        //         title: controlObject.controlTitle,
        //         description : controlObject.controlGuidance,
        //         framework_controlNo: framework_controlNo
        //     }
        //     console.log("sendObj",sendObj)
        //     console.log('framework_controlNo',framework_controlNo)
        //     this.addedControlArray.push(sendObj)
        //     this.controlIdsArray.push(this.addTemplateObject.selectedControlNumber.value)
        //     console.log("this.addedControlArray",this.addedControlArray)
        //     console.log("this.controlIdsArray",this.controlIdsArray)
        //     console.log("this.controlsArray",this.controlsArray)
        //     // let getArray:any = this.controlsArray.filter((control:any)=>{
        //     //     this.controlIdsArray.includes(control.value)
        //     //     console.log("this.controlIdsArray.includes(control.value)",this.controlIdsArray.includes(control.value))
        //     // })
        //     // console.log("getArray",getArray)
            
        //     // this.addTemplateObject.template = '',
        //     this.addTemplateObject.selectedControlNumber = ''
        // },
        async updateTemplateObj(obj: any){
            let templateId:any = this.$route.params.templateId
            console.log("update method")
            // /api/org/template/:templateId/update
            //             POST

            // {
            //   title:'change content',
            //   linkedProcessId:'0wef8bdu89338h',
            //   controls:['1','2','3','4']
            //  }
            console.log("this.controlIdsArray",this.controlIdsArray)
            const payload: any = {
				title: obj.template,
                linkedProcessId : this.$route.params.processId,
                controls: this.controlIdsArray
            };
            console.log("update payload",payload)
            try{
                await this.$http
				.post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/template/${templateId}/update`,payload)
				.then((result: { data: any }) => {
                    console.log("Update data",result.data)
                    toast.info('Updated Successfully', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
				})
				.catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
			});
            } catch(err) {
                    toast.error(`Error`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
			} finally {
                this.clickOnButton = false;
            }
           
                
        },
        async getAllActiveControls(){
             // /active?frameworks=true
            //  /active?relatedFrameworks=true
            await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active?frameworks=true`)
				.then((result: { data: any }) => {
                    // console.log("Active controls data",result.data)
                    this.allActiveControls = result.data;
				})
				.catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
				});
                
                for (var i = 0; i < this.allActiveControls.length; i++) {
                    this.keysOfActiveControls = Object.keys(this.allActiveControls[i])
                    // console.log("this.keysOfActiveControls",this.keysOfActiveControls)
                    // console.log("this.keysOfActiveControls.includes('controlFrequency')",this.keysOfActiveControls.includes('controlFrequency'))
                    if(this.keysOfActiveControls.includes('controlFrequency')&& this.allActiveControls[i].controlFrequency == 1){
                        this.controlsArray.push({ label: this.allActiveControls[i].controlTitle, value: this.allActiveControls[i]._id });
                    }
                }
                console.log("this.controlsArraythis.controlsArray",this.controlsArray)
        },
        async createTemplate(obj : any) {
            // const controlArray:any =[]
            // controlArray.push(obj.selectedControlNumber.value)
            // console.log("controllllllll",controlArray)
            // console.log("objjjjjjj",obj)
            const payload: any = {
				title: obj.template,
                linkedProcessId : this.$route.params.processId,
                controls: this.controlIdsArray,
                createdBy: this.userInfo._id
            };
            console.log("payload",payload)
            try{
                await this.$http
                    .post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/template/create`,[payload])
                    .then((result: { data: any }) => {
                    console.log('here it ISSSSSS',payload)
                    toast.info('Added a template', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    })
                this.goToViewPage();
            } catch(err){
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false;
            }
            
            // /api/org/template/create

            //     POST

            //     [
            //     {
            //     title:'vbkjhghujhbhjhbhjhb',
            //     linkedProcessId:'0wef8bdu89338h',
            //     controls:['1','2','3']
            //     }
            //     ]
                            
        },
        goToViewPage(){
            this.$router.push({ name: 'template' });
        },
        goToViewPage1(){
            let id: any = this.$route.params.templateId
            this.$router.push({ name: 'templateDetails', templateId: id})
        },
        async getOneTemplateDetails() {
            let templateId:any = this.$route.params.templateId
            console.log("this.$route.prams.templateId,",this.$route.params.templateId)
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/template/${templateId}/get`).then((result: any) => {
				console.log('result.data', result.data);
                this.presentTemplateObject = result.data
			});
            let templateObj:any
            let finaltemplateObj:any
            let framework_controlNo :any = []
            this.presentTemplateObject.controls.forEach((element:any) => {
                templateObj=this.allActiveControls.filter((obj: any)=>{return obj._id === element})
                console.log("templateObj",templateObj)
                templateObj.forEach((obj: any) => {
                    if(Object.keys(obj).includes('frameworks')){
                        framework_controlNo = obj.frameworks.map((framework: any) => {
                            return {
                                framework: framework.framework,
                                controls: framework.controls
                            };
                        });
                           
                    }else{
                        framework_controlNo =  {
                                control:{label:obj.controlNumber, value:obj._id},
                                frameworkName:'Internal Controls'
                            }
                            console.log("framework_controlNo",framework_controlNo)
                        
                    }
                    
                    finaltemplateObj= {
                        _id: obj._id,
                        ccid:obj.clientUniqueId != undefined ? obj.clientUniqueId : 'N/A',
                        title:obj.controlTitle != undefined ? obj.controlTitle : 'N/A',
                        description: obj.controlGuidance != undefined ? obj.controlGuidance : 'N/A',
                        framework_controlNo:(framework_controlNo != undefined && framework_controlNo.length != 0) ? framework_controlNo : 'N/A',
                    }
                    this.addedControlArray.push(finaltemplateObj)
                });
              
            });
            // this.addedControlArrayDummy = this.addedControlArray
			
		},
        async getAllBusinessProcesses() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                console.log("result of all business Process",result.data)
				this.allProcess = result.data
            });
            let processId:any = this.$route.params.processId
            let obj:any = this.allProcess.find((process:any)=>{
                return process._id==processId
            })
            this.presentProcessObj=obj
        },
        deleteRow(data:any){
            console.log("intoo emmiterr", data)
            // let removeControlIdfromObj:any
            // let removeControlIdfromdata:any
			let afterDeleteArray = this.addedControlArray.filter((obj:any)=>{
                // removeControlIdfromObj=obj.framework_controlNo.control
                // removeControlIdfromdata=obj.framework_controlNo.control
                // console.log("removeControlIdfromdata",removeControlIdfromdata)
				return !(obj._id == data._id)
			});
            // let afterDeleteArray:any
            let id:any = this.controlIdsArray.filter((id: any)=>{return data._id!=id })
            this.controlIdsArray = id
            console.log("this.controlIdsArray",this.controlIdsArray)
            console.log("iddddd",id)
			this.addedControlArray = [...afterDeleteArray]
        }
    },
    async mounted() {
        await this.getAllActiveControls();
        await this.getAllBusinessProcesses();
        
        this.addTemplateObjectDummy = {...this.addTemplateObject}
        console.log("this.$route.processId",this.$route.params.processId)
        emitter.off('deleteRowFromTable')
		emitter.on('deleteRowFromTable', (data:any) => {
            this.deleteRow(data)
        }); 
        emitter.off('activiteToolTip');
			emitter.on('activiteToolTip', (data: any) => {
				this.toolTipActive = true;
				this.toolTipText = data.tipText
			});
			emitter.off('deActiviteToolTip');
			emitter.on('deActiviteToolTip', (data: any) => {
				this.toolTipActive = false;
				this.toolTipText = ''
			});
        if(this.$route.path.includes('/edit')){
            let templateId:any = this.$route.params.templateId
            this.routesArray=[
                { name: 'Process', routeName: 'process' },
                { name: this.presentProcessObj.businessProcessName, routeName: 'template' },
                { name: 'Template', routeName: '' }
            ]
            
            await this.getOneTemplateDetails();
            this.editPage=true
            console.log("this.presentTemplateObject",this.presentTemplateObject)
            // let editTemplateObj: any = {template: this.presentTemplateObject.title}
            this.presentTemplateObjectDummy = this.presentTemplateObject.title
            this.addTemplateObject.template = this.presentTemplateObject.title
            // this.addTemplateObjectDummy = {...this.addTemplateObject}
            this.addedControlArrayDummy = [...this.addedControlArray]
            console.log("this.addTemplateObject",this.addTemplateObject)
            this.controlIdsArray = this.presentTemplateObject.controls
            this.controlIdsArrayDummy = [...this.presentTemplateObject.controls]
            this.addedControlArraylength = this.addedControlArray.length
            if(this.presentTemplateObject.title){
            this.titleLength = this.presentTemplateObject.title.length
        }
            
        } else {
            this.routesArray=[
                { name: 'Process', routeName: 'process' },
                { name: this.presentProcessObj.businessProcessName, routeName: 'template' },
                { name: 'New template', routeName: 'createTemplate' }
            ]
        }
    }
	
});
</script>


<style scoped>
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>