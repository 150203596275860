import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2d174b72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "body-container" }
const _hoisted_2 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_sidemenubar = _resolveComponent("sidemenubar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_navbar),
    _createElementVNode("div", {
      class: "flex",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeApps()))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'w-40': _ctx.isToggling, 'w-10': !_ctx.isToggling })
      }, [
        _createVNode(_component_sidemenubar)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["content flex-1 w-full h-screen overflow-y-scroll", { toggledstate: _ctx.isToggling }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createVNode(_component_Footer)
      ], 2)
    ])
  ]))
}