<template>
  <div class="pt-3 px-5 pb-2">
    <div class="items-center justify-between flex">
      <div class="title flex items-center">
        <h1>Tasks</h1>
      </div>
	  <div class="cardbody flex relative">
          <div ref="menuContainer" class="flex flex-row-reverse mr-2">
				<button @click="toggleMenu" class="btnprimary btns flex justify-center items-center"><img src="@/assets/plusIcon.svg" alt="" class="mr-2 heightofplusicon" />Add</button>
					<ul  v-if="isMenuVisible" class="card shadow-sm z-50 absolute cursor-pointer top-9 w-40">
						<li class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center" @click="showpopupOnAddForNewTasks()">
							<div class="flex" ><img src="@/assets/EditIcon.svg" alt="" class="mr-2"/>New Task</div>
						</li>
						<li class="px-4 py-2 cursor-pointer h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center" @click="showpopupOnAddForTemplate()">
							<div class="flex" ><img src="@/assets/Template.svg" alt="" class="mr-2"/> From Template</div>
						</li>
					</ul>
			</div>
            <div class="flex flex-row-reverse items-center">
            <button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                Filters
                <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
          </div>
        </div>
    </div>
	<div class="mt-3">
		<p class="headerText">
			A Planner is a tool that helps people organize and manage projects. This can include setting goals and objectives, creating schedules and budgets, assigning tasks and resourses, and tracking progress.
			A Planner is a tool that helps people organize and manage projects. This can include setting goals and objectives, creating schedules and budgets, assigning tasks and resourses, and tracking progress.
		</p>
	</div>
	
	<div>
		<tableData :tableRowData="tableRow" :headersData="headersTable" :loadData="dataLoading"></tableData>
		<div v-if="toolTipActive">
			<tool-tip :tipText="toolTipText"></tool-tip>
		</div>	
	</div>

	<popup v-if="showpopupForTasksfromNew" :popUp="showpopupForTasksfromNew">
		<template v-slot:header>
              <div class="flex items-center justify-between m-4 rounded">
                  <h1>{{ isEdit ? 'Edit Task' : 'New Task' }}</h1>
                  <button class="float-right text-3xl" @click="onCancelButtonForTasks()">
                      <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                  </button>
              </div>
          </template>
		  <fieldset>
			<div class="statuscontainer popupbodycontainer">
                <div class="formcontainer flex flex-col">
					<div class="row flex flex-row w-full upperspacing">
						<div class="w-full">
							<label class="controllabel">Title</label>
							<input type="text" v-model.trim="newTasksObject.taskTitle" class="inputboxstyling mt-2" placeholder="Title Name" :class="{'is-invalid': v$.newTasksObject.taskTitle.$error}">
							<div v-if="v$.newTasksObject.taskTitle.$error" class="text-red-500">
							<div class="error-text" v-if="v$.newTasksObject.taskTitle.required.$invalid">Required</div>
							</div>
						</div>
					</div>
				  <div class="row flex flex-row w-full upperspacing gap-4">
					<div class="w-1/2">
                    <label class="controllabel">Start Date</label>
                    <input
						type="date"
						v-model="newTasksObject.startDate"
						class="mt-2"
						@focus="openCalendar"
						:min="today"
						onkeydown="return false"
						placeholder="Select Date"
						:class="{'is-invalid': v$.newTasksObject.startDate.$error,'inputboxstylingForSelectAtPopup': newTasksObject.startDate === '','stylingForInputboxstylingForSelectAtPopup': newTasksObject.startDate !== ''}"
					/>
					<div v-if="v$.newTasksObject.startDate.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.newTasksObject.startDate.required.$invalid">Required</div>
                    </div>
					</div>
					<div class="w-1/2 ml-2">
                    <label class="controllabel">Due Date</label>
                    <input
						type="date"
						v-model="newTasksObject.dueDate"
						class="mt-2"
						@focus="openCalendar"
						:min="newTasksObject.startDate"
						onkeydown="return false"
						:disabled="newTasksObject.startDate === '' ? true : false"
						placeholder="Select Date"
						:class="{'is-invalid': v$.newTasksObject.dueDate.$error,'inputboxstylingForSelectAtPopup': newTasksObject.dueDate === '','stylingForInputboxstylingForSelectAtPopup': newTasksObject.dueDate !== ''}"
					/>
					<div v-if="v$.newTasksObject.dueDate.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.newTasksObject.dueDate.required.$invalid">Required</div>
                    </div>
					</div>
				 </div>
				 <div class="row flex flex-row w-full upperspacing gap-4">
					<div class="w-1/2">
                    <label class="controllabel">Control</label>
                    <!-- <input
						type="text"
						v-model="newTasksObject.controlNumber"
						class="inputboxstyling  mt-2"
						placeholder="Control Number"
						
					/> -->
					<div class="mt-2">
                        <multiselect
                            v-model="newTasksObject.controlNumber"
                            :options="controlOptions"
                            :showNoOptions="false"
                            @search-change="asyncFind2"
                            :limit-text="limitText"
                            :multiple="false"
                            :hide-selected="true"
                            :close-on-select="true"
                            :clear-on-select="true"
                            open-direction="bottom"
                            label="label"
                            track-by="value"    
                            :searchable="true"
                            :hideArrow="false"
                            placeholder="Control"
                           	class="custom-multiselect userSelect"
                            :class="{
                                'has-options': controlOptions && controlOptions.length > 0,
                                'no-options': !controlOptions.length &&  searchValue !== '',
                            }"
                        >
                                <template v-slot:noResult>
                                    <span>No Control Found</span>
                                </template>
                            </multiselect>
					</div>
					<!-- <div v-if="v$.newTasksObject.controlNumber.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.newTasksObject.controlNumber.required.$invalid">Required</div>
                    </div> -->
					</div>
					<div class="w-1/2 ml-2">
                    <label class="controllabel">Assigned To</label>
					<div class="mt-2">
                    <multiselect
						v-model="newTasksObject.assignee"
						:options="userOptions"
						:showNoOptions="false"
						@search-change="asyncFind"
						:limit-text="limitText"
						:multiple="false"
						:hide-selected="true"
						:close-on-select="true"
						:clear-on-select="true"
						open-direction="bottom"
						label="label"
						track-by="value"    
						:searchable="true"
						:hideArrow="false"
						placeholder="Assigned to"
						class="custom-multiselect userSelect"
						:class="{
							'is-invalid': v$.newTasksObject.assignee.$error,
							'has-options': userOptions && userOptions.length > 0,
                            'no-options': !userOptions.length &&  searchValue !== ''
						}"
					>
						<template v-slot:noResult>
							<span>No Members Found</span>
						</template>
					</multiselect>
					<div v-if="v$.newTasksObject.assignee.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.newTasksObject.assignee.required.$invalid">Required</div>
                    </div>
				</div>
					</div>
				 </div>
				 <div class="row flex flex-row w-full upperspacing">
					<div class="w-full">
                    <label class="controllabel">Description</label>
                    <textarea class="inputboxstyling  mt-2" v-model.trim="newTasksObject.description" placeholder="Description" :class="{'is-invalid': v$.newTasksObject.description.$error}"></textarea>
					<div v-if="v$.newTasksObject.description.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.newTasksObject.description.required.$invalid">Required</div>
                    </div>
					</div>
				 </div>
				</div>
			</div>
			<div class="buttonposition flex justify-center items-center mb-4 mx-0">
                  <button class="mr-1.5" :disabled="clickOnButton" :class="JSON.stringify(newTasksObject) !== JSON.stringify(dummyNewTasksObject) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtnForTasks()">Reset</button>
				  <button v-if="isEdit" @click="updateButtonForTasks()">Update</button>
                  <button v-else :disabled="clickOnButton" :class="JSON.stringify(newTasksObject) !== JSON.stringify(dummyNewTasksObject) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="submitButtonForNewTasks()">Submit</button>
              </div>
		  </fieldset>
	</popup>
	<popup v-if="showPopUpForTemplate" :popUp="showPopUpForTemplate">
		<template v-slot:header>
              <div class="flex items-center justify-between m-4 rounded">
                  <h1>{{ isEdit ? 'Edit Tasks For Templates' : 'Tasks For Templates' }}</h1>
                  <button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
                      <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                  </button>
              </div>
          </template>
		  <fieldset>
			<div class="statuscontainer popupbodycontainer">
                <div class="formcontainer flex flex-col">
			     <div class="row flex flex-row w-full upperspacing">
					<div class="w-full">
                    <label class="controllabel">Template</label>
                    <!-- <input type="text" class="inputboxstyling" placeholder="Select Template as Created"> -->
					<div class="mt-2">
					<multiselect
						v-model="tasksTemplateObject.template"
						:options="templates"
						:showNoOptions="false"
						@search-change="asyncFind1"
						:limit-text="limitText"
						:multiple="false"
						:hide-selected="true"
						:close-on-select="true"
						:clear-on-select="true"
						open-direction="bottom"
						label="label"
						track-by="value"    
						:searchable="true"
						:hideArrow="false"
						placeholder="Template name"
						class="custom-multiselect userSelect"
						:class="{
							'is-invalid': v$.tasksTemplateObject.template.$error,
							'has-options': templates && templates.length > 0,
                            'no-options': !templates.length &&  searchValue !== ''
						}"
					>
						<template v-slot:noResult>
							<span>No Templates Found</span>
						</template>
					</multiselect>
					<div v-if="v$.tasksTemplateObject.template.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.tasksTemplateObject.template.required.$invalid">Required</div>
                    </div>
				</div>
					</div>
				 </div>
				 <div class="row flex flex-row w-full upperspacing">
					<div class="w-full">
                    <label class="controllabel">Title</label>
                    <input type="text" v-model.trim="tasksTemplateObject.templateTitle" class="inputboxstyling mt-2" placeholder="Title Name" :class="{'is-invalid': v$.tasksTemplateObject.templateTitle.$error}">
					<div v-if="v$.tasksTemplateObject.templateTitle.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.tasksTemplateObject.templateTitle.required.$invalid">Required</div>
                    </div>
					</div>
				 </div>
				 <div class="row flex flex-row w-full upperspacing gap-4">
					<div class="w-1/2">
                    <label class="controllabel">Start Date</label>
                    <input
						type="date"
						v-model="tasksTemplateObject.startDate"
						class="mt-2"
						:min="today"
						@focus="openCalendar"
						onkeydown="return false"
						placeholder="Select Date"
						:class="{'is-invalid': v$.tasksTemplateObject.startDate.$error,'inputboxstylingForSelectAtPopup': tasksTemplateObject.startDate === '','stylingForInputboxstylingForSelectAtPopup': tasksTemplateObject.startDate !== ''}"
					/>
					<div v-if="v$.tasksTemplateObject.startDate.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.tasksTemplateObject.startDate.required.$invalid">Required</div>
                    </div>
					</div>
					<div class="w-1/2 ml-2">
                    <label class="controllabel">Due Date</label>
                    <input
						type="date"
						v-model="tasksTemplateObject.dueDate"
						class="mt-2"
						:min="tasksTemplateObject.startDate"
						@focus="openCalendar"
						:disabled="tasksTemplateObject.startDate === '' ? true : false"
						onkeydown="return false"
						placeholder="Select Date"
						:class="{'is-invalid': v$.tasksTemplateObject.dueDate.$error,'inputboxstylingForSelectAtPopup': tasksTemplateObject.dueDate === '','stylingForInputboxstylingForSelectAtPopup': tasksTemplateObject.dueDate !== ''}"
					/>
					<div v-if="v$.tasksTemplateObject.dueDate.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.tasksTemplateObject.dueDate.required.$invalid">Required</div>
                    </div>
					</div>
				 </div>
				 <div class="row flex flex-row w-full upperspacing">
					<div class="w-full">
                    <label class="controllabel">Description</label>
                    <textarea class="inputboxstyling mt-2" v-model.trim="tasksTemplateObject.description" placeholder="Description" :class="{'is-invalid': v$.tasksTemplateObject.description.$error}"></textarea>
					<div v-if="v$.tasksTemplateObject.description.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.tasksTemplateObject.description.required.$invalid">Required</div>
                    </div>
					</div>
				 </div>
				</div>
			</div>
			<div class="buttonposition flex justify-center items-center mb-4 mx-0">
                  <button class="mr-1.5" :disabled="clickOnButton" :class="JSON.stringify(tasksTemplateObject) !== JSON.stringify(dummyTasksTemplateObject) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Reset</button>
				  <button v-if="isEdit" @click="updateButtonForTemplate()">Update</button>
                  <button v-else :disabled="clickOnButton" :class="JSON.stringify(tasksTemplateObject) !== JSON.stringify(dummyTasksTemplateObject) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="submitButtonforTemplate()">Submit</button>
              </div>
		  </fieldset>
	</popup>
  </div>
</template>
<style scoped>
.heightofplusicon{
	height:13px;
}
.filterButton{
   padding: 10px !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { helpers, required } from "@vuelidate/validators";
import { emitter,toast } from '@/main';
import { useVuelidate } from "@vuelidate/core";
import popup from "@/components/popup.vue";
import tableData from "@/components/tableData.vue";
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import toolTip from '@/components/toolTip.vue';


// import { template } from "lodash";
export default defineComponent({
  data(): any {
    return {
	  v$: useVuelidate(),
      isMenuVisible: false,
	  showPopUpForTemplate:false,
      showpopupForTasksfromNew:false,
	  selectedRowData:[],
	  allActiveControls:[],
	  allTasksList: [],
	  headersTable:["taskId","title","CCID","type","assignee","dueDate","taskstatus"],
	  tableRow:[],
	  activeTemplates:[],
	  templateOptions:[],
	  controlOptions:[],
	  clickOnButton: false,
	  tasksTemplateObject:{
		template:'',
        templateTitle:'',
		startDate: '',
        dueDate: '',
		description:'',
	  },
	  dummyTasksTemplateObject:{
        templateTitle:'',
		startDate: '',
        dueDate: '',
		description:'',
	  },
	  newTasksObject:{
        taskTitle:'',
		startDate:'',
		dueDate:'',
		controlNumber:'',
		assignee:'',
		description:'',
	  },
	  dummyNewTasksObject:{
        taskTitle:'',
		startDate:'',
		dueDate:'',
		controlNumber:'',
		assignedTo:'',
		description:'',
	  },
	  optionUserArray:[],
	  userOptions:[],
	  templates:[],
	  keysOfActiveControls:[],
	  controlsArray:[],
	  toolTipActive:false,
	  toolTipText:'',
	  searchValue:''
	};
  },
  validations() {
    return {
		tasksTemplateObject:{
			template:{required},
			templateTitle:{required},
			startDate: {required},
			dueDate: {required},
			description:{required},
		},
	  newTasksObject:{
        taskTitle:{required},
		startDate:{required},
		dueDate:{required},
		assignee:{required},
		description:{required},
	  },
	  dataLoading:true
	}
  },
  components: {
    popup,
	tableData,
	Multiselect,
	toolTip  
},
  methods:{
	toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
	filterOpen() {
		this.showFilters = !this.showFilters;
		if (this.showFilters == true) {
			this.dummyList = this.savedAuditSets;
		} else {
			this.savedAuditSets = this.dummyList;
		}
		emitter.emit('visible', this.showFilters);
	},
	handleClickOutside(event:any) {
      const menuContainer = this.$refs.menuContainer;
      if (menuContainer && !menuContainer.contains(event.target)) {
        this.isMenuVisible = false;
      }
    },
	showpopupOnAddForTemplate(){
		this.isEdit = false;
		this.showPopUpForTemplate = !this.showPopUpForTemplate;
		this.tasksTemplateObject = {
		templateTitle: '',
		startDate: '',
		dueDate: '',
		description: ''
	};
	},
	limitText (count:any) {
		// return `and ${count} other countries`
		console.log('countcount',count)
	},
	asyncFind2(query:any) {
		this.searchValue = query
            if (query === '') {
                this.controlOptions = [];
            } else if (query.length > 0) {
                this.controlOptions = this.controlsArray.filter((obj:any) => {
                    return obj.label.toLowerCase().includes(query.toLowerCase())
                    // const notSelected = !this.controlIdsArray.includes(obj.value);
                    // return matchesQuery && notSelected;
                });
            }
        },
	asyncFind(query:any){
		this.searchValue = query
		if(query == ''){
			this.userOptions = []
		}else if(query.length > 0){
			this.userOptions = this.optionUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
		}
	},
	asyncFind1(query:any){
		this.searchValue = query
		if(query == ''){
			this.templates = []
		}else if(query.length > 0){
			this.templates = this.templateOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
		}
	},
	async showpopupOnAddForNewTasks(){
		this.isEdit = false;
        this.showpopupForTasksfromNew = !this.showpopupForTasksfromNew;
		

		this.newTasksObject = {
			taskTitle: '',
			startDate: '',
			dueDate: '',
			controlNumber: '',
			assignedTo: '',
			description: ''
		};
	},
	onCancelButtonAddPopUp(){
		this.showPopUpForTemplate=false;
		this.onCancelbtn();
	},
	onCancelButtonForTasks(){
        this.showpopupForTasksfromNew=false;
		this.onCancelbtnForTasks();
	},
	openCalendar(event: any) {
      event.target.showPicker();
    },
	onCancelbtn() {
	this.tasksTemplateObject = { ...this.dummyTasksTemplateObject };
	this.v$.$reset();
	},
    onCancelbtnForTasks() {
	this.newTasksObject = { ...this.dummyNewTasksObject };
	this.v$.$reset();
	},
	submitButtonforTemplate(){
      this.v$.$touch();
	  if (!this.v$.tasksTemplateObject.$invalid){
		
		this.clickOnButton = true;
		let copyoftasksTemplateObject: any = {...this.tasksTemplateObject}
		this.taskFromTemplateOption(copyoftasksTemplateObject)
		this.showPopUpForTemplate=false;
		this.onCancelbtn();
	  }
	},
	submitButtonForNewTasks(){
		this.v$.$touch();
		 if (!this.v$.newTasksObject.$invalid){
			this.clickOnButton = true;
			
			let copyOfnewTaskObject: any = {...this.newTasksObject}
			this.taskFromNewOption(copyOfnewTaskObject)
			this.showpopupForTasksfromNew=false;
			this.onCancelbtnForTasks();
		 }
    },
	async taskFromTemplateOption(obj:any){
	
		let templateId:any = obj.template
		const payload: any = {
			linkedTemplateId: templateId.value,
			mainTaskName:obj.templateTitle,
			startDate: obj.startDate,
			dueDate:obj.dueDate,
			description:obj.description,
			createdBy:this.userInfo._id,
		};
	
		try{
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/planner/template/task`,[payload])
				.then((result: { data: any }) => {
					console.log("posted new task")
					toast.info('Task created from template', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				})
				.catch((error: any) => {
					toast.error(` ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
			});
				await this.getAllTasks();

		} catch(err){
			toast.error('Error', {
				timeout: 1000,
				closeOnClick: true,
				closeButton: 'button',
				icon: true
			});
		} finally {
			this.clickOnButton = false;
		}
		
	},
	async taskFromNewOption(obj: any){
		// /api/org/trigger/workflow/:workflowId/planner/subtask/task

		// WORKFLOW API 3040

		// for adding a mainTask with FROM New OPTION

		// POST

		// [
		// {
		// 	TaskName: 'asdfvf',
		// 	startDate:'',
		// 	dueData:'',
		// 	description:'',
		// 	assignee:'',
		// 	createdAt:'',
		// 	createdBy:'',
		// }
		// ]
		const payload: any = {
			TaskName: obj.taskTitle,
			startDate: obj.startDate,
			dueDate:obj.dueDate,
			// controlNo:obj.controlNumber.value,
			description:obj.description,
			assignee:obj.assignee.value,
			createdBy:this.userInfo._id,
		};
		// console.log("payloaddd",payload)
		let workflowId: any = "66c2f0beb4f223dca569f907"
		try{
			await this.$http
			.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/planner/subtask/task`,[payload])
			.then((result: { data: any }) => {
				// console.log("posted new task")
				toast.info("Created Task", {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			})
			.catch((error: any) => {
				toast.error(` ${error}`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});
			await this.getAllTasks();
		} catch (err){
			toast.error('Error', {
				timeout: 1000,
				closeOnClick: true,
				closeButton: 'button',
				icon: true
			});
		} finally {
			this.clickOnButton = false;
		}
		
	},
	updateButtonForTemplate() {
      this.v$.$touch();
      if (!this.v$.tasksTemplateObject.$invalid && this.selectedRowData) {
        Object.assign(this.selectedRowData, {
          name: this.tasksTemplateObject.templateTitle,
          startDate: this.tasksTemplateObject.startDate,
          dueDate: this.tasksTemplateObject.dueDate,
          description: this.tasksTemplateObject.description,
        });

        this.showPopUpForTemplate = false;
        this.onCancelbtn();
      }
	},
	updateButtonForTasks() {
      this.v$.$touch();
      if (!this.v$.newTasksObject.$invalid && this.selectedRowData) {
        Object.assign(this.selectedRowData, {
          name: this.newTasksObject.taskTitle,
          controlNo: this.newTasksObject.controlNumber,
          startDate: this.newTasksObject.startDate,
          dueDate: this.newTasksObject.dueDate,
          assignee: this.newTasksObject.assignee,
          description: this.newTasksObject.description,
        });
        this.showpopupForTasksfromNew = false;
        this.onCancelbtnForTasks();
	  }
	},
    // onClickOnRowData(){
	// 	emitter.on('clickedOnRowData', this.handleRowClick);
	// },
	async getAllTasks() {
		this.tableRow = [];
		this.allTasksList = [];

		try{
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/tasks/getAll`).then((res: any) => {
				this.allTasksList = res.data
				
				this.allTasksList.forEach((task: any) => {
					
					let assignee: any = this.optionUserArray.find((name: any)=>{ return name.value == task.assignee})

					let TaskName:any;

					let controlId:any

					if(task.actionType == 1){
						TaskName = task.mainTaskName != undefined ? task.mainTaskName : 'N/A';
						controlId = 'N/A'
					}else if(task.actionType == 2){
						let contolObj = this.allActiveControls.find((control:any)=> control._id == task.control);

						TaskName = contolObj!=undefined?contolObj.controlTitle:'N/A';
						controlId  = contolObj != undefined && contolObj.clientUniqueId != undefined ? contolObj.clientUniqueId : 'N/A'

					}else if(task.actionType == 3){
						TaskName = task.TaskName != undefined ? task.TaskName : 'N/A';
						controlId = 'N/A'
					}	
				
					let taskObj: any = {
						taskId: task.taskId,
						title: TaskName != undefined ? TaskName : 'N/A',
						CCID: controlId,
						type:task.actionType != undefined ? task.actionType : 'N/A',
						assignee: assignee != undefined ? assignee.label : 'N/A',
						dueDate: task.dueDate != undefined ? task.dueDate : 'N/A',
						taskstatus: task.statusCode != undefined ? task.statusCode : 'N/A',
						id: task._id != undefined ? task._id : 'N/A',
						actionType: task.actionType != undefined ? task.actionType : 'N/A',
					}

					if(taskObj.taskstatus != 10411){
						this.tableRow.push(taskObj)
					}
					
					
				});	

				this.dataLoading = false;
			});
		}catch(e:any){
			console.log('error',e);
			this.dataLoading = false;
		}
	},
	async getAllActiveControls(){
		await this.$http
			.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`)
			.then((result: { data: any }) => {
				// console.log("Active controls data",result.data)
				this.allActiveControls = result.data;
			})
			.catch((error: any) => {
				toast.error(` ${error}`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});
			for (var i = 0; i < this.allActiveControls.length; i++) {
				this.keysOfActiveControls = Object.keys(this.allActiveControls[i])
				
				if(this.keysOfActiveControls.includes('controlFrequency')&& this.allActiveControls[i].controlFrequency == 1){
					this.controlsArray.push({ label: this.allActiveControls[i].controlTitle, value: this.allActiveControls[i]._id });
				}
			}
        },
	async getAllActiveUsers() {
		await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
	
			this.orgUsersList = res.data
			for (var i = 0; i < this.orgUsersList.length; i++) {
				this.optionUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
			}
		});
	},
	async getActiveTemplates(typeId: any){
		
		try{
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/type/${typeId}/templates/getAll`).then((result: any) => {
				this.activeTemplates = result.data
			});

			for (var i = 0; i < this.activeTemplates.length; i++) {
				if(this.activeTemplates[i].controls && this.activeTemplates[i].controls.length != 0){
					this.templateOptions.push({ label: this.activeTemplates[i].title, value: this.activeTemplates[i]._id });
				}
			}
		}catch(e:any){
			console.log(e)
		}

	},
	handleRowClick(rowData: any) {
	this.isEdit = true;
	this.selectedRowData = rowData;
    if (rowData.id === "1") {
		this.showPopUpForTemplate = true;
		this.dummyTasksTemplateObject = {
		templateTitle: rowData.name,
		startDate: rowData.startDate,
		dueDate: rowData.dueDate,
		description: rowData.description,
		};
		this.tasksTemplateObject = { ...this.dummyTasksTemplateObject };
	} else {
		this.showpopupForTasksfromNew = true;
		this.dummyNewTasksObject = {
		taskTitle: rowData.name,
		startDate: rowData.startDate,
		dueDate: rowData.dueDate,
		controlNumber: rowData.controlNo,
		assignedTo: rowData.assigned,
		description: rowData.description,
		};
		this.newTasksObject = { ...this.dummyNewTasksObject };
	}
	},
	goToTemplateTaskEdit(id: any){
		this.$router.push({ name: 'templateTaskEdit' ,params:{ taskId : id}});
	},
	goToSubTaskEdit(id: any){
		this.$router.push({ name: 'subTaskEdit' ,params:{ subtaskId : id}});
	}
   },
  computed:{
	...mapGetters({ userInfo: 'userInfo' }),
    today() {
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, '0'); 
		const day = String(today.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	},	
  },
  
  async created(){
	this.dataLoading = true; 
	await this.getAllActiveUsers();
	await this.getAllActiveControls();
	await this.getAllTasks();
	await this.getActiveTemplates(1);
  },

  async mounted() {

	emitter.off('clickedOnRowData')
	emitter.on('clickedOnRowData',async (dataObj:any)=>{
		this.goToTemplateTaskEdit(dataObj.id)
	})

    document.addEventListener('click', this.handleClickOutside);
	
	emitter.off('clickedOnSubTaskRowData')
	emitter.on('clickedOnSubTaskRowData',async (dataObj:any)=>{
		this.goToSubTaskEdit(dataObj.id);
	})
			
	emitter.off('activiteToolTip');
	emitter.on('activiteToolTip', (data: any) => {
		this.toolTipActive = true;
		this.toolTipText = data.tipText
	});

	emitter.off('deActiviteToolTip');
	emitter.on('deActiviteToolTip', (data: any) => {
		this.toolTipActive = false;
		this.toolTipText = ''
	});
  },
});
</script>