<template>
	<div class="mb-5 mx-5 mt-3 ResponsiveControlePage">
		<div class="mb-2">
			<breadCrumbs :routesArray="routesArray"></breadCrumbs>
		</div>
		<div class="items-center justify-between flex">
			<div v-if="!(presentSubTask.TaskName)" class="title flex items-center">
				<h1 :class="controltitle.controlTitle != undefined && controltitle.controlTitle.length > 40 ? 'heading-ellipsis':''">{{ controltitle.controlTitle }}</h1>
				<!-- <h1 class="title">{{ controltitle.controlTitle }}</h1> -->
			</div>
			<div v-else class="title flex items-center">
				  <h1 :class="presentSubTask.TaskName!=undefined && presentSubTask.TaskName!='' && presentSubTask.TaskName.length > 0 > 40? 'heading-ellipsis':''">{{ presentSubTask.TaskName }}</h1>
				<!-- <h1 class="title">{{ presentSubTask.TaskName }}</h1> -->
			</div>
			<div class="flex">
				
				<div v-if="checkOwner() && (presentSubTask.statusCode == 10415)">
					<!-- v-if="checkOwner() && presentSubTask.statusCode == 10402" -->
					<button class="btnprimary mr-1.5" :disabled="clickOnButton" @click="approveOrRejectTheParentApproval(10403)">Approve</button>
					<button class="btnprimary mr-1.5" :disabled="clickOnButton" @click="approveOrRejectTheParentApproval(10410)">Reject</button>
				</div>
				
				
			</div>
		</div>
		<div v-if="dotsLoading" class="mt-2">
                <div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
                    <dotsloader />
                </div>
            </div>
		<div v-else>
			<div class="w-full minheight">
				<div class="w-full">
					<div class="text-sm">
						<div class="mainContainer tabStructure flex flex-row w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey">
							<div class="w-75 heightFixing pr-5">
								<div class="tabStructureDiv">
									<div class="flex flex-row borderBottom justify-between cursor-pointer tab-container">
										<div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold primaryText':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
											<div>
												<div class="cursor-pointer">{{ tab.displayName }}</div>
												<div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
											</div>
										</div>
									</div>
									<div class="progressbarB rounded h-1"></div>
								</div>
								<div class="h-full">
									<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 1">
										<div>
											<div class="border-b border-lightgrey flex justify-between ">
												<!-- <div class="flex items-center  justify-center"> -->
													<div>
														<p class="my-1.5 header">Title</p>
													</div>
												<!-- </div> -->
											</div>
											
											<p v-if="!(presentSubTask.TaskName)" class="mt-1.5 text-xs">{{ controltitle.controlTitle }}</p>
											<p v-else-if="presentSubTask.TaskName" class="mt-1.5 text-xs">{{ presentSubTask.TaskName }}</p>
										</div>
										<div>
											<div class="border-b border-lightgrey">
												<p class="my-1.5 header">Description</p>
											</div>
											<p v-if="!(presentSubTask.description)" class="mt-1.5 text-xs">{{ controltitle.controlGuidance }}</p>
											<p v-if="presentSubTask.description" class="mt-1.5 text-xs">{{ presentSubTask.description }}</p>
										</div>
										<!-- <div class="mb-2">
											{{ presentSubTask.description }}
										</div> -->
										<div v-if="presentSubTask.notes">
											<div>
												<div class="border-b border-lightgrey">
													<p class="my-1.5 header">Notes</p>
												</div>
												<p class="mt-1.5">{{ presentSubTask.notes }}</p>
											</div>
											<!-- <div class="mb-2">
												{{ presentSubTask.notes }}
											</div> -->
										</div>
										
									</div>
									<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 2">
										<div>
											<div class="border-b border-lightgrey flex justify-between  ">
												<!-- <div class="flex items-center mt-0.5 marginTopForDoc marginBottomForDoc justify-center"> -->
													<div>
														<p class="my-1.5 header">Documents</p>
													</div>
												<!-- </div> -->
												<div class="buttonposition flex justify-center items-center mb-1.5">
													<!-- <button class=" btnprimary flex justify-center taskEditButtons">Attach</button> -->
												</div>
											</div>
											
										</div>
									</div>
									<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 3">
										<div>
											<div class="border-b border-lightgrey flex justify-end ">
												<!-- <div><p class="my-1.5 header">Files </p></div> -->
												
													<div class="buttonposition flex justify-center items-center margin1px">
														<!-- :class="TaskDetailsDropDown.taskStatus ==10419 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 || presentFindingTaskObj.auditor == userInfo._id? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" -->
														<button @click="openFilePopup" for="upload"  :class="presentSubTask.statusCode == 10403? 'btndisabled1 pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'">Attach</button>
													</div>
												
											</div>
											<!-- <p class="mt-1.5">Files</p> -->
											<div v-if="Object.keys(presentSubTask).includes('evidences')" class="flex mt-4 gap-4 flex-wrap">
												<div v-for="doc in presentSubTask.evidences" :key="doc">
													<div class="pdf w-full rounded-sm">
														<a :href="doc.url" class="flex w-full flex-row items-center">
															<img src="@/assets/pdfs.svg" alt="" />
															<p v-if="Object.keys(doc).includes('filename')" class="text-xs ml-2">{{doc.filename}}</p>
															<p v-else class="text-xs ml-1">{{ doc.name }}</p>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 5">
										<div>
											<div class="border-b border-lightgrey flex justify-between ">
												<div class="flex items-center justify-between">
													<div>
														<p class="my-1.5 header">Comments</p>
													</div>
												</div>
												<div>
													<div class="buttonposition flex justify-center items-center">
														<div class="buttonposition flex justify-center items-center">
															<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortComments()">
																<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
																Newest
															</button>
															<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortComments()">
																<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
																Oldest
															</button>
														</div>
													</div>
												</div>
											</div>
											<div>
												<div class="comment-box w-full">
													<!-- <div class="my-3"><textarea placeholder="Add a Comment" class="w-full text-xs border border-lightgrey rounded-sm p-2" rows="4" :disabled="presentSubTask.statusCode == 10403"  v-model="comment" @input="handleInput"></textarea></div>
													<div class="flex justify-end">
														<button @click="addComment" class="mr-2" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Add</button>
														<button @click="clearComment" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Cancel</button>
													</div> -->
													<div class="my-3">
														<commentsEditor/>
													</div>
													
												</div>
												<div v-if="Object.keys(presentSubTask).includes('comments')" class="comments">
													<div v-for="(obj, index) in presentSubTask.comments" class="border border-lightgrey mt-2 rounded-sm flex flex-row p-2" :key="index">
														<div class="w-1/5">
															<div class="header">{{ getPersonName(obj.createdBy) }}</div>
															<div class="fontSize10PX">{{ obj.createdAt }}</div>
														</div>
														<div class="w-4/5 text-xs"><safeHtml :content="obj.comment"/></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 4">
										<div class="border-b border-lightgrey flex justify-between ">
										
											<div>
												<p class="my-1.5 header">Approvals</p>
											</div>
										
											<div class="buttonposition flex justify-center items-center">
												
												<button @click="openApprovalpopup()" :class="presentSubTask.statusCode==10403 || presentSubTask.statusCode==10415 || presentSubTask.assignee.value == userInfo._id ?'approvalbtndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons mb-1.5'" >Send Approval</button>

											</div>
										</div>
										<div>
											<div v-for="(approval, number) in presentSubTask.approvals" :key="number" class="bg-white flex flex-row p-4 mb-0.5 border-lightgrey border-b w-full">
												<div v-if="approval.createdBy != undefined" class="initials">{{extractInitials(getPersonName(approval.createdBy))}}</div>
												<div v-if="approval.createdBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.createdBy) }}</div>
												
												<div v-if="approval.updatedBy != undefined" class="initials">{{extractInitials(getPersonName(approval.updatedBy))}}</div>
												<div v-if="approval.updatedBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.updatedBy) }}</div>

												<div v-if="approval.statusCode == 10401" class="mx-2 mt-1 text-xs"> {{' Requested an approval '}} </div>
												<div v-else-if="approval.statusCode == 10410" class="mx-2 mt-1 text-xs"> {{' Rejected an approval '}} </div>
												<div v-else-if="approval.statusCode == 10403" class="mx-2 mt-1 text-xs"> {{' Approved an approval '}} </div>

												<div v-if="approval.createdAt != undefined" class="font-semibold mt-1 text-xs">{{approval.createdAt}}</div>
												<div v-if="approval.updatedAt != undefined" class="font-semibold mt-1 text-xs">{{approval.updatedAt}}</div>
											</div>
										</div>
									</div>
									<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 6">
										<div>
											<div class="border-b border-lightgrey flex justify-between ">
												<div class="flex items-center justify-between">
													<div>
														<p class="my-1.5 header">History</p>
													</div>
												</div>
												<div>
													<div class="buttonposition flex justify-center items-center">
														<div class="buttonposition flex justify-center items-center">
															<button class="btnprimary w-24 flex taskEditButtons" v-if="!newHistory" @click="sortWorkLog()">
																<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
																Newest
															</button>
															<button class="btnprimary w-24 flex taskEditButtons" v-if="newHistory" @click="sortWorkLog()">
																<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
																Oldest
															</button>
														</div>
													</div>
												</div>
											</div>

										<div v-for="work in workLogObject.taskHistory" :key="work" class="bg-white p-4 mb-0.5 border-lightgrey border-b w-full">
												<div class="flex">
													<div v-if="Object.keys(work).includes('createdBy')" class="initials">
														{{ extractInitials(getPersonName(work.createdBy)) }}
													</div>
													<div v-if="Object.keys(work).includes('updatedBy')" class="initials">
														{{ extractInitials(getPersonName(work.updatedBy)) }}
													</div>
													<!-- <div class="flex ml-4" v-if="Object.keys(work).includes('previousStatus')">
														
														<div class="">
															
															<div class="flex mt-3">
																<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorTemplateBg(work.previousStatus)">
																	<div :class="colorTemplateText(work.previousStatus)">
																		{{ getStatusLabel(work.previousStatus) }}
																	</div>
																</div>
																<div class="mx-4 flex items-center w-3.5"><img src="@/assets/arrowRight.svg"/></div>
																<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorTemplateBg(work.updatedStatus)">
																	<div :class="colorTemplateText(work.updatedStatus)">
																		{{ getStatusLabel(work.updatedStatus) }}
																	</div>
																</div>
															</div>
														</div>
													</div> -->
													<div class="logContainer w-93">
														<div class="flex mt-1">
															<p v-if="Object.keys(work).includes('createdBy')" class="font-semibold text-xs">
																{{ getPersonName(work.createdBy) }}
															</p>
															<p v-else-if="Object.keys(work).includes('updatedBy')" class="font-semibold text-xs">
																{{ getPersonName(work.updatedBy) }}
															</p>
	
															<p v-if="work.actionEntity == 10301" class="mx-2 text-xs">{{ 'Created the Task on' }}</p>
															<p v-else-if="work.statusCode != undefined && work.statusCode == 10418" class="mx-2 text-xs">{{ 'Terminated the Task on' }}</p>
															<p v-else-if="work.actionEntity == 10302" class="mx-2 text-xs">{{ 'Updated the Task on' }}</p>
	
															<p v-if="Object.keys(work).includes('createdAt')" class="font-semibold  text-xs">{{ formatTime(work.createdAt) }}</p>
															<p v-if="Object.keys(work).includes('updatedAt')" class="font-semibold text-xs">{{ formatTime(work.updatedAt) }}</p>
														</div>
														
														<div class="mt-3" v-if="Object.keys(work).includes('additionalInfo')">
															<div class="flex" v-if="Object.keys(work.additionalInfo).includes('comment')">
																<p class="font-semibold text-xs widthForCommentBox">{{ 'Comment :' }}</p>
																<p class="text-xs w-full text-justify"><safeHtml :content="work.additionalInfo.comment"/></p>
															</div>
															
															<div class="flex" v-if="Object.keys(work.additionalInfo).includes('filename')">
																<p class="font-semibold text-xs">{{'Uploaded the Evidence -'}}</p>
																<p class="mx-2 text-xs">{{ work.additionalInfo.filename }}</p>
															</div>
															<div class="flex" v-if="Object.keys(work.additionalInfo).includes('assignee')">
																<p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
																<p class="mx-2 text-xs">{{ getPersonName(work.additionalInfo.assignee) }}</p>
															</div>
															<div class="flex" v-if="Object.keys(work.additionalInfo).includes('approver')">
																<p class="font-semibold text-xs">{{'Requested approval from -'}}</p>
																<p class="mx-2 text-xs text-justify">{{ getPersonName(work.additionalInfo.approver) }}</p>
															</div>
															<div class="flex items-center" v-if="Object.keys(work.additionalInfo).includes('previousStatus')">
																<div>
																	<p class="font-semibold text-xs">{{'Updated The Status :'}}</p>
																</div>
																<div class="flex ml-4">
																	<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorTemplateBg(work.additionalInfo.previousStatus)">
																		<div :class="colorTemplateText(work.additionalInfo.previousStatus)">
																			{{ getStatusLabel(work.additionalInfo.previousStatus) }}
																		</div>
																	</div>
																	<div class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
																	<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorTemplateBg(work.additionalInfo.updatedStatus)">
																		<div :class="colorTemplateText(work.additionalInfo.updatedStatus)">
																			{{ getStatusLabel(work.additionalInfo.updatedStatus) }}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="w-25 border taskDetailsRightBar rounded-sm border-lightgrey">
								<div class="m-3">
									<div class="pb-2 border-b border-lightgrey">
										<h1>Task Details</h1>
									</div>
									<div class="pt-1.5 overflow-y-scroll thirtyWidthToggle">
										<div class="flex flex-col mb-3">
											<label class="controllabel">Task Status</label>
											<!-- :class="changeBackground()" -->
											<div v-if="(presentSubTask.statusCode == 10403 )" :class="changeBackground()" class="inputboxstyling1 cursor-pointer" >
												<div :class="changeBackground()" @change="updateTaskStatus(TaskDetailsDropDown.taskStatus)">
													{{ 
														SubTaskStatus1.find((data)=>{
															return data.id == presentSubTask.statusCode
														}).value
													}}
												</div>
											</div>
											<div v-else-if="(presentSubTask.statusCode == 10415 )" :class="changeBackground()" class="inputboxstyling1 cursor-pointer" >
												<div :class="changeBackground()" @change="updateTaskStatus(TaskDetailsDropDown.taskStatus)">
													{{ 
														inreview.find((data)=>{
															return data.id == presentSubTask.statusCode
														}).value
													}}
												</div>
											</div>
											<div v-else>
												<select
													class="custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
													:class="changeBackground()"
													v-if="(presentSubTask.statusCode != 10402)"
													:disabled="presentSubTask.statusCode == 10403"
													v-model="TaskDetailsDropDown.taskStatus"
													@change="updateTaskStatus(TaskDetailsDropDown.taskStatus)"
												>
													<option value="" selected disabled hidden>Please select one</option>
													<option v-for="i in SubTaskStatus" :value="i.id" :key="i">
														{{ i.value }}
													</option>
												</select>
												<select
													class="custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
													:class="changeBackground()"
													:disabled="presentSubTask.statusCode == 10403"
													v-else
													v-model="TaskDetailsDropDown.taskStatus"
													@change="updateTaskStatus(TaskDetailsDropDown.taskStatus)"
												>
													<option value="" selected disabled hidden>Please select one</option>
													<option v-for="i in SubTaskStatus1" :value="i.id" :key="i">
														{{ i.value }}
													</option>
												</select>
											</div>
											
										</div>
			
										
										<div class="mb-3">
											<div>
												<label class="controllabel">Assigned To</label>
												<div class="mt-2" v-if="presentSubTask.statusCode == 10403 || (presentSubTask.createdBy != userInfo._id && presentSubTask.assignee != undefined && presentSubTask.assignee.value != userInfo._id)">
													{{presentSubTask.assignee != undefined ? presentSubTask.assignee.label : 'N/A'}}
												</div>
												<div v-else class="mt-2">
													<multiselect
														v-model="presentSubTask.assignee"
														:options="userOptions"
														:showNoOptions="false"
														@search-change="asyncFind"
														:limit-text="limitText"
														:multiple="false"
														:close-on-select="true"
														:disabled="presentSubTask.statusCode == 10403"
														:clear-on-select="true"
														label="label"
														open-direction="bottom"
														:hide-selected="true"
														track-by="value"   
														@select="updateAssignedMember(presentSubTask.assignee)" 
														:searchable="true"
														:hideArrow="false"
														placeholder="Assigned-To"
														deselectLabel=""
														selectLabel=""
														class="custom-multiselect userSelect"
														:class="{
															'has-options': userOptions && userOptions.length > 0,
															'no-options': !userOptions.length &&  searchValue !== '',
														}"
													>
														<template v-slot:noResult>
															<span>No Members Found</span>
														</template>
													</multiselect>
												</div>
											</div>
										</div>
										
										<div class="mb-3">
											<label class="controllabel mb-2">Created By</label><br>
											<!-- show process Owner -->
											<div class="my-1">{{ getPersonName(presentSubTask.createdBy) }}</div>
										</div>
										<div class="mb-3">
											<label class="controllabel mb-2">Start Date</label><br>
											<!-- show Start Date -->
											<div class="my-1">{{ presentSubTask.startDate }}</div>
										</div>
										<div class="mb-3">
											<label class="controllabel mb-2">Due Date</label><br>
											<!-- show Due Date -->
											<!-- <div class="my-1">{{ presentSubTask.dueData }}</div> -->
											<div v-if="presentSubTask.dueDate!= undefined || presentSubTask.dueDate!= null" class="my-1">{{ changeDateFormat(presentSubTask.dueDate) }}</div>
											<div v-else class="my-1">N/A</div>
										</div>
										<div class="mb-3">
											<label class="controllabel mb-2">Responsibility</label><br>
											<!-- {{ presentSubTask.team }} -->
											<div v-if="teamName!= undefined || teamName!= null" class="my-1">{{ teamName.label }}</div>
											<div v-else class="my-1">N/A</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<popup v-if="importpopUp" :popUp="importpopUp">
			<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
			<div class="relative w-full max-w-xl">
			<!--content-->
			<div class="border-0 rounded relative flex flex-col w-full bg-white h-screen">
				<!--header-->
				<div class="flex items-center justify-between popupheadingcontainer rounded">
				<h1>Import files</h1>
				<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCancelIcon()">
					<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
				</button>
				</div>
				<div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
				<div class="impactcontainer popupbodycontainer">
				<div class="formcontainer flex flex-col">
					<div class="row flex flex-col mt-2">
					<div>
						<label class="controllabel">File Name </label>
						<input class="inputboxstyling mt-2" type="text" v-model.trim="inputFileName" placeholder="Filename"  :class="{'is-invalid': v$.inputFileName.$error }"/>
						<div v-if="v$.inputFileName.$error " class="text-red-500">
							<div class="error-text text-xs" v-if="v$.inputFileName.required.$invalid ">Required</div>
						</div>
					</div>
					<div class="pt-3">
						<label class="controllabel">Attachment</label>
						<div class="mt-2 w-auto evidenceHeight bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden " :class="{'is-invalid':v$.fileToUpload.$error}">
							<!-- <label htmlFor="upload" class="controllabel">Upload File</label> -->
							<input type="file" placeholder="Choose a file to upload" ref="fileInput" class="hidden" id="upload" @change="handleFileUpload"/>
							<label for="upload" class="float-right cursor-pointer text-white heightForDiv flex justify-center items-center w-9 bg-primary"><img src="@/assets/Browse.svg" /></label>
							<!-- <div v-if="fileName" class="text-xs p-1">{{ fileName }}</div> -->
							<div v-if="fileName == ''" class="text-sm text-mediumGrey heightForDiv">
								{{ placeholder }}
							</div>
							<div v-if="fileName != ''" class="text-sm heightForDiv">
								{{ fileName }}
							</div>
						</div>
					</div>
					
					<div v-if="v$.fileToUpload.$error" class="text-red-500">
						<div v-if="v$.fileToUpload.$invalid" class="error-text text-xs">File upload is required</div>
					</div>
					</div>
				</div>
				</div>
				<!--footer-->
				<div class="buttonposition flex justify-center items-center">
					<button class="btnprimary" @click="handleFileChange" :class="(inputFileName.length == 0) ? 'btndisabled pointer-events-none ' : 'btnprimary'">Save</button>
				</div>
			</div>
			</div>
			</div>
		</popup>
		<popup v-if="showApprovalPopup" :popUp="showApprovalPopup">
			<template v-slot:header>
				<div class="flex items-center justify-between popupheadingcontainer rounded">
					<h1>Approval</h1>

					<button class="float-right text-3xl" @click="onCrossbtn()">
						<img src="@/assets/cancel.svg" class="cursor-pointer" />
					</button>
				</div>
			</template>

			<div class="popup-business">
				<div>
					<div>
						<div class="statuscontainer px-5 pb-5">
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row upperspacing">
									<div class=" w-1/2">
										<label class="controllabel">Name of the Authority</label>
										<div class="mt-2">
										<multiselect
											v-model="taskObj.approver"
											:options="authorityOptions"
											:showNoOptions="false"
											@search-change="asyncFind1"
											:limit-text="limitText"
											:multiple="false"
											label="label"
											open-direction="bottom"
											track-by="value"
											:searchable="true"
											:hideArrow="false"
											placeholder="Assigned-To"
											deselectLabel=""
											selectLabel=""
											class="custom-multiselect userSelect"
											:class="{
												'is-invalid': v$.taskObj.approver.$error,
												'has-options': userOptions && userOptions.length > 0,
                                				'no-options': !userOptions.length &&  searchValue !== '',
											 }"
										></multiselect>
									</div>
									<div v-if="v$.taskObj.approver.$error " class="text-red-500">
										<div class="error-text" v-if="v$.taskObj.approver.$invalid ">Required</div>
									</div>
										
									</div >
								</div>
								<div class="row flex flex-row upperspacing">
									<div class="w-full">
										<label class="controllabel">Justification or Comments for Approving</label>
										<textarea
											class="inputboxstyling mt-2"
											placeholder="Comments"
											v-model.trim="taskObj.approvalComments"
											:class="{'is-invalid': v$.taskObj.approvalComments.$error }"
										/>
										<div v-if="v$.taskObj.approvalComments.$error " class="text-red-500">
											<div class="error-text" v-if="v$.taskObj.approvalComments.$invalid ">Required</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="buttonposition flex justify-center items-center mb-4 mx-0">
						<button class="btnprimary mr-2" :class="(JSON.stringify(taskObj) == JSON.stringify(taskObjDummy)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtnAtApproval()">Reset</button>
						<button class="btnprimary" :class="(JSON.stringify(taskObj) == JSON.stringify(taskObjDummy)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="clickOnSaveForApproval(taskObj)">Submit</button>
					</div>
				</div>
			</div>
		</popup>
	</div>
</template>
<style scoped>
.widthForCommentBox{
	width : 75px;
}
.margin1px{
	margin-bottom : 6.5px;
}
.progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
}
.numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 2px solid #152A43;
    /* border-width: 2px; */
}
.progress {
	background: #152a43;
	position: relative;
	border-radius: 4px;
	z-index: 2;
}
.primaryBackground{
	background-color: #152a433b
}
.descriptionToggle {
	height: 97%;
}
.tabStructure {
	height: calc(100vh - 165px);
}
.thirtyWidthToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.thirtyWidthToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.thirtyWidthToggle{  
     height:  calc(100vh - 250px);
}
.descriptionToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}
.descriptionToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.heightFixing {
	height:  calc(100vh - 220px);
	/* height : 64vh; */
}
option {
	background-color: #ffffff;
	color: #000000;
	border-color: pink transparent transparent transparent;
}
.textColorWhite {
	color: white;
}
.custom-select {
    background-image: url("../assets/Collapse.svg") !important;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    background-position: right 15px center; /* Change the px value here to change the distance */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.marginBottomForDoc{
	margin-bottom : 3px;
}
.marginTopForDoc{
	margin-top : 2.5px;
}
.marginBottomZero{
	margin-bottom : 0px;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.labelWidth{
	min-width: 7rem;
}
.framework{
	padding: 12px 170px 12px 12px;
}
.findingCategory{
	padding: 12px 80px 12px 12px;
}
.inputboxstyling1 {
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(233, 233, 233, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  /* background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); */
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  --tw-text-opacity: 1;
  /* color: rgba(77, 77, 77, var(--tw-text-opacity)); */
}
.btndisabled1 {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(233 233 233 / var(--tw-bg-opacity));
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1px;
    --tw-text-opacity: 1;
    color: rgb(77 77 77 / var(--tw-text-opacity));
    height: 24px;
}
.btnprimary1 {
	cursor: pointer;
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-family: Open Sans;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.approvalbtndisabled {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(233 233 233 / var(--tw-bg-opacity));
    padding: 6px;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: .75rem;
    --tw-text-opacity: 1;
    color: rgb(77 77 77 / var(--tw-text-opacity));
}
.evidenceHeight{
	height: 29px;
}
.heightForDiv{
  height : 29.333px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import breadCrumbs from '@/components/breadCrumbs.vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import popup from '@/components/popup.vue';
import tableData from '@/components/tableData.vue';
// import { groupBy } from 'lodash';
import { emitter, toast } from '@/main';
// import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import commentsEditor from '@/components/commentsEditor.vue';
import Multiselect from 'vue-multiselect';
import CommentsEditor from '@/components/commentsEditor.vue';
import safeHtml from '@/components/safeHtml.vue';
import dotsloader from '@/components/dotsloader.vue';

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			selectedTabIndex: null,
			fileToUpload: null,
			tabHead: [
				{ displayName: 'Description', id: 1 },

				{ displayName: 'Documents', id: 2 },

				{ displayName: 'Evidence', id: 3 },

				{ displayName: 'Approvals', id: 4 },

				{ displayName: 'Comments', id: 5 },

				{ displayName: 'History', id: 6 }
			],
			selectedTabObj:{},
            clickOnButton: false,
			presentSubTask:[],
			Headers: ['ccid','title','team','startDate','dueDate','SubTaskStatus'],
			tableRow:[],
			importpopUp: false,
			inputFileName: '',
			fileName:'',
			isInputFileNameValid: true,
			showSubTaskPopUp: false,
			controltitle:'',
			TaskDetailsDropDown: {
				taskStatus: '',
				assignedToDropDown: ''
			},
			taskObj:{
				approver:'',
                approvalComments : ''
            },
			taskObjDummy:{
				approver:'',
                approvalComments : ''
			},
			userOptions:[],
			routesArray:[],
			allSubTasks:[],
			users:[],
			showApprovalPopup: false,
			SubTaskStatus:[
                // {
				// 	id: 10411,
				// 	value: 'Draft'
				// },
				{
					id: 10401,
					value: 'Open'
				},
				{
					id: 10402,
					value: 'In Progress'
				},
				// {
				// 	id: 10415,
				// 	value: 'In Review'
				// },
				// {
				// 	id: 10403,
				// 	value: 'Completed'
				// },
			],
			inreview:[
			{
					id: 10415,
					value: 'In Review'
				}
			],
			SubTaskStatus1:[
				// {
				// 	id: 10401,
				// 	value: 'Open'
				// },
				// {
				// 	id: 10402,
				// 	value: 'In Progress'
				// },
				// {
				// 	id: 10415,
				// 	value: 'In Review'
				// },
				{
					id: 10402,
					value: 'In Progress'
				},
				// {
				// 	id: 10415,
				// 	value: 'In Review'
				// },
				{
					id: 10403,
					value: 'Completed'
				},
			],
			tasksStatus:[
				{
					id: 10411,
					value: 'Draft'
				},
				{
					id: 10401,
					value: 'Open'
				},
				{
					id: 10402,
					value: 'In Progress'
				},
				{
					id: 10415,
					value: 'In Review'
				},
				{
					id: 10415,
					value: 'In Review'
				},
				{
					id: 10403,
					value: 'Completed'
				},
			],
			subTaskDetailsObj:{},
			oldComments: true,
			newHistory: true,
			comment: '',
			comments: [],
			btnDisabled: true,
			selectedOption: '',
			selectedBG: '',
			workLogObject: [],
			optionsUserArray: [],
			orgUsersList: [],
			presentAuditObject: {},
			teamName: '',
			personObj : '',
			controlsArray : [],
			controlDetailsArray : [],
            controlsArrayAllControls : [],
            controlsDetailsList : [],
			teamObj : '',
			allFrameWorkControls:[],
			locationObject:[],
			fileSelected: false,
			isFileValid: true,
			fileChanged : false,
			findingsForTask:[],
			allActiveControls:[],
			teamObject:[],
			presentTemplateTask:[],
			approvingMembers:[],
			titleLength: 0,
            controlTitleLength:0,
			placeholder: 'Select a File',
			searchValue:'',
			authorityOptions:[]
		};
	},
	validations() {
		return {
			inputFileName:{required},
			fileToUpload: { required },
			taskObj:{
				approver:{required},
				approvalComments:{required}
			}
		};
	},
	components: {
		breadCrumbs,
		popup,
		Multiselect,
		tableData,
		commentsEditor,
		safeHtml,
		dotsloader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	methods: {
		limitText (count:any) {
			console.log('countcount',count)
		},
		changeDateFormat(date: any) {
            if(date != undefined){
                return date.split('-').reverse().join('-');
            }
		},
		asyncFind(query:any){
			this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		asyncFind1(query:any){
			this.searchValue = query;
			if(query == ''){
				this.authorityOptions = []
			}else if(query.length > 0){
				this.authorityOptions = this.approvingMembers.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase()) && obj.value != this.userInfo._id})
			}
		},
		async getSubTaskHistoryObject() {
			// /api/org/task/:taskId/history/get
			
			// for task history
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${this.$route.params.subtaskId}/history/get`).then((result: any) => {
				const mainObject = result.data;
				this.workLogObject = mainObject;
				console.log('this.workLogObject', this.workLogObject);
			});
		},
		
		
		getLocation(teamId:any){
                
			let presentteamObject:any = this.users.find((obj: any) => {
				return obj._id == teamId;
			});

			if (presentteamObject!=undefined && presentteamObject.type == 20301){
				return 'Global';
			}else{
				if(this.presentSubTask.location == undefined || this.presentSubTask.location == null ){
					return 'N/A'
				}else{
					return this.getLocationName(this.presentSubTask.location[0].entity)
				}
			}
		},
		getLocationName(locationId: any) {
            let locationObj = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
			
            if (locationObj == undefined) {
                let empty = '';
                return empty;
            } else {
                return locationObj.label;
            }
        },
		onCancelIcon(){
			this.importpopUp = false
			this.inputFileName = ''
			this.fileName = ''
			this.fileToUpload=null
			this.v$.$reset();
		},
		getStatusLabel(num:any){
			console.log("nummm",num)
			let result = this.tasksStatus.find((obj:any)=>{return obj.id == num});
			let resultValue = result.value
			return result.value
		},
		scrollTabIntoView(tabId : any) {
			this.$nextTick(() => {
				const tabElement = this.$el.querySelector(`.tab:nth-child(${tabId})`);
				if (tabElement) {
				tabElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
				}
			});
		},
		openApprovalpopup(){
			this.showApprovalPopup = true
		},
		onCancelbtnAtApproval(){
			this.v$.$reset();
			this.taskObj.approver = ''
			this.taskObj.approvalComments = ''
		},
		onCrossbtn(){
			this.v$.$reset();
			this.taskObj.approver = ''
			this.taskObj.approvalComments = ''
			this.showApprovalPopup =  false
		},
		checkOwner(){
			let approver: any 
			if(this.presentSubTask.approvals){
				let approvals: any = this.presentSubTask.approvals
				approvals.forEach((obj:any) => {
					approver = obj.approver
				});
				if(approver == this.userInfo._id){
					return true
				} else{
					return false
				}
			}
		},
		async getAllActiveControls(){
			await this.$http
			.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`)
			.then((result: { data: any }) => {
				this.allActiveControls = result.data;
			}).catch((error: any) => {
				toast.error(` ${error}`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});
        },
		clickOnSaveForApproval(obj: any){
			// (TYPE = 3) for sending approvals

			// {approver:'userId',createdBy:'',createdAt:''}
			let payload: any = {
				approver :obj.approver.value,
			}
			this.v$.$touch();
			if(!this.v$.taskObj.$invalid){
				this.clickOnButton = true;
			this.updateTemplateTask(3,payload)
			this.taskObjDummy = this.taskObj
			this.showApprovalPopup = false
			this.taskObj.approver = ''
			this.taskObj.approvalComments = ''
			this.v$.$reset();
			}
		},
		async approveOrRejectTheParentApproval(statusCode: any){
			this.clickOnButton = true;
			let subtaskId: any = this.$route.params.subtaskId
			const payload: any={
				statusCode: statusCode,
				updatedBy: this.userInfo._id
			}
			// /api/org/task/:taskId/update/approval

			// POST

			// => payload for approving approval

			// {statusCode: 10403 , updatedBy:'',updatedAt:''}
			await this.$http.post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${subtaskId}/update/approval`,payload).then((res: any) => {
                
				console.log("this.presentSubTask",this.presentSubTask)
            });
			if(statusCode == 10403){
                toast.success(`Approval Approved`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
				});
				await this.getSubTaskHistoryObject()
            }else if(statusCode == 10410){
                toast.error(`Approval Rejected`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
				});
				await this.getSubTaskHistoryObject() 
            }
			await this.getPresentSubTask();
			this.workLogObject.taskHistory.sort().reverse();
		},
		async getAllActiveUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
				this.orgUsersList = res.data;
				for (var i = 0; i < this.orgUsersList.length; i++) {
					this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });

					if(this.userInfo._id != this.orgUsersList[i]._id){
						this.approvingMembers.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
					}
				}
			});
		},
		
		async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
        },
		async getAllTeams() {
            this.teamObject = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
            });
            
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].teamName, value: this.users[i]._id });
            }
            
        },
		getPersonName(person: any) {
			let personObj = this.orgUsersList.find((user: any) => {             
				return user._id == person;
			});
			
			if (personObj == undefined) {
				return '';
			} else {
				return personObj.name;
			}
		},
		getPerson(person: any) {
			this.personObj = this.orgUsersList.find((user: any) => {
				return user._id == person;
			});
			if (this.personObj == undefined) {
				return '';
			} else {
				return this.personObj.name;
			}
		},
		sortWorkLog() {
			this.newHistory = !this.newHistory;
			this.workLogObject.taskHistory.sort().reverse();
		},
		sortComments() {
			this.oldComments = !this.oldComments;
			if(this.presentSubTask.comments != undefined){
				this.presentSubTask.comments.sort().reverse();
			}
		},
		formatTime(time: any) {
			let now = moment(time);
			return now.format('DD-MM-YYYY, hh:mm:ss');
		},
		extractInitials(name: any) {
			const names = name.split(' ');
			return names
				.map((name: any) => name[0])
				.join('')
				.toUpperCase();
		},
		changeBackground() {
			if (this.TaskDetailsDropDown.taskStatus == 10411) {
				return 'bg-disabledgrey textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10415) {
				return 'bg-yellow textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10402) {
				return 'bg-electricGreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10403) {
				return 'bg-lightgreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10401) {
				return 'bg-darkred textColorWhite';
			} else {
				return '';
			}
		},
		checkAuditor(id:any){
			if(id == this.userInfo._id){
				return true
			} else if(id == undefined || id == null){
				return false
			} else {
				return false
			}
		},
		async updateTaskStatus(status: any) {
			let payload: any = { statusCode: status };
			await this.updateTemplateTask(5,payload)
		},
		async updateAssignedMember(obj: any) {
			let payload: any = { assignee: obj.value };
			await this.updateTemplateTask(6,payload)
		},
		handleInput() {
			this.btnDisabled = this.comment.length === 0;
		},
		async addComment() {
			if (this.comment.trim() !== '') {
				const newComment: any = {
					comment: this.comment
				};
				await this.updateTemplateTask(4, newComment);
				
				if(this.presentSubTask.comments != undefined){
					this.presentSubTask.comments.sort().reverse();
				}
				this.comment = '';
				this.btnDisabled = true;
			}
		},
		clearComment() {
			this.comment = '';
			this.btnDisabled = true;
		},
		async toggleTabs(obj: any) {
			this.scrollTabIntoView(obj.id);
			this.selectedTabIndex = obj.id;
			this.selectedTabObj = obj;
			sessionStorage.setItem('selectedTab',obj.id);
		},
		
		onCancelButtonAddPopUp() {
			this.showSubTaskPopUp = false;
			this.onCancelbtn();
		},
		openFilePopup() {
			this.importpopUp = true
		},
		updateFileName(event: any) {
			let file = this.$refs.fileInput.files[0];

			if (file && !file.type.match('application/pdf') && !file.name.endsWith('.doc') && !file.name.endsWith('.docx')) {
				alert('Please select a PDF, DOC, or DOCX file.');
				return;
			} else {
				this.fileChanged = true;
				this.fileToUpload.file = file;
			}
		},
		handleFileUpload(event: any) {
            const file = this.$refs.fileInput.files[0];
            if (file) {
                this.fileName = file.name;
                this.fileToUpload = file
               
                const fileNameParts = file.name.split('.');
                
                fileNameParts.pop();
                const nameWithoutExtension = fileNameParts.join('.');
                
                if (this.inputFileName.trim() === '') {
                	this.inputFileName = nameWithoutExtension;
                }
            } else {
                this.fileName = '';
                this.inputFileName = '';
            }
        },
		async handleFileChange(event: any) {
			let data = this.$refs.fileInput.files[0];
			
			this.v$.inputFileName.$touch()
			this.v$.fileToUpload.$touch()
			
			if((!this.v$.fileToUpload.$invalid) && (!this.v$.inputFileName.$invalid)){
				this.importpopUp = !this.importpopUp
				let fileExtension = data.name.split('.').pop();

				if (fileExtension == 'pdf') {
					const fileDataFormat = await this.getConvertDataForFile(data);
					let uuid = uuidv4();
					let payload = { fileData: fileDataFormat, name: `${uuid}.${fileExtension}` };
					let folderName = 'planner';
					try {
						await this.$http
							.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${folderName}/file/upload`, payload)
							.then(async (response: any) => {
								if (response.status == 200) {
									console.log(response.data);
									let payload: any = {
										// name: `${uuid}.${fileExtension}`,
										url: response.data,
										filename:this.inputFileName
									};
									await this.updateTemplateTask(2, payload);
									this.inputFileName = ''
									this.fileName = ''
									this.v$.$reset();
								} else {
									console.log('response status', response.status);
								}
							});
					} catch (err) {
						console.error(err);
					} finally {
						this.clickOnButton = false;
					}
				} else {
					toast.error(`Upload only pdf`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.v$.$reset();
				}
				
				this.fileName = '';
				this.inputFileName = '';
				this.fileToUpload=null
			}
		},
		async getConvertDataForFile(data: any) {
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		
		async getPresentSubTask() {
            let subtaskId:any = this.$route.params.subtaskId
			// /api/org/task/:taskId/get

            // GET

            // for getting one task object 
			await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${subtaskId}/get`).then((res: any) => {
                this.presentSubTask = res.data;
            });
			this.controltitle= this.allActiveControls.find((obj: any)=>{return obj._id == this.presentSubTask.control})
			
			this.teamName = this.teamObject.find((obj: any)=>{return obj.value == this.presentSubTask.team})
			
            this.TaskDetailsDropDown.taskStatus = this.presentSubTask.statusCode;
			if(this.presentSubTask.assignee != undefined){
				let userObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentSubTask.assignee});
				this.presentSubTask.assignee = userObj;
			}else{
				let userObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentSubTask.auditor});
				this.presentSubTask.assignee = userObj;
			}
            
        },
		async updateTemplateTask(type: any, payload: any) {
			payload.createdBy = this.userInfo._id;

			if(type == 2){
				payload.additionalInfo = {evidence:''};
				payload.additionalInfo.evidence = payload.filename;
			} else if(type == 3){
				payload.additionalInfo = {approver:''};
				payload.additionalInfo.approver = payload.approver;
			} else if(type == 4){
				payload.additionalInfo = {comment:''};
				payload.additionalInfo.comment = payload.comment;
			} else if(type == 5){
				payload.additionalInfo = {previousStatus:'',updatedStatus:''};
				payload.additionalInfo.previousStatus = this.presentSubTask.statusCode;
				payload.additionalInfo.updatedStatus = payload.statusCode;
			} else if(type == 6){
				payload.additionalInfo = {assignee:''};
				payload.additionalInfo.assignee = payload.assignee;
			} else if(type == 7){
				payload.additionalInfo = {createdBy:''};
				payload.additionalInfo.createdBy = payload.createdBy;
			}
			
			try {
				await this.$http.post(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${this.$route.params.subtaskId}/type/${type}/update`, payload).then(async (result: any) => {
					toast.info(`Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			} catch {
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			await this.getPresentSubTask();
			await this.getSubTaskHistoryObject();
			this.workLogObject.taskHistory.sort().reverse();
		},
		colorTemplateText(status: any) {
			if (status == 10411) {
				return ['font-semibold text-xs text-disabledgrey'];
			} else if (status == 10415) {
				return ['font-semibold text-xs text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-xs text-lightgreen'];
			} else if(status == 10402){
				return ['font-semibold text-xs mx-2 text-electricGreen']
			} else if(status == 10401){
				return ['font-semibold text-xs mx-2 text-darkred']
			} else{
				return 'N/A'
			}
		},
		colorTemplateBg(status: any) {
			if (status == 10411) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10402) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10401) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			}
		},
		
		async getPresentTemplateTask() {
			let taskId : any = this.$route.params.taskId
			
            await this.$http.get(`${process.env.VUE_APP_PLANNER_API_URL}/api/org/task/${taskId}/get`).then((res: any) => {
                this.presentTemplateTask = res.data;
            });
        },
		
		
	},
	async mounted(){
		emitter.off('addedComments');
		emitter.on('addedComments',(data: any)=>{
			this.comment = data.comments
			this.addComment()
		})
	},
	async created(){
        await this.getAllActiveUsers();
		await this.getAllTeams();
		await this.getAllActiveControls();
        await this.getPresentSubTask();
		await this.getSubTaskHistoryObject();
		const savedTabKey = sessionStorage.getItem('selectedTab');

		if (savedTabKey) {
			const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
			if (savedTab) {
				await this.toggleTabs(savedTab);
			}else{
				this.selectedTabObj = { displayName: 'Description', id: 1 };
				this.selectedTabIndex = 1;
			}
        }else {
		    this.selectedTabObj = { displayName: 'Description', id: 1 };
			this.selectedTabIndex = 1;
		}
		
		if(this.$route.query.tasks == 'true'){
			await this.getPresentTemplateTask();
			this.routesArray = [
				{ name: 'Task', routeName: 'tasks' },
				{ name: this.presentTemplateTask.taskId, routeName: 'templateTaskEdit' },
				{ name: this.presentSubTask.taskId, routeName: 'subTaskEdit' },
			];
		} else{
			this.routesArray = [
				{ name: 'Task', routeName: 'tasks' },
				{ name: this.presentSubTask.taskId, routeName: 'subTaskEdit' },
			];
		}
		
		if(this.presentSubTask.comments != undefined){
			this.presentSubTask.comments.sort().reverse();
		}

		this.workLogObject.taskHistory.sort().reverse();
		this.titleLength = this.presentSubTask.TaskName ? this.presentSubTask.TaskName.length:0
        this.controlTitleLength = this.controltitle?.controlTitle ? this.controltitle?.controlTitle.length:0
	},
	beforeUnmount() {
		sessionStorage.removeItem('selectedTab')
	},
	
});
</script>
